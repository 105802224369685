<div class="selectContainer {{ extraClass }}">
  <div class="select__currentItem">
    <p (click)="ToogleItemsList()" class="cursorLink">
      {{ currentText }}
      <span
        [ngClass]="forceOneElement === false && removeSelectedIcon === true ? '' : 'hiddenItem'"
        (click)="CloseCurrentOption()"
        class="icon-close closeIconFormat"></span>
      <span class="icon-down-arrow"></span>
    </p>
  </div>
  <div class="select__itemsList"
       [ngClass]="{
       showItems: opened === true,
       twoColumns: twoColumns === true
       }">
    <ul>
      <li
        *ngFor="let item of options"
        [ngClass]="item.value === currentSelected ? 'Active' : ''"
        (click)="ChangeOption(item.value)" class="cursorLink">{{ item.label }}</li>
    </ul>
  </div>
</div>
