import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { User } from '../_models/user';
import {environment} from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;

  constructor(private http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();
  }

  public get currentUserValue(): boolean {
    // const user = {
    //   id: '1',
    //   name: 'admin',
    //   token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
    // };
    // localStorage.setItem('currentUser', JSON.stringify(user));
    const u = localStorage.getItem('currentUser');
    const d = localStorage.getItem('currentTime');

    if (u === null || d === null) {
      return false;
    }

    // console.log(u);
    // console.log(d);
    // const oldDate = new Date(parseInt(d, 10));
    const expirationDate = new Date(parseInt(d, 10) + (environment.expiration_minutes * 60 * 1000));
    const currentDate = new Date();
    // console.log(oldDate.toLocaleString());
    // console.log(expirationDate.toLocaleString());

    return currentDate <= expirationDate;
    // this.currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
    // return this.currentUserSubject.value;
  }

  login(password): Observable<User> {
    return this.http.post<any>(`${environment.api_url}/api/iculture/general/login`, { password: password })
      .pipe(map(user => {
        // store user details and jwt token in local storage to keep user logged in between page refreshes
        localStorage.setItem('currentUser', JSON.stringify(user));
        this.currentUserSubject.next(user);
        return user;
      }));
  }

  logout(): void {
    // remove user from local storage and set current user to null
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }
}
