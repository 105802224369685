<section class="mainSectionHome">
  <div class="wrapper {{ number }}" [style]="randomBackground()">
    <div class="homeLogo paddingDiv">
      <img src="assets/images/logo.png"
           alt="iCulture"
           srcset="assets/images/logo.png 1x, assets/images/logo@2x.png 2x">
    </div>
    <div class="paddingDiv">
      <h1 [innerHTML]="translations.explora_iculture"></h1>
    </div>
    <div style="width: 100%;">
      <app-login-form
        (onChangeLanguage)="changeLanguage($event)"
      ></app-login-form>
    </div>
  </div>
</section>
