import {Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Selectitem } from '../_models/selectitem';
import { Filtersobject } from '../_models/filtersobject';
import { Itemcard } from '../_models/itemcard';
import { Translations } from '../_models/translations';
import { TranslationsService } from '../_services/translations.service';
import { InewService } from '../_services/inew.service';
import { IpassportService } from '../_services/ipassport.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  openOptions = false;
  openSearch = false;
  takeSearch = false;
  currentFilters: Filtersobject = {
    date: '', level: '', apthemes: '', categories: ''
  };
  language = '';
  languageLang = '';
  translations: Translations;
  categoriesList: Selectitem[] = [];
  apTagsList: Selectitem[] = [];
  openCategorySelector = false;
  openApthemesSelector = false;
  openDateSelector = false;
  openLevelSelector = false;

  showMobileMenu = false;

  searchedItemsiPassport: Itemcard[];
  searchedItemsiNews: Itemcard[];
  searchedItemsiVideos: Itemcard[];
  searchedItemsiSongs: Itemcard[];
  numberItemsSearch = 0;
  searching: boolean;

  @Input() option: string;
  @Input() monthSelectorType = 'normal';
  // @Input() searchedItems: Itemcard[];
  // @Input() searching: boolean;
  @Input() color: string;
  @Input() inewsFilters = false;
  @Output() filtersOptions = new EventEmitter();
  @Output() openItem = new EventEmitter();

  constructor(
    private translationsService: TranslationsService,
    private inewService: InewService,
    private ipassportService: IpassportService,
    private router: Router
  ) {
    this.translations = translationsService.getTranslations();

    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };

    this.inewService.getCategoriesItems(
      localStorage.getItem('currentLanguage')
    ).subscribe((data: any) => {
      if (data.status) {
        data.result.forEach((item) => {
          this.categoriesList.push({
            label: item.name,
            value: item.id
          });
        });
      }
    });

    this.inewService.getAPTagsItems(
      localStorage.getItem('currentLanguage')
    ).subscribe((data: any) => {
      if (data.status) {
        data.result.forEach((item) => {
          this.apTagsList.push({
            label: item.name,
            value: item.id
          });
        });
      }
    });
  }

  ngOnInit(): void {
    this.language = localStorage.getItem('currentLanguage');
    if (this.language === 'Spanish') {
      this.languageLang = this.translations.espanol;
    } else if (this.language === 'German') {
      this.languageLang = this.translations.aleman;
    } else if (this.language === 'French') {
      this.languageLang = this.translations.frances;
    }
  }

  ToogleOptions(): void {
    this.openOptions = !this.openOptions;
  }

  ToogleSearch(): void {
    this.openSearch = !this.openSearch;
    if (this.openSearch !== false) {
      this.takeSearch = false;
    }
    setTimeout(() => {
      document.getElementById('inputGlobalSearch').focus();
    }, 500);
  }

  APThemesOptions(): Selectitem[] {
    const ret: Selectitem[] = [];
    let tmp: Selectitem = {
      label: 'Global Challenges',
      value: '1'
    };
    ret.push(tmp);
    tmp = {
      label: 'Science and Technology',
      value: '2'
    };
    ret.push(tmp);
    tmp = {
      label: 'Contemporary Life',
      value: '3'
    };
    ret.push(tmp);
    tmp = {
      label: 'Personal and Public Identities',
      value: '4'
    };
    ret.push(tmp);
    tmp = {
      label: 'Families and Communities',
      value: '5'
    };
    ret.push(tmp);
    tmp = {
      label: 'Beauty and Aesthetics',
      value: '6'
    };
    ret.push(tmp);

    return ret;
  }

  LevelOptions(): Selectitem[] {
    const ret: Selectitem[] = [];
    let tmp: Selectitem = {
      label: this.translations.basico,
      value: 'Basic'
    };
    ret.push(tmp);
    tmp = {
      label: this.translations.avanzado,
      value: 'Advanced'
    };
    ret.push(tmp);

    return ret;
  }

  changeAPThemes(current: any): void {
    this.currentFilters.apthemes = current;
    this.filtersOptions.emit(this.currentFilters);
  }

  changeCategories(current: any): void {
    this.currentFilters.categories = current;
    this.filtersOptions.emit(this.currentFilters);
  }

  changeLevel(current: any): void {
    this.currentFilters.level = current;
    this.filtersOptions.emit(this.currentFilters);
  }

  changeDate(current: any): void {
    this.currentFilters.date = current;
    this.filtersOptions.emit(this.currentFilters);
  }

  ChangeSearch(current: string): void {
    if (current && current !== '' && current.length >= 4) {
      this.takeSearch = true;
    } else {
      this.takeSearch = false;
    }

    this.searchedItemsiPassport = [];
    this.searchedItemsiNews = [];
    this.searchedItemsiVideos = [];
    this.searchedItemsiSongs = [];
    this.searching = true;
    this.numberItemsSearch = 0;
    if (current && current !== '' && current.length >= 4) {
      this.ipassportService.getGlobalSearchItems(
        current,
        localStorage.getItem('currentLanguage'),
        this.currentFilters.apthemes,
        this.currentFilters.categories,
        this.currentFilters.date
      ).subscribe((data: any) => {
        if (data.status) {
          this.searchedItemsiPassport = [];
          this.searchedItemsiNews = [];
          this.searchedItemsiVideos = [];
          this.searchedItemsiSongs = [];
          this.numberItemsSearch = 0;
          data.result.forEach((item) => {
            if(item.element_category == 'ipassport') {
              this.searchedItemsiPassport.push(
                this.processItemFromApi(item)
              );
            } else if(item.element_category == 'inews') {
              this.searchedItemsiNews.push(
                this.processItemFromApi(item)
              );
            } else if(item.element_category == 'ivideos') {
              this.searchedItemsiVideos.push(
                this.processItemFromApi(item)
              );
            } else if(item.element_category == 'isongs') {
              this.searchedItemsiSongs.push(
                this.processItemFromApi(item)
              );
            }
          });
        }
        this.numberItemsSearch = this.searchedItemsiPassport.length +
          this.searchedItemsiNews.length +
          this.searchedItemsiVideos.length +
          this.searchedItemsiSongs.length;
        this.searching = false;
      },
      error => {
        this.numberItemsSearch = 0;
        this.searching = false;
      });
    }
  }


  processItemFromApi(item: any): Itemcard {
    return {
      id: item.id,
      category: item.cat_name ? item.cat_name : item.cat,
      title: item.titulo,
      image: item.image,
      text: item.intro,
      type: 'passport',
      month: '',
      year: '',
      date: '',
      level: item.tipo ? (item.tipo === '1' ? this.translations.basico : this.translations.avanzado) : '',
      block: item.element_category
    };
  }

  OpenCategoriesSelector(): void {
    this.openCategorySelector = true;
    this.openApthemesSelector = false;
    this.openDateSelector = false;
    this.openLevelSelector = false;
  }

  CloseCategoriesSelector(): void {
    this.openCategorySelector = false;
  }

  OpenApthemesSelector(): void {
    this.openCategorySelector = false;
    this.openApthemesSelector = true;
    this.openDateSelector = false;
    this.openLevelSelector = false;
  }

  CloseApthemesSelector(): void {
    this.openApthemesSelector = false;
  }

  OpenLevelSelector(): void {
    this.openCategorySelector = false;
    this.openApthemesSelector = false;
    this.openDateSelector = false;
    this.openLevelSelector = true;
  }

  CloseLevelSelector(): void {
    this.openLevelSelector = false;
  }

  openItemiPassport(itemid: number): void {
    this.router.navigated = false;
    this.router.navigate(['/ipassport/' + itemid.toString()]);
  }

  openItemiNews(itemid: number): void {
    this.router.navigated = false;
    this.router.navigate(['/inews/' + itemid.toString()]);
  }

  openItemiVideos(itemid: number): void {
    this.router.navigated = false;
    this.router.navigate(['/ivideos/' + itemid.toString()]);
  }

  openItemiSongs(itemid: number): void {
    this.router.navigated = false;
    this.router.navigate(['/isongs/' + itemid.toString()]);
  }

  openMobileMenu(): void {
    this.showMobileMenu = true;
  }
  closeMobileMenu(): void {
    this.showMobileMenu = false;
  }
}
