import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';

import { JwtInterceptor, ErrorInterceptor } from './_helpers/';

import { AppRoutingModule } from './app-routing.module';
import { FormsModule } from '@angular/forms';
import { AppComponent } from './app.component';
import { FooterComponent } from './footer/footer.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { IpassportComponent } from './ipassport/ipassport.component';
import { HomeComponent } from './home/home.component';
import { HeaderComponent } from './header/header.component';
import { ElementCardComponent } from './element-card/element-card.component';
import { IvideosComponent } from './ivideos/ivideos.component';
import { IsongsComponent } from './isongs/isongs.component';
import { InewsComponent } from './inews/inews.component';
import { LoadingComponent } from './loading/loading.component';
import { IpassportitemComponent } from './ipassportitem/ipassportitem.component';
import { VideoplayerComponent } from './videoplayer/videoplayer.component';

import { ProcessSubtitlesPipe } from './_pipes/process-subtitles.pipe';
import { IvideositemComponent } from './ivideositem/ivideositem.component';
import { IsongsitemComponent } from './isongsitem/isongsitem.component';
import { AudioplayerComponent } from './audioplayer/audioplayer.component';
import { InewslistComponent } from './inewslist/inewslist.component';
import { InewsitemComponent } from './inewsitem/inewsitem.component';
import { ElementMonthCardComponent } from './element-month-card/element-month-card.component';
import { QuestionTextareaComponent } from './question-textarea/question-textarea.component';
import { QuestionPollBooleanComponent } from './question-poll-boolean/question-poll-boolean.component';
import { QuestionPollComponent } from './question-poll/question-poll.component';
import { FilterSelectComponent } from './filter-select/filter-select.component';
import { KaraokeplayerComponent } from './karaokeplayer/karaokeplayer.component';
import { AudioComponent } from './karaokeplayer/audio/audio.component';
import { LyricsComponent } from './karaokeplayer/lyrics/lyrics.component';
import { CollapsibleElementComponent } from './collapsible-element/collapsible-element.component';
import { CollapsibleElementTextareaComponent } from './collapsible-element-textarea/collapsible-element-textarea.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { ModalColumnComponent } from './modal-column/modal-column.component';
import { DateSelectComponent } from './date-select/date-select.component';
import { ArchiveComponent } from './archive/archive.component';
import { ArchiveCardComponent } from './archive-card/archive-card.component';
import {DateYearMonthSelectComponent} from './date-year-month-select/date-year-month-select.component';

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    LoginFormComponent,
    IpassportComponent,
    HomeComponent,
    HeaderComponent,
    ElementCardComponent,
    IvideosComponent,
    IsongsComponent,
    InewsComponent,
    LoadingComponent,
    IpassportitemComponent,
    VideoplayerComponent,
    ProcessSubtitlesPipe,
    IvideositemComponent,
    IsongsitemComponent,
    AudioplayerComponent,
    InewslistComponent,
    InewsitemComponent,
    ElementMonthCardComponent,
    QuestionTextareaComponent,
    QuestionPollBooleanComponent,
    QuestionPollComponent,
    FilterSelectComponent,
    KaraokeplayerComponent,
    AudioComponent,
    LyricsComponent,
    CollapsibleElementComponent,
    CollapsibleElementTextareaComponent,
    NavigationBarComponent,
    ModalColumnComponent,
    DateSelectComponent,
    ArchiveComponent,
    ArchiveCardComponent,
    DateYearMonthSelectComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    NgxAudioPlayerModule,
    BrowserAnimationsModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
