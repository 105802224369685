import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import { Inewlistitem } from '../_models/inewlistitem';
import {Translations} from '../_models/translations';
import {AuthenticationService} from '../_services/authentication.service';
import {TranslationsService} from '../_services/translations.service';
import {Common} from '../_helpers/common';

@Component({
  selector: 'app-inewslist',
  templateUrl: './inewslist.component.html',
  styleUrls: ['./inewslist.component.scss']
})
export class InewslistComponent implements OnInit, OnChanges {
  translations: Translations;
  monthLabel = '';
  type = '1';

  @Input() item: Inewlistitem[];
  @Input() initialType;
  @Input() month;
  @Input() year;
  @Input() levelFilter: string = '';
  @Output() closeModal = new EventEmitter();
  @Output() openItem = new EventEmitter();

  constructor(
    private translationsService: TranslationsService,
    private common: Common
  ) {
    this.translations = translationsService.getTranslations();
  }

  ngOnInit(): void {
    if(this.month !== '') {
      this.getLabelMonth();
    }
    if(this.initialType && this.initialType !== '') {
      this.type = this.initialType;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'month': {
            this.month = changes[propName].currentValue.toString();
            this.getLabelMonth();
          }
        }
      }
    }
  }

  CutText(text: string): string {
    return this.common.CutText(text, 70);
  }

  getLabelMonth(): void {
    switch (this.month)
    {
      case '1':
        this.monthLabel = this.translations.enero.toUpperCase();
        break;
      case '2':
        this.monthLabel = this.translations.febrero.toUpperCase();
        break;
      case '3':
        this.monthLabel = this.translations.marzo.toUpperCase();
        break;
      case '4':
        this.monthLabel = this.translations.abril.toUpperCase();
        break;
      case '5':
        this.monthLabel = this.translations.mayo.toUpperCase();
        break;
      case '6':
        this.monthLabel = this.translations.junio.toUpperCase();
        break;
      case '7':
        this.monthLabel = this.translations.julio.toUpperCase();
        break;
      case '8':
        this.monthLabel = this.translations.agosto.toUpperCase();
        break;
      case '9':
        this.monthLabel = this.translations.septiembre.toUpperCase();
        break;
      case '10':
        this.monthLabel = this.translations.octubre.toUpperCase();
        break;
      case '11':
        this.monthLabel = this.translations.noviembre.toUpperCase();
        break;
      case '12':
        this.monthLabel = this.translations.diciembre.toUpperCase();
        break;
    }
  }

  changeListType(): void {
    this.type = (this.type === '1') ? '2' : '1';
  }

  currentListType(): string {
    return (this.levelFilter !== '') ? (this.levelFilter === 'Advanced' ? '2' : '1') : this.type;
  }
}
