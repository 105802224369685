import { Component, EventEmitter, Input, OnInit, Output, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Subscription } from 'rxjs';
import * as LRC from 'lrc.js';

import { Lyriclrc } from '../../_models/lyriclrc';

export interface Line {
  index: number;
  text: string;
}

@Component({
  selector: 'app-lyrics',
  templateUrl: './lyrics.component.html',
  styleUrls: ['./lyrics.component.scss']
})
export class LyricsComponent implements OnInit, OnDestroy {

  @Input() src = '';
  @Input() onCurrentTimeUpdate: EventEmitter<number>;
  private timeSubscription: Subscription;
  public lyrics: Lyriclrc;
  public currentLineIndex = -1;
  public lines: Line[] = [];

  constructor(
    private http: HttpClient
  ) { }

  ngOnInit(): void {
    this.loadLyrics(this.src);
  }

  ngOnDestroy(): void {
    if (this.timeSubscription) {
      this.timeSubscription.unsubscribe();
    }
  }

  loadLyrics(src): void {
    this.http.get('' + src, {responseType: 'text'})
      .subscribe((data: any) => {
        this.processLyrics(data);
        this.timeSubscription = this.onCurrentTimeUpdate.subscribe(this.handleUpdateTime);
      });
  }

  processLyrics(lrcText): void {
    this.lyrics = LRC.parse(lrcText);
    this.currentLineIndex = -1;
    this.lines = [];
  }

  handleUpdateTime = (currentTime: number) => {
    this.getCurrentLine(currentTime);
  }

  existsCurrentIndex = (current: number): boolean => {
    const exists = this.lines.filter((element) => {
      return element.index === current;
    });
    return (exists.length > 0);
  }

  getCurrentLine = (currentTime: number) => {
    const { lines } = this.lyrics;
    const lastLine = lines.length - 1;
    let lineIndex = lines.findIndex((line) => ( line.time >= (currentTime + 0.3) ));
    const previousLine = lines[this.currentLineIndex];
    let currentLineIndex = (lineIndex - 1);
    let currentLine = (lineIndex > 0) ? lines[currentLineIndex] : null;
    console.log(lines);
    console.log(lineIndex);
    console.log(currentLineIndex);
    console.log(currentTime + 0.3);

    if (currentTime === 0 || lineIndex === 0) {
      // Initial configuration:
      this.lines = [];
      this.lines.push({ index: -1, text: '  ' });
      this.lines.push({ index: 0, text: lines[0].text });
      this.lines.push({ index: 1, text: lines[1].text });
      this.lines.push({ index: 2, text: lines[2].text });
    }

    // Check last lines
    if (lineIndex === -1) {
    //   this.lines = [];
    //   this.lines.push({ index: lastLine - 1, text: lines[lastLine - 1].text });
    //   this.lines.push({ index: lastLine, text: lines[lastLine].text });
    //   this.lines.push({ index: lastLine + 1, text: '  ' });
    //   this.lines.push({ index: lastLine + 2, text: '  ' });
      lineIndex = lastLine;
      currentLineIndex = lastLine;
      currentLine = lines[lastLine];
    }

    // We need to check a time jump:
    if (!this.existsCurrentIndex(currentLineIndex)) {
      // If the element is not present at the lines list, we have to insert a new list of lines (jump in the time):
      this.lines = [];

      const prev = (lineIndex - 1);
      let textPrev = '  ';
      if (prev > 0) {
        textPrev = lines[prev].text;
      }

      this.lines.push({ index: (lineIndex - 1), text: textPrev });
      this.lines.push({ index: lineIndex, text: lines[lineIndex].text });
      if (lineIndex < lastLine) {
        this.lines.push({index: (lineIndex + 1), text: lines[(lineIndex + 1)].text});
      } else {
        this.lines.push({index: (lineIndex + 1), text: '  '});
      }
      if ((lineIndex + 1) < lastLine) {
        this.lines.push({index: (lineIndex + 2), text: lines[(lineIndex + 2)].text});
      } else {
        this.lines.push({index: (lineIndex + 2), text: '  '});
      }
    }

    // New line
    if (currentLine && currentLine !== previousLine) {
      this.currentLineIndex = currentLineIndex;

      // We have to animate the exit of the first line:
      const idFirstItem = this.lines[0].index;
      const eleDom = document.getElementById('lyrics__' + idFirstItem);

      if (eleDom) {
        eleDom.classList.add('remove');

        // Wait until animation finish (500ms in CSS transition):
        setTimeout(() => {
          // Remove the line in our array
          if (this.lines.length > 1) {
            this.lines.shift();
          }

          // Add new line:
          const nextId = (this.lines[this.lines.length - 1].index + 1);

          // Looking for the next line:
          eleDom.classList.add('remove');
          if (lines[nextId]) {
            this.lines.push({index: nextId, text: lines[nextId].text});
          }
        }, 500);
      }
    }
  }
}
