import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { IpassportComponent } from './ipassport/ipassport.component';
import { InewsComponent } from './inews/inews.component';
import { IvideosComponent } from './ivideos/ivideos.component';
import { IsongsComponent } from './isongs/isongs.component';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './_helpers/auth.guard';
import { ArchiveComponent } from './archive/archive.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'enter/:language', component: HomeComponent },
  { path: 'enter/:language/:date', component: HomeComponent },
  { path: 'entersince/:date', component: HomeComponent },
  { path: 'inews', component: InewsComponent, canActivate: [AuthGuard] },
  { path: 'inews/:year/:month', component: InewsComponent, canActivate: [AuthGuard] },
  { path: 'inews/:id', component: InewsComponent, canActivate: [AuthGuard] },
  { path: 'inews/:id/lang/:language', component: InewsComponent },
  { path: 'ivideos', component: IvideosComponent, canActivate: [AuthGuard] },
  { path: 'ivideos/:id', component: IvideosComponent, canActivate: [AuthGuard] },
  { path: 'ivideos/:id/lang/:language', component: IvideosComponent },
  { path: 'isongs', component: IsongsComponent, canActivate: [AuthGuard] },
  { path: 'isongs/:id', component: IsongsComponent, canActivate: [AuthGuard] },
  { path: 'isongs/:id/lang/:language', component: IsongsComponent },
  { path: 'ipassport', component: IpassportComponent, canActivate: [AuthGuard] },
  { path: 'ipassport/:id', component: IpassportComponent, canActivate: [AuthGuard] },
  { path: 'ipassport/:id/lang/:language', component: IpassportComponent },
  { path: 'archive/:section', component: ArchiveComponent, canActivate: [AuthGuard] },

  // otherwise redirect to home
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {scrollPositionRestoration: 'top'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
