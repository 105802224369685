<div class="innerCard" style="border-left: 2px solid {{ color }}">
  <div class="containerImage">
    <img src="{{ img }}" >
  </div>
  <div class="containerText">
    <p class="card__title" style="color: {{ color }};">{{ title }}</p>
    <div class="container__cardText">
      <p class="card__text">{{ text }}</p>
    </div>
    <p class="card__section" style="color: {{ color }};">
      <span class="{{ icon }}"></span> {{ section }}
    </p>
  </div>
</div>
