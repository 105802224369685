<div class="collapsible-element mobile-up-hidden" [ngClass]="collapsibleOpen === false ? 'closed' : 'opened'">
  <div class="collapsible__title">
    <p class="cursorLink" (click)="toggleCollapsible()">
      <strong>{{ title }}</strong></p>
    <span class="icon-up-arrow cursorLink" (click)="toggleCollapsible()"></span>
  </div>
  <div class="collapsible__body" [ngClass]="body && body !== '' ? '' : 'hiddenItem'">
    <div [innerHTML]="body"></div>
  </div>
  <div class="collapsible__body" [ngClass]="!body || body === '' ? '' : 'hiddenItem'">
    <ng-content></ng-content>
  </div>
</div>
