<div class="textareaContainer {{ extraClass }}">
  <textarea id="text-{{ uuid }}" placeholder="{{ placeHolder }}" class="{{ textareaClass }}" [ngModel]="text" (ngModelChange)="onChange($event)"></textarea>
  <div class="characters characters-spanish" [ngClass]="currentLanguage === 'Spanish' ? '' : 'hiddenItem'">
    <button (click)="inschar('ñ')">ñ</button>
    <button (click)="inschar('Ñ')">Ñ</button>
    <button (click)="inschar('á')">á</button>
    <button (click)="inschar('Á')">Á</button>
    <button (click)="inschar('é')">é</button>
    <button (click)="inschar('É')">É</button>
    <button (click)="inschar('í')">í</button>
    <button (click)="inschar('Í')">Í</button>
    <button (click)="inschar('ó')">ó</button>
    <button (click)="inschar('Ó')">Ó</button>
    <button (click)="inschar('ú')">ú</button>
    <button (click)="inschar('Ú')">Ú</button>
    <button (click)="inschar('¿')">¿</button>
    <button (click)="inschar('¡')">¡</button>
  </div>
  <div class="characters characters-german" [ngClass]="currentLanguage === 'German' ? '' : 'hiddenItem'">
    <button (click)="inschar('Ä')">Ä</button>
    <button (click)="inschar('ä')">ä</button>
    <button (click)="inschar('Ö')">Ö</button>
    <button (click)="inschar('ö')">ö</button>
    <button (click)="inschar('É')">É</button>
    <button (click)="inschar('é')">é</button>
    <button (click)="inschar('Ü')">Ü</button>
    <button (click)="inschar('ü')">ü</button>
    <button (click)="inschar('ß')">ß</button>
  </div>
  <div class="characters characters-french" [ngClass]="currentLanguage === 'French' ? '' : 'hiddenItem'">
    <button (click)="inschar('À')">À</button>
    <button (click)="inschar('à')">à</button>
    <button (click)="inschar('Â')">Â</button>
    <button (click)="inschar('â')">â</button>
    <button (click)="inschar('Á')">Á</button>
    <button (click)="inschar('á')">á</button>
    <button (click)="inschar('Æ')">Æ</button>
    <button (click)="inschar('æ')">æ</button>
    <button (click)="inschar('È')">È</button>
    <button (click)="inschar('è')">è</button>
    <button (click)="inschar('Ê')">Ê</button>
    <button (click)="inschar('ê')">ê</button>
    <button (click)="inschar('É')">É</button>
    <button (click)="inschar('é')">é</button>
    <button (click)="inschar('Ë')">Ë</button>
    <button (click)="inschar('ë')">ë</button>
    <button (click)="inschar('Î')">Î</button>
    <button (click)="inschar('î')">î</button>
    <button (click)="inschar('Ï')">Ï</button>
    <button (click)="inschar('ï')">ï</button>
    <button (click)="inschar('Ô')">Ô</button>
    <button (click)="inschar('ô')">ô</button>
    <button (click)="inschar('Œ')">Œ</button>
    <button (click)="inschar('œ')">œ</button>
    <button (click)="inschar('Ù')">Ù</button>
    <button (click)="inschar('ù')">ù</button>
    <button (click)="inschar('Û')">Û</button>
    <button (click)="inschar('û')">û</button>
    <button (click)="inschar('Ü')">Ü</button>
    <button (click)="inschar('ü')">ü</button>
    <button (click)="inschar('ÿ')">ÿ</button>
    <button (click)="inschar('Ç')">Ç</button>
    <button (click)="inschar('ç')">ç</button>
    <button (click)="inschar('«')">«</button>
    <button (click)="inschar('»')">»</button>
  </div>
</div>
