import {Component, Input, OnInit} from '@angular/core';
import {Ipassportitem} from '../_models/ipassportitem';

@Component({
  selector: 'app-collapsible-element',
  templateUrl: './collapsible-element.component.html',
  styleUrls: ['./collapsible-element.component.scss']
})
export class CollapsibleElementComponent implements OnInit {
  collapsibleOpen = false;

  @Input() title;
  @Input() body;

  constructor() { }

  ngOnInit(): void {}

  toggleCollapsible(): void {
    this.collapsibleOpen = !this.collapsibleOpen;
  }
}
