import {Component, EventEmitter, Input, OnInit, Output, HostListener, ElementRef} from '@angular/core';
import { Selectitem } from '../_models/selectitem';

@Component({
  selector: 'app-filter-select',
  templateUrl: './filter-select.component.html',
  styleUrls: ['./filter-select.component.scss']
})
export class FilterSelectComponent implements OnInit {
  currentText = '';
  currentSelected = '';
  removeSelectedIcon = false;
  private wasInside = false;
  // showOptions = false;

  @Input() name = '';
  @Input() selected = '';
  @Input() forceOneElement: boolean = false;
  @Input() options: Selectitem[] = [];
  @Input() extraClass = '';
  @Input() twoColumns = false;
  @Input() opened = false;
  @Output() changeOption = new EventEmitter();
  @Output() openSelector = new EventEmitter();
  @Output() closeSelector = new EventEmitter();

  @HostListener('click')
  clickInside(): void {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout(): void {
    if (!this.wasInside) {
      if (this.opened === true) {
        this.closeSelector.emit();
      }
    }
    this.wasInside = false;
  }

  constructor() { }

  ngOnInit(): void {
    this.currentText = this.name;
    if(this.selected && this.selected !== '') {
      this.currentSelected = this.selected;
      this.currentText = this.selected;
    }
  }

  ChangeOption(value: string): void {
    if (this.currentSelected === value && this.forceOneElement === false) {
      this.currentSelected = '';
      this.currentText = this.name;
      this.removeSelectedIcon = false;
    } else {
      this.currentSelected = value;
      const currentOption = this.options.filter((item) => {
        return item.value === value;
      });
      if (currentOption.length > 0) {
        this.currentText = currentOption[0].label;
        this.removeSelectedIcon = true;
      }
    }
    // this.showOptions = false;
    this.changeOption.emit(this.currentSelected);
    this.closeSelector.emit();
  }

  CloseCurrentOption(): void {
    this.currentSelected = '';
    this.currentText = this.name;
    this.removeSelectedIcon = false;
    this.changeOption.emit(this.currentSelected);
    this.closeSelector.emit();
  }

  ToogleItemsList(): void {
    if (this.opened === true) {
      this.closeSelector.emit();
    } else {
      this.openSelector.emit();
    }
  }
}
