<div class="player">
  <div class="player-screen">
    <div class="player-screen-lyrics p1">
      <app-lyrics
        [src]="currentSong.lyrics"
        [onCurrentTimeUpdate]="lyricsTimeUpdate"
      ></app-lyrics>
    </div>
    <div class="player-screen-controls p2">
      <app-audio
        [autoPlay]="true"
        [src]="currentSong.audio"
        (CurrentTimeUpdate)="handleAudioTimeUpdate($event)"
      ></app-audio>
    </div>
  </div>
</div>
