<app-header option="isongs"
            (filtersOptions)="ChangeFilters($event)"
            color="{{ color }}"
            (openItem)="handleItemClick($event)"
            monthSelectorType="month"
></app-header>
<main>
  <section id="containeriSongs" class="mainContainerBG"
           [ngClass]="itemId > 0 && itemPreview && itemPreviewSubtitles ? 'mobile-hidden' : ''">
    <p class="sectionTitle">{{ translations.mas_reciente }}</p>
    <div class="elementsList">
      <app-loading *ngIf="searching === true;else searchedRecent"></app-loading>
      <ng-template #searchedRecent>
        <div *ngIf="!currentItems || currentItems.length === 0;else resultsRecent">
          <p class="notFound">{{ translations.not_found }}</p>
        </div>
        <ng-template #resultsRecent>
          <div class="elementCard bucle" *ngFor="let item of currentItems">
            <app-element-card
              color="{{color}}"
              title="{{item.title}}"
              img="{{item.image}}"
              text="{{item.text}}"
              section="{{sectionName}}"
              icon="{{ iconPath }}"
              (click)="handleItemClick(item.id)"
              class="cursorLink"
            ></app-element-card>
          </div>
        </ng-template>
      </ng-template>
    </div>
    <p class="sectionTitle">{{ translations.archivo }} <a href="archive/iSongs" target="_blank"><span class="icon-archive"></span></a></p>
    <div class="elementsList">
      <app-loading *ngIf="searching === true;else searchedArchive"></app-loading>
      <ng-template #searchedArchive>
        <div *ngIf="!archivedItemsAboveRow || archivedItemsAboveRow.length === 0;else resultsArchive">
          <p class="notFound">{{ translations.not_found }}</p>
        </div>
        <ng-template #resultsArchive>
          <div>
            <div class="elementCard bucle" *ngFor="let item of archivedItemsAboveRow">
              <app-element-card
                color="{{color}}"
                title="{{item.title}}"
                img="{{item.image}}"
                text="{{item.text}}"
                section="{{sectionName}}"
                icon="{{iconPath}}"
                (click)="handleItemClick(item.id)"
                class="cursorLink"
              ></app-element-card>
            </div>
          </div>
          <div>
            <div class="elementCard bucle" *ngFor="let item of archivedItemsBelowRow">
              <app-element-card
                color="{{color}}"
                title="{{item.title}}"
                img="{{item.image}}"
                text="{{item.text}}"
                section="{{sectionName}}"
                icon="{{iconPath}}"
                (click)="handleItemClick(item.id)"
                class="cursorLink"
              ></app-element-card>
            </div>
          </div>
        </ng-template>
      </ng-template>
    </div>
  </section>
</main>
<div *ngIf="itemId > 0 && itemPreview && itemPreviewSubtitles">
  <app-isongsitem
    (closeModal)="handleCloseModal()"
    [item]=itemPreview
    itemsubtitles="{{ itemPreviewSubtitles.url }}"
  ></app-isongsitem>
</div>
