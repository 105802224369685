import {Component, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import { InewService } from '../_services/inew.service';
import { InewitemService } from '../_services/inewitem.service';
import {Itemcard} from '../_models/itemcard';
import {Inewlistitem} from '../_models/inewlistitem';
import {Inewitem} from '../_models/inewitem';
import {Filtersobject} from '../_models/filtersobject';
import {Translations} from '../_models/translations';
import {AuthenticationService} from '../_services/authentication.service';
import {TranslationsService} from '../_services/translations.service';
import {Common} from '../_helpers/common';

@Component({
  selector: 'app-inews',
  templateUrl: './inews.component.html',
  styleUrls: ['./inews.component.scss']
})
export class InewsComponent implements OnInit {
  translations: Translations;
  searchedItems: Itemcard[] = [];
  searching = false;
  currentItems: Itemcard[] = [];
  archivedItemsAboveRow: Itemcard[] = [];
  archivedItemsBelowRow: Itemcard[] = [];
  sectionName = 'iNews';
  iconPath = 'icon-wifi';
  color = '#EC8F23';
  monthId: number;
  yearId: number;
  monthItemsList: Inewlistitem[] = [];
  itemId: number;
  itemPreview: Inewitem = null;
  initialType = '';
  currentFilters: Filtersobject = {
    date: null, level: null, apthemes: null, categories: null
  };

  constructor(
    private inewService: InewService,
    private inewitemService: InewitemService,
    private actRoute: ActivatedRoute,
    private location: Location,
    private translationsService: TranslationsService,
    private common: Common
  ) {
    this.common.bodyScroll();
    if (this.actRoute.snapshot.params.language) {
      const langNew = this.actRoute.snapshot.params.language;
      if (langNew === 'Spanish' || langNew === 'French' || langNew === 'German') {
        localStorage.setItem('currentLanguage', langNew);
      }
    }

    if (this.actRoute.snapshot.params.id) {
      // Get ID from URL
      this.itemId = this.actRoute.snapshot.params.id;
      // Call to API to retrieve ID item information
      this.readItemPreview();
    } else if (this.actRoute.snapshot.params.month && this.actRoute.snapshot.params.year) {
      // Get ID from URL
      this.monthId = this.actRoute.snapshot.params.month;
      this.yearId = this.actRoute.snapshot.params.year;
      // Call to API to retrieve ID item information
      this.readMonthItemsList();
    } else {
      this.itemId = null;
    }
    this.location = location;
    this.initialType = '';
    this.actRoute.queryParams.subscribe(params => {
      this.initialType = params.type;
    });

    this.translations = translationsService.getTranslations();
  }

  ngOnInit(): void {
    this.callToAPI();
  }

  callToAPI(): void {
    this.currentItems = [];
    this.archivedItemsAboveRow = [];
    this.archivedItemsBelowRow = [];
    this.searching = true;
    this.inewService.getAllItems(
      localStorage.getItem('currentLanguage'),
      this.currentFilters.apthemes,
      this.currentFilters.categories,
      this.currentFilters.date,
      this.currentFilters.level
    ).subscribe((data: any) => {
      if (data.status) {
        data.result.forEach((item, index) => {
          if(index < 9) {
            this.currentItems.push(
              this.processItemFromApi(item, true)
            );
          } else {
            if (index % 2 === 1) {
              this.archivedItemsAboveRow.push(
                this.processItemFromApi(item, true)
              );
            } else {
              this.archivedItemsBelowRow.push(
                this.processItemFromApi(item, true)
              );
            }
          }
        });
      }
      this.searching = false;
    },
    error => {
      console.log(error);
      this.searching = false;
    });
  }

  processItemFromApi(item: any, archive: boolean = false, search: boolean = false): Itemcard {
    const title = (!archive) ?
      this.convertMonthToText(item.month) :
      this.convertMonthToText(item.month) + ' ' + item.year;
    return {
      category: item.cat_name ? item.cat_name : item.cat,
      date: item.vdatum,
      level: item.tipo === '1' ? this.translations.basico.toUpperCase() : this.translations.avanzado.toUpperCase(),
      id: item.id,
      title: search ? item.titulo : title,
      image: item.image,
      text: item.numberItems + ' ' + this.translations.num_articulos,
      type: 'new',
      month: item.month,
      year: item.year,
      block: ''
    };
  }

  convertMonthToText(month: string): string {
    switch (month)
    {
      case '1':
        return this.translations.enero.toUpperCase();
      case '2':
        return this.translations.febrero.toUpperCase();
      case '3':
        return this.translations.marzo.toUpperCase();
      case '4':
        return this.translations.abril.toUpperCase();
      case '5':
        return this.translations.mayo.toUpperCase();
      case '6':
        return this.translations.junio.toUpperCase();
      case '7':
        return this.translations.julio.toUpperCase();
      case '8':
        return this.translations.agosto.toUpperCase();
      case '9':
        return this.translations.septiembre.toUpperCase();
      case '10':
        return this.translations.octubre.toUpperCase();
      case '11':
        return this.translations.noviembre.toUpperCase();
      case '12':
        return this.translations.diciembre.toUpperCase();
    }
  }

  readMonthItemsList(): void {
    this.inewService.getMonthList(
      this.yearId,
      this.monthId,
      localStorage.getItem('currentLanguage'),
      this.currentFilters.apthemes,
      this.currentFilters.categories,
      this.currentFilters.date,
      this.currentFilters.level
    ).subscribe((data: any) => {
      if (data.status) {
        data.result.forEach((item) => {
          const lang = localStorage.getItem('currentLanguage');
          if(lang === 'Spanish') {
            item.category = item.spanish_cat;
          } else if(lang === 'French') {
            item.category = item.french_cat;
          } else if(lang === 'German') {
            item.category = item.german_cat;
          }
          this.monthItemsList.push(item);
        });
        this.loadItemPopup();
      }
    });
  }

  readItemPreview(): void {
    this.inewitemService.getItem(this.itemId).subscribe((data: any) => {
      if (data.status) {
        data.result.forEach((item: Inewitem) => {
          this.itemPreview = item;
        });
        this.loadItemPopup();
      }
    });
  }

  loadItemPopup(): void {
    this.common.bodyNoScroll();
  }

  handleItemClick(year: string, month: string): void {
    this.yearId = parseInt(year, 10);
    this.monthId = parseInt(month, 10);
    // this.location.go('inew/' + year + '/' + month);
    // Call to API to retrieve ID item information
    this.readMonthItemsList();
  }

  handleCloseModal(): void {
    this.monthId = null;
    this.yearId = null;
    this.monthItemsList = [];
    // this.location.go('inew/');
    this.common.bodyScroll();
  }

  handleCloseiNewModal(): void {
    this.itemId = null;
    let param = '';
    if(this.itemPreview.tipo === '2') {
      param = '?type=2';
      this.initialType = '2';
    }
    this.itemPreview = null;
    // this.location.go('inew/' + this.yearId + '/' + this.monthId + param);
  }

  handleChangeiNewRelated(): void {
    const newItemId = parseInt(this.itemPreview.relatedId, 10);

    this.itemId = 0; // Reset the preview Form of the item
    this.itemId = newItemId;
    this.itemPreview = null; // Reset the preview Form of the item

    // this.location.go('inew/' + this.itemPreview.relatedId);
    // Call to API to retrieve ID item information
    this.readItemPreview();
  }

  handleiNewItemClick(itemId: any): void {
    this.itemId = itemId;
    // this.location.go('inew/' + itemId);
    // Call to API to retrieve ID item information
    this.readItemPreview();
  }

  ChangeFilters(element: Filtersobject): void {
    this.currentFilters.apthemes = null;
    this.currentFilters.categories = null;
    this.currentFilters.date = null;
    this.currentFilters.level = null;

    if (element.apthemes !== '') {
      this.currentFilters.apthemes = element.apthemes;
    }
    if (element.categories !== '') {
      this.currentFilters.categories = element.categories;
    }
    if (element.date !== '') {
      this.currentFilters.date = element.date;
    }
    if (element.level !== '') {
      this.currentFilters.level = element.level;
    }

    this.callToAPI();
  }
}
