import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Inewitem} from '../_models/inewitem';
import {AuthenticationService} from '../_services/authentication.service';
import {TranslationsService} from '../_services/translations.service';
import {Translations} from '../_models/translations';
import {navigationoption} from '../_models/navigationoption';
import {Common} from '../_helpers/common';
import {Inewanswer} from '../_models/inewanswer';

@Component({
  selector: 'app-inewsitem',
  templateUrl: './inewsitem.component.html',
  styleUrls: ['./inewsitem.component.scss']
})
export class InewsitemComponent implements OnInit {
  translations: Translations;
  currentLanguage = '';
  currentTab = 'home';
  currentQuest = '1';
  currentPrint = '1';
  question1 = '';
  question2 = '';
  question3 = '';
  question4 = '';
  reportCertificate = false;
  reportActivityActivity = false;
  reportActivityAll = false;
  answer: Inewanswer;

  individualAspect = false;

  username = '';

  activitiesNavigationMenu: navigationoption[] = [];

  @Input() item: Inewitem;
  @Input() deactivateRelatedItem = false;
  @Input() itemsubtitles;
  @Output() closeModal = new EventEmitter();
  @Output() changeRelated = new EventEmitter();

  constructor(
    private translationsService: TranslationsService,
    private common: Common
  ) {
    this.translations = translationsService.getTranslations();
    this.currentLanguage = localStorage.getItem('currentLanguage');
    this.answer = new class implements Inewanswer {
      a1q1: string;
      a1q2: string;
      a1q3: string;
      a1q4: string;
      a2q1: string;
      a2q2: string;
      a2q3: string;
      a2q4: string;
      activity_1: string;
      activity_2: string;
    };
    this.answer.activity_1 = '';
    this.answer.activity_2 = '';
    this.answer.a1q1 = '';
    this.answer.a1q2 = '';
    this.answer.a1q3 = '';
    this.answer.a1q4 = '';
    this.answer.a2q1 = '';
    this.answer.a2q2 = '';
    this.answer.a2q3 = '';
    this.answer.a2q4 = '';

    // Check if we have a direct link to this element:
    const currentUrl = window.location.href;
    if (currentUrl.indexOf('lang') !== -1) {
      this.individualAspect = true;
    }
  }

  ngOnInit(): void {
    let qty = 0;
    if (this.item.acta === '' && this.item.tipoa === '') {
    } else {
      ++qty;
    }
    if (this.item.actb === '' && this.item.tipob === '') {
    } else {
      ++qty;
    }
    for (let i = 1; i <= qty; ++i) {
      this.activitiesNavigationMenu.push({
        id: i.toString(),
        name: i.toString(),
        extraClass: '',
        link: ''
      });
    }
    this.answer.activity_1 = this.item.acta;
    this.answer.activity_2 = this.item.actb;

    this.common.changeThemeVar('--item-popup-color', '#EC8F23');
    this.common.changeThemeVar('--text-selected', '#fff');
  }

  ChangeTab(newTab, event): void {
    event.preventDefault();
    if (newTab !== this.currentTab) {
      this.currentTab = newTab;
    }
  }

  homeIconTooltip() {
    const eleDom = document.getElementById('HomeIcon');
    if (eleDom.classList.contains('DissableItem') === true) {
      return this.translations.actividad_bloqueada;
    } else {
      return this.translations.inicio;
    }
  }

  ChangeQuestion(newTab: string): void {
    if (newTab !== this.currentQuest) {
      this.currentQuest = newTab;
    }
  }

  ToogleReportCheck(variable: string): void {
    this[variable] = !this[variable];
  }

  ChangeAnswer(question, answer, event): void {
    event.preventDefault();
    if (question === '1') {
      this.question1 = answer;
    } else if (question === '2') {
      this.question2 = answer;
    } else if (question === '3') {
      this.question3 = answer;
    } else if (question === '4') {
      this.question4 = answer;
    }
  }

  _getQuestionLetter(question: number): string {
    return question === 1 ? 'a' : 'b';
  }

  GenerateQuestionsPollBoolean(question: number): string[] {
    const ret: string[] = [];
    const letter: string = this._getQuestionLetter(question);

    for (let num = 1; num <= 4; ++num) {
      if (this.item['xact' + letter + num] !== '') { // xacta1 or xactb1
        ret.push(this.item['xact' + letter + num]);
      }
    }

    return ret;
  }

  GenerateQuestionsPoll(question: number): string[] {
    const ret: any[] = [];
    const letter: string = this._getQuestionLetter(question);

    for (let num = 1; num <= 4; ++num) {
      // xactb1, xactb2... or xacta1, xacta2...
      const keyQ = 'xact' + letter + num.toString();
      if (this.item[keyQ] !== '') {
        const newItem = {
          question: this.item[keyQ],
          answers: []
        };
        for (let i = 1; i <= 3; ++i) {
          // bxa1, bxb1, bxc1 or axa1, axb1, axc1
          const key = letter + 'x' + String.fromCharCode(96 + i) + num.toString();
          if (this.item[key] !== '') {
            newItem.answers.push(this.item[key]);
          }
        }
        ret.push(newItem);
      }
    }

    return ret;
  }

  NextStepPrint(): void {
    this.currentPrint = '2';
  }

  onChangeRelated(): void {
    this.changeRelated.emit();
  }

  ChangeAnswerPoll(activity: number, info_answer: any): void {
    const answer_key = 'a' + activity.toString() + 'q' + info_answer.question;
    this.answer[answer_key] = (this.answer[answer_key] === '') ? info_answer.answer : this.answer[answer_key];
  }

  PrintReport(): void {
    const printArea = document.getElementById('contentToPrint');

    if (!printArea) {
      return;
    }

    printArea.innerHTML = '';

    // Título:
    let content = '<p><img src="assets/images/logo-dark@3x.png" alt="iCulture" style="width: 140px; margin-bottom: 50px"></p>';
    content += '<p><strong>iCulture Carnegie Learning</strong></p>';
    content += '<p>Inews - ' + this.item.titulo + '</p>';
    content += '<p>&nbsp;</p>';

    content += '<p>User: ' + this.username + '</p>';
    content += '<p>&nbsp;</p>';

    // Transcripción
    if (this.reportCertificate === true) {
      content += '<p><strong>' + this.translations.transcripcion + '</strong></p>';
      content += '<div>' + this.item.body + '</div>';
      content += '<p>&nbsp;</p>';
    }

    // Pregunta Actividad 1
    if (this.item.forma === '1' || this.item.forma === '2') {
      if (this.reportActivityActivity === true || this.reportActivityAll === true) {
        content += '<p><strong>' + this.translations.actividad + ' 1</strong></p>';
        // content += '<div>' + this.translations.actividad + '</div>';
        content += '<div>' + this.common.answerToText(this.item.acta) + '</div>';
        content += '<p>&nbsp;</p>';
      }
      if (this.reportActivityAll === true) {
        content += '<p><strong>Your answer:</strong></p>';
        content += this.common.answerToText(this.answer.activity_1, 'text');
        content += '<p>&nbsp;</p>';
      }
    } else if (this.item.forma === '3') {
      const questions = this.GenerateQuestionsPollBoolean(1);
      content += '<p><strong>' + this.translations.actividad + ' 1</strong></p>';
      questions.forEach((item, index) => {
        if (this.reportActivityActivity === true || this.reportActivityAll === true) {
          content += '<div>' + item + '</div>';
          content += '<p>&nbsp;</p>';
        }
        if (this.reportActivityAll === true) {
          content += '<p><strong>Your answer:</strong></p>';
          content += this.common.answerToText(this.answer['a1q' + (index + 1)]);
          content += '<p>&nbsp;</p>';
        }
      });
    } else if (this.item.forma === '4') {
      const questions = this.GenerateQuestionsPoll(1);
      content += '<p><strong>' + this.translations.actividad + ' 1</strong></p>';
      questions.forEach((item: any, index) => {
        if (this.reportActivityActivity === true || this.reportActivityAll === true) {
          content += '<div><p>' + item.question + '</p></div>';
          item.answers.forEach((answer: any, indexAnswer) => {
            content += '<p>' + this.PaintLetter(indexAnswer) + '. ' + answer + '</p>';
          });
          content += '<p>&nbsp;</p>';
        }
        if (this.reportActivityAll === true) {
          content += '<p><strong>Your answer:</strong></p>';
          content += this.common.answerToText(this.answer['a1q' + (index + 1)]);
          content += '<p>&nbsp;</p>';
        }
      });
    }

    // Pregunta Actividad 2
    if (this.item.formb === '1' || this.item.formb === '2') {
      if (this.reportActivityActivity === true || this.reportActivityAll === true) {
        content += '<p><strong>' + this.translations.actividad + ' 2</strong></p>';
        // content += '<div>' + this.translations.actividad + '</div>';
        content += '<div>' + this.common.answerToText(this.item.actb) + '</div>';
        content += '<p>&nbsp;</p>';
      }
      if (this.reportActivityAll === true) {
        content += '<p><strong>Your answer:</strong></p>';
        content += this.common.answerToText(this.answer.activity_2, 'text');
        content += '<p>&nbsp;</p>';
      }
    } else if (this.item.formb === '3') {
      const questions = this.GenerateQuestionsPollBoolean(2);
      content += '<p><strong>' + this.translations.actividad + ' 2</strong></p>';
      questions.forEach((item, index) => {
        if (this.reportActivityActivity === true || this.reportActivityAll === true) {
          content += '<div>' + item + '</div>';
          content += '<p>&nbsp;</p>';
        }
        if (this.reportActivityAll === true) {
          content += '<p><strong>Your answer:</strong></p>';
          content += this.common.answerToText(this.answer['a2q' + (index + 1)]);
          content += '<p>&nbsp;</p>';
        }
      });
    } else if (this.item.formb === '4') {
      const questions = this.GenerateQuestionsPoll(2);
      content += '<p><strong>' + this.translations.actividad + ' 2</strong></p>';
      questions.forEach((item: any, index) => {
        if (this.reportActivityActivity === true || this.reportActivityAll === true) {
          content += '<div><p>' + item.question + '</p></div>';
          item.answers.forEach((answer: any, indexAnswer) => {
            content += '<p>' + this.PaintLetter(indexAnswer) + '. ' + answer + '</p>';
          });
          content += '<p>&nbsp;</p>';
        }
        if (this.reportActivityAll === true) {
          content += '<p><strong>Your answer:</strong></p>';
          content += this.common.answerToText(this.answer['a2q' + (index + 1)]);
          content += '<p>&nbsp;</p>';
        }
      });
    }

    printArea.innerHTML = content;
    window.print();
  }

  PaintLetter(itemNumber: number): string {
    return String.fromCharCode(itemNumber + 65);
  }
}
