import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Common} from '../_helpers/common';

@Component({
  selector: 'app-date-select',
  templateUrl: './date-select.component.html',
  styleUrls: ['./date-select.component.scss']
})
export class DateSelectComponent implements OnInit {
  currentText = '';
  inputValue: Date;
  removeSelectedIcon = false;

  @Input() name = '';
  @Input() extraClass = '';
  @Output() changeOption = new EventEmitter();

  constructor(
    private common: Common
  ) { }

  ngOnInit(): void {
    this.currentText = this.name;
  }

  ChangeDatepicker(): void {
    const newDate = this.common.formatDateISO(this.inputValue);
    this.currentText = newDate;
    this.removeSelectedIcon = true;
    this.changeOption.emit(newDate);
  }

  CloseCurrentOption(): void {
    this.currentText = this.name;
    this.removeSelectedIcon = false;
    this.changeOption.emit('');
  }
}
