<div class="itemPopup">
  <div class="overlay" [ngClass]="(individualAspect === true)?'opaqueOverlay':''"></div>
  <div class="modalContainer">
    <div class="modalContainer__header mobile-up-hidden">
      <div class="header__title">
        <span class="icon-music"></span> iSongs
      </div>
      <div>
        <span class="icon-close cursorLink"
              (click)="closeModal.emit()"></span>
      </div>
    </div>

      <div class="modalContainer__wrapper">
        <div class="modalContainer__main">
        <app-loading *ngIf="!itemsubtitles || !item;else other_content"></app-loading>
        <ng-template #other_content>
          <div id="contentHome" [ngClass]="(currentTab === 'home')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column extraComponentClass="noScroll"
                              headerComponentTitle="iSongs"
                              headerComponentIcon="icon-music">
              <img src="{{ item.image }}" style="width: 100%;">
            </app-modal-column>
            <div class="modalContainer__mobileMenu mobile-up-hidden">
              <nav>
                <ul>
                  <li class="ActiveItem"><a href="#" (click)="ChangeTab('home', $event)">{{ translations.inicio }}</a>
                  </li>
                  <li><a href="#" (click)="ChangeTab('actq', $event)">{{ translations.actividad_web }}</a></li>
                  <li><a href="#" (click)="ChangeTab('word', $event)">{{ translations.palabras_dificiles }}</a></li>
                  <li><a href="#" (click)="ChangeTab('bio', $event)">{{ translations.biografia }}</a></li>
                  <li><a href="#" (click)="ChangeTab('send', $event)">{{ translations.imprimir }}</a></li>
                </ul>
              </nav>
            </div>
            <app-modal-column extraComponentClass="noScroll">
              <div class="title">{{ item.titulo }}</div>
              <br>
              <div class="text">
                <p>{{ translations.hecha_famosa_por }} {{ item.canta }}</p>
                <p [innerHTML]="translations.ver_cancion_normal"></p>
                <p [innerHTML]="translations.ver_cancion_karaoke"></p>
                <div class="selectSongIcons">
                  <a href="#" (click)="ChangeTab('ssun', $event)"><span class="icon-music buttonIcon"></span></a>
                  <a href="#" (click)="ChangeTab('skar', $event)"><span class="icon-microphone buttonIcon"></span></a>
                </div>
              </div>
            </app-modal-column>
          </div>
          <div id="contentSungVersion" [ngClass]="(currentTab === 'ssun')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column class="size30" extraComponentClass="noScroll"
                              headerComponentTitle="iSongs"
                              headerComponentIcon="icon-music">
              <div>
                <img src="{{ item.image }}" style="width: 100%;">
                <p class="noMargin">&nbsp;</p>
                <p class="noMargin">{{ item.titulo }}</p>
                <p class="noMargin">{{ translations.hecha_famosa_por }} {{ item.canta }}</p>
              </div>
            </app-modal-column>
            <div class="modalContainer__mobileMenu mobile-up-hidden">
              <nav>
                <ul>
                  <li><a href="#" (click)="ChangeTab('home', $event)">{{ translations.inicio }}</a></li>
                  <li><a href="#" (click)="ChangeTab('actq', $event)">{{ translations.actividad_web }}</a></li>
                  <li><a href="#" (click)="ChangeTab('word', $event)">{{ translations.palabras_dificiles }}</a></li>
                  <li><a href="#" (click)="ChangeTab('bio', $event)">{{ translations.biografia }}</a></li>
                  <li><a href="#" (click)="ChangeTab('send', $event)">{{ translations.imprimir }}</a></li>
                </ul>
              </nav>
            </div>
            <app-modal-column class="size70" extraComponentClass="noScroll">
              <div class="verticalGrow">
                <p><strong>{{ translations.version_cantada }}</strong></p>
                <div>
                  <app-karaokeplayer *ngIf="currentTab === 'ssun'" [currentSong]="currentSungSong"></app-karaokeplayer>
                </div>
                <div class="selectSongIcons">
                  <a href="#" (click)="ChangeTab('ssun', $event)"><span class="icon-music buttonIcon"></span></a>
                  <a href="#" (click)="ChangeTab('skar', $event)"><span class="icon-microphone buttonIcon"></span></a>
                </div>
              </div>
            </app-modal-column>
          </div>
          <div id="contentKaraokeVersion" [ngClass]="(currentTab === 'skar')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column class="size30" extraComponentClass="noScroll"
                              headerComponentTitle="iSongs"
                              headerComponentIcon="icon-music">
              <div>
                <img src="{{ item.image }}" style="width: 100%;">
                <p class="noMargin">&nbsp;</p>
                <p class="noMargin">{{ item.titulo }}</p>
                <p class="noMargin">{{ translations.hecha_famosa_por }} {{ item.canta }}</p>
              </div>
            </app-modal-column>
            <div class="modalContainer__mobileMenu mobile-up-hidden">
              <nav>
                <ul>
                  <li><a href="#" (click)="ChangeTab('home', $event)">{{ translations.inicio }}</a></li>
                  <li><a href="#" (click)="ChangeTab('actq', $event)">{{ translations.actividad_web }}</a></li>
                  <li><a href="#" (click)="ChangeTab('word', $event)">{{ translations.palabras_dificiles }}</a></li>
                  <li><a href="#" (click)="ChangeTab('bio', $event)">{{ translations.biografia }}</a></li>
                  <li><a href="#" (click)="ChangeTab('send', $event)">{{ translations.imprimir }}</a></li>
                </ul>
              </nav>
            </div>
            <app-modal-column class="size70" extraComponentClass="noScroll">
              <div class="verticalGrow">
                <p><strong>{{ translations.version_karaoke }}</strong></p>
                <div>
                  <app-karaokeplayer *ngIf="currentTab === 'skar'"
                                     [currentSong]="currentKaraokeSong"></app-karaokeplayer>
                </div>
                <div class="selectSongIcons">
                  <a href="#" (click)="ChangeTab('ssun', $event)"><span class="icon-music buttonIcon"></span></a>
                  <a href="#" (click)="ChangeTab('skar', $event)"><span class="icon-microphone buttonIcon"></span></a>
                </div>
              </div>
            </app-modal-column>
          </div>
          <div id="contentActq" [ngClass]="(currentTab === 'actq')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column extraComponentClass="noScroll"
                              headerComponentTitle="iSongs"
                              headerComponentIcon="icon-music">
              <img src="{{ item.image }}" style="width: 100%;">
            </app-modal-column>
            <div class="modalContainer__mobileMenu mobile-up-hidden">
              <nav>
                <ul>
                  <li><a href="#" (click)="ChangeTab('home', $event)">{{ translations.inicio }}</a></li>
                  <li class="ActiveItem"><a href="#"
                                            (click)="ChangeTab('actq', $event)">{{ translations.actividad }}</a></li>
                  <li><a href="#" (click)="ChangeTab('word', $event)">{{ translations.palabras_dificiles }}</a></li>
                  <li><a href="#" (click)="ChangeTab('bio', $event)">{{ translations.biografia }}</a></li>
                  <li><a href="#" (click)="ChangeTab('send', $event)">{{ translations.imprimir }}</a></li>
                </ul>
              </nav>
            </div>
            <app-modal-column extraComponentClass="autoScroll">
              <div class="verticalGrow">
                <p><strong>{{ translations.actividad }}</strong></p>
                <div [innerHTML]="item.actividad"></div>
                <app-question-textarea placeHolder="{{ translations.puedes_teclear_aqui }}"
                                       [(text)]="answer.activity"
                                       textareaClass="activitiesTextarea bigTextarea"></app-question-textarea>
              </div>
            </app-modal-column>
          </div>
          <div id="contentBio" [ngClass]="(currentTab === 'bio')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column extraComponentClass="noScroll"
                              headerComponentTitle="iSongs"
                              headerComponentIcon="icon-music">
              <img src="{{ item.image }}" style="width: 100%;">
            </app-modal-column>
            <div class="modalContainer__mobileMenu mobile-up-hidden">
              <nav>
                <ul>
                  <li><a href="#" (click)="ChangeTab('home', $event)">{{ translations.inicio }}</a></li>
                  <li><a href="#" (click)="ChangeTab('actq', $event)">{{ translations.actividad }}</a></li>
                  <li><a href="#" (click)="ChangeTab('word', $event)">{{ translations.palabras_dificiles }}</a></li>
                  <li class="ActiveItem"><a href="#" (click)="ChangeTab('bio', $event)">{{ translations.biografia }}</a>
                  </li>
                  <li><a href="#" (click)="ChangeTab('send', $event)">{{ translations.imprimir }}</a></li>
                </ul>
              </nav>
            </div>
            <app-modal-column>
              <p><strong>{{ translations.biografia }}</strong></p>
              <div [innerHTML]="item.bio"></div>
            </app-modal-column>
          </div>
          <div id="contentWords" [ngClass]="(currentTab === 'word')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column extraComponentClass="noScroll mobile-hidden"
                              headerComponentTitle="iSongs"
                              headerComponentIcon="icon-music">
              <p><strong>{{ translations.letra_cancion }}</strong></p>
              <div [innerHTML]="item.subtitle"></div>
            </app-modal-column>
            <div class="leftSideAir mobile-up-hidden">
              <div>
                <img src="{{ item.image }}" style="width: 100%"/>
              </div>
            </div>
            <div class="modalContainer__mobileMenu mobile-up-hidden">
              <nav>
                <ul>
                  <li><a href="#" (click)="ChangeTab('home', $event)">{{ translations.inicio }}</a></li>
                  <li><a href="#" (click)="ChangeTab('actq', $event)">{{ translations.actividad }}</a></li>
                  <li class="ActiveItem"><a href="#"
                                            (click)="ChangeTab('word', $event)">{{ translations.palabras_dificiles }}</a>
                  </li>
                  <li><a href="#" (click)="ChangeTab('bio', $event)">{{ translations.biografia }}</a></li>
                  <li><a href="#" (click)="ChangeTab('send', $event)">{{ translations.imprimir }}</a></li>
                </ul>
              </nav>
            </div>
            <app-modal-column extraComponentClass="mobile-hidden">
              <div>
                <p><strong>{{ translations.algunas_expresiones_dificiles }}</strong></p>
                <div [innerHTML]="item.words"></div>
              </div>
            </app-modal-column>
            <div class="rightSideAir mobile-up-hidden">
              <div>
                <p><strong>{{ translations.letra_cancion }}</strong></p>
                <div [innerHTML]="item.subtitle"></div>
              </div>
            </div>
            <app-collapsible-element title="{{ translations.algunas_expresiones_dificiles }}"
                                     body="{{ item.words }}"></app-collapsible-element>
          </div>
          <div id="contentSend" [ngClass]="(currentTab === 'send')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column extraComponentClass="noScroll"
                              headerComponentTitle="iSongs"
                              headerComponentIcon="icon-music">
              <img src="{{ item.image }}" style="width: 100%;">
            </app-modal-column>
            <div class="modalContainer__mobileMenu mobile-up-hidden">
              <nav>
                <ul>
                  <li><a href="#" (click)="ChangeTab('home', $event)">{{ translations.inicio }}</a></li>
                  <li><a href="#" (click)="ChangeTab('actq', $event)">{{ translations.actividad }}</a></li>
                  <li><a href="#" (click)="ChangeTab('word', $event)">{{ translations.palabras_dificiles }}</a></li>
                  <li><a href="#" (click)="ChangeTab('bio', $event)">{{ translations.biografia }}</a></li>
                  <li class="ActiveItem"><a href="#" (click)="ChangeTab('send', $event)">{{ translations.imprimir }}</a>
                  </li>
                </ul>
              </nav>
            </div>
            <app-modal-column>
              <div *ngIf="currentPrint === '1'">
                <p><strong>{{ translations.imprimir }}</strong></p>
                <p class="textGrey14">{{ translations.imprimir_texto_actividades }}</p>
                <hr/>
                <p><strong>{{ translations.biografia }}</strong></p>
                <p (click)="ToogleReportCheck('reportCertificate')" class="cursorLink">
                  <span
                    [ngClass]="(reportCertificate === true) ? 'icon-checked-checkbox' : 'icon-unchecked-checkbox'"></span>
                  <span
                    style="margin-left: 10px;"
                    matTooltip="{{ translations.biografia }}"
                    matTooltipPosition="below"
                    matTooltipClass="tooltipElementBelow">{{ translations.biografia }}</span></p>
                <hr/>
                <p><strong>{{ translations.actividad }}</strong></p>
                <div class="contentTwoColumns">
                  <p (click)="ToogleReportCheck('reportActivityActivity')" class="cursorLink">
                    <span
                      [ngClass]="(reportActivityActivity === true) ? 'icon-checked-checkbox' : 'icon-unchecked-checkbox'"></span>
                    <span
                      style="margin-left: 10px;"
                      matTooltip="{{ translations.solo_actividad }}"
                      matTooltipPosition="below"
                      matTooltipClass="tooltipElementBelow">{{ translations.solo_actividad }}</span></p>
                  <p (click)="ToogleReportCheck('reportActivityAll')" class="cursorLink">
                    <span
                      [ngClass]="(reportActivityAll === true) ? 'icon-checked-checkbox' : 'icon-unchecked-checkbox'"></span>
                    <span
                      style="margin-left: 10px;"
                      matTooltip="{{ translations.actividad_respuesta }}"
                      matTooltipPosition="below"
                      matTooltipClass="tooltipElementBelow">{{ translations.actividad_respuesta }}</span></p>
                </div>
                <hr/>
                <p><strong>{{ translations.transcripcion }}</strong></p>
                <div class="contentTwoColumns">
                  <p (click)="ToogleReportCheck('reportTranscriptionText')" class="cursorLink">
                    <span
                      [ngClass]="(reportTranscriptionText === true) ? 'icon-checked-checkbox' : 'icon-unchecked-checkbox'"></span>
                    <span
                      style="margin-left: 10px;"
                      matTooltip="{{ translations.letra_cancion }}"
                      matTooltipPosition="below"
                      matTooltipClass="tooltipElementBelow">{{ translations.letra_cancion }}</span></p>
                  <p (click)="ToogleReportCheck('reportTranscriptionWords')" class="cursorLink">
                    <span
                      [ngClass]="(reportTranscriptionWords === true) ? 'icon-checked-checkbox' : 'icon-unchecked-checkbox'"></span>
                    <span
                      style="margin-left: 10px;"
                      matTooltip="{{ translations.palabras_dificiles }}"
                      matTooltipPosition="below"
                      matTooltipClass="tooltipElementBelow">{{ translations.palabras_dificiles }}</span></p>
                </div>
                <hr/>
                <button (click)="NextStepPrint()" type="button" class="buttonBlue">{{ translations.imprimir }}</button>
              </div>
              <div *ngIf="currentPrint === '2'">
                <p><strong>{{ translations.imprimir }}</strong></p>
                <p class="textGrey14">{{ translations.imprimir_texto_actividades }}</p>
                <hr/>
                <p><strong>{{ translations.your_name }}</strong></p>
                <input class="inputName" placeholder="{{ translations.name }}" [(ngModel)]="username"/>
                <p>&nbsp;</p>
                <button (click)="PrintReport()" type="button" class="buttonBlue">{{ translations.imprimir }}</button>
              </div>
            </app-modal-column>
          </div>
        </ng-template>        
        </div>
        <div class="modalContainer__sideMenu">
        <div>
          <nav>
            <ul>
              <li (click)="closeModal.emit()"
                  class="cursorLink" [ngClass]="(individualAspect === true)?'visibilityHidden':''"
              ><span class="icon-close"
                     matTooltip="{{ translations.cerrar }}"
                     matTooltipPosition="left"
                     matTooltipClass="tooltipElement"></span></li>
              <li [ngClass]="(currentTab === 'home')?'ActiveItem':''"
              ><a href="#" (click)="ChangeTab('home', $event)"><span class="icon-home"
                                                                     matTooltip="{{ translations.inicio }}"
                                                                     matTooltipPosition="left"
                                                                     matTooltipClass="tooltipElement"></span></a></li>
              <li [ngClass]="(currentTab === 'actq')?'ActiveItem':''"
              ><a href="#" (click)="ChangeTab('actq', $event)"><span class="icon-circle-tick"
                                                                     matTooltip="{{ translations.actividad }}"
                                                                     matTooltipPosition="left"
                                                                     matTooltipClass="tooltipElement"></span></a></li>
              <li [ngClass]="(currentTab === 'word')?'ActiveItem':''"
              ><a href="#" (click)="ChangeTab('word', $event)"><span class="icon-ticket"
                                                                     matTooltip="{{ translations.palabras_dificiles }}"
                                                                     matTooltipPosition="left"
                                                                     matTooltipClass="tooltipElement"></span></a></li>
              <li [ngClass]="(currentTab === 'bio')?'ActiveItem':''"
              ><a href="#" (click)="ChangeTab('bio', $event)"><span class="icon-star"
                                                                    matTooltip="{{ translations.biografia }}"
                                                                    matTooltipPosition="left"
                                                                    matTooltipClass="tooltipElement"></span></a></li>
              <li [ngClass]="(currentTab === 'send')?'ActiveItem':''"
              ><a href="#" (click)="ChangeTab('send', $event)"><span class="icon-upload"
                                                                     matTooltip="{{ translations.imprimir }}"
                                                                     matTooltipPosition="left"
                                                                     matTooltipClass="tooltipElement"></span></a></li>
              <li hidden><a href="#" (click)="ChangeTab('send', $event)"><span class="icon-star"
                                                                     matTooltip="Favorito"
                                                                     matTooltipPosition="left"
                                                                     matTooltipClass="tooltipElement"></span></a></li>
            </ul>
          </nav>
        </div>
        </div>
      
      </div>
      <div class="footer_sergio" > 
        <div style="width: 70%; height: 40px; float:left; background: #fff;"><img src="assets/images/logo-dark.png" class="logo_sergio" alt="logo"></div>
        <div style="width: 30%; height: 40px; float:right; background: #fff;">© 2023 Carnegie Learning, Inc.</div>
      </div>

   
  </div>
</div>


