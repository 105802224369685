import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Itemcard } from '../_models/itemcard';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs';
import {Common} from '../_helpers/common';

@Injectable({ providedIn: 'root' })
export class IpassportService {
  constructor(
    private http: HttpClient,
    private common: Common
  ) { }

  getCurrentItems(language: string, apthemeFilter: string, categoriesFilter: string, dateFilter: string): Observable<Itemcard[]> {
    const url = environment.api_url + '/api/iculture/ipassport/list';
    const params = this.common.paramsServicesURL(url, language, apthemeFilter, categoriesFilter, dateFilter);
    return this.http.get<Itemcard[]>(params);
  }

  getArchivedItems(language: string, apthemeFilter: string, categoriesFilter: string, dateFilter: string): Observable<Itemcard[]> {
    const url = environment.api_url + '/api/iculture/ipassport/archivedlist';
    const params = this.common.paramsServicesURL(url, language, apthemeFilter, categoriesFilter, dateFilter);
    return this.http.get<Itemcard[]>(params);
  }

  getAllItems(language: string, apthemeFilter: string, categoriesFilter: string, dateFilter: string): Observable<Itemcard[]> {
    const url = environment.api_url + '/api/iculture/ipassport/alllist';
    const params = this.common.paramsServicesURL(url, language, apthemeFilter, categoriesFilter, dateFilter);
    return this.http.get<Itemcard[]>(params);
  }

  getSearchItems(text: string, language: string, apthemeFilter: string, categoriesFilter: string, dateFilter: string): Observable<Itemcard[]> {
    const url = environment.api_url + '/api/iculture/ipassport/search/' + text;
    const params = this.common.paramsServicesURL(url, language, apthemeFilter, categoriesFilter, dateFilter);
    return this.http.get<Itemcard[]>(params);
  }

  getGlobalSearchItems(text: string, language: string, apthemeFilter: string, categoriesFilter: string, dateFilter: string): Observable<Itemcard[]> {
    const url = environment.api_url + '/api/iculture/ipassport/globalsearch/' + text;
    const params = this.common.paramsServicesURL(url, language, apthemeFilter, categoriesFilter, dateFilter);
    return this.http.get<Itemcard[]>(params);
  }
}
