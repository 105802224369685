import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Translations } from '../_models/translations';
import {environment} from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class TranslationsService {
  constructor(private http: HttpClient) { }

  getTranslations(force?: string): Translations {
    const lang = (force) ? force : localStorage.getItem('currentLanguage');
    if (!lang || lang === 'English') {
      return this._english();
    } else if (lang === 'Spanish') {
      return this._spanish();
    } else if (lang === 'French') {
      return this._french();
    } else if (lang === 'German') {
      return this._german();
    } else {
      return this._english();
    }
  }

  _spanish(): Translations {
    return {
      imprimir: 'Imprimir',
      palabras_dificiles: 'Palabras difíciles',
      texto_del_video: 'Texto del vídeo',
      transcripcion: 'Transcripción',
      actividad_respuesta: 'Actividad y respuesta',
      solo_actividad: 'Sólo la actividad',
      actividad_web: 'Actividad Web',
      avanzado: 'Avanzado',
      algo_personal: 'Algo personal',
      certificado: 'Certificado',
      que_recuerdas: '¿Qué recuerdas?',
      imprimir_texto_actividades: 'En esta sección puedes imprimir el texto de las actividades y/o tus respuestas en PDF. Selecciona lo que deseas incluir y haz click en el botón de imprimir.',
      algunas_expresiones_dificiles: 'Algunas palabras y expresiones difíciles',
      puedes_teclear_aqui: 'Escribe tus respuestas aquí.',
      responde_e_imprime_certificado: 'Responde las preguntas y luego imprime el certificado con los resultados haciendo clic en el botón de imprimir. Ojo, puedes intentar responder cuantas veces quieras cada pregunta, pero el certificado solo va a incluir tus respuestas del primer intento.',
      presta_atencion: 'Presta atención a todos los detalles y responde a las preguntas de la sección "¿Qué recuerdas?"',
      click_ver_video: 'Ahora haz clic en el botón <span class="icon-play"></span> para ver el video.',
      abrir_google_maps: 'Abrir en Google Maps',
      mapa: 'Mapa',
      buscando: 'Buscando...',
      sin_resultados: 'No se han encontrado resultados',
      explora_iculture: '¡Explora el mundo hispanohablante<br />y sus diversas culturas!',
      mas_reciente: 'Más reciente',
      archivo: 'Archivo',
      basico: 'Básico',
      hecha_famosa_por: 'Hecha famosa por:',
      ver_cancion_normal: 'Haz clic en <span class="icon-music"></span> para reproducir la canción en Versión cantada.',
      ver_cancion_karaoke: 'Si quieres la versión Karaoke, haz clic en <span class="icon-microphone"></span> abajo. ¡Diviértete!',
      letra_cancion: 'Letra de la canción',
      cargando: 'Cargando...',
      elige_idioma: 'Elige idioma',
      frances: 'Français',
      espanol: 'Español',
      aleman: 'Deutsch',
      tu_nombre: 'Tu nombre',
      busqueda_iculture: 'Búsqueda en iCulture',
      categorias: 'Categorías',
      ap_themes: 'AP Themes',
      busqueda: 'Búsqueda',
      cerrar: 'Cerrar',
      enviar: 'Enviar',
      num_articulos: ' Artículos',
      enero: 'Enero',
      febrero: 'Febrero',
      marzo: 'Marzo',
      abril: 'Abril',
      mayo: 'Mayo',
      junio: 'Junio',
      julio: 'Julio',
      agosto: 'Agosto',
      septiembre: 'Septiembre',
      octubre: 'Octubre',
      noviembre: 'Noviembre',
      diciembre: 'Diciembre',
      articulo: 'Artículo',
      actividad: 'Actividad',
      biografia: 'Biografía',
      falso: 'Falso',
      verdadero: 'Verdadero',
      escribe_tu_respuesta: 'Escribe tu respuesta aquí.',
      inicio: 'Inicio',
      version_cantada: 'Versión cantada',
      version_karaoke: 'Versión karaoke',
      haz_clic_en_el_boton: 'Haz clic en el botón <span class="icon-play"></span> para ver el vídeo de cada una de las tres preguntas personales.',
      preguntas: 'Preguntas',
      your_name: 'Tu nombre',
      name: 'Nombre',
      not_found: 'No se han encontrado resultados',
      type_your_password: 'Escribe tu contraseña',
      password: 'Contraseña',
      loginButton: 'Entrar',
      error_password: 'Contraseña incorrecta',
      filtro_ap_themes: 'Temas AP',
      filtro_categories: 'Categorías',
      filtro_date: 'Fecha',
      filtro_level: 'Nivel',
      filtros: 'Filtros',
      actividad_bloqueada: 'Para realizar la actividad, no debes ver la noticia.\n' +
        'Cuando hayas terminado la actividad, debes volver al índice principal.'
    };
  }

  _french(): Translations {
    return {
      imprimir: 'Imprimer',
      palabras_dificiles: 'Les mots difficiles',
      texto_del_video: 'Transcription',
      transcripcion: 'Transcription',
      actividad_respuesta: 'Activités et réponses',
      solo_actividad: 'Activités uniquement',
      actividad_web: 'Activité internet',
      avanzado: 'Avancé',
      algo_personal: 'Questions personnelles',
      certificado: 'Certificat',
      que_recuerdas: 'De quoi te rappelles-tu ?',
      imprimir_texto_actividades: 'Dans cette section, tu peux imprimer en format PDF les activités seules ou avec tes réponses. Sélectionne ce que tu souhaites inclure et clique sur le bouton « Imprimer ».',
      algunas_expresiones_dificiles: 'Les mots difficiles',
      puedes_teclear_aqui: 'commence à taper ici',
      responde_e_imprime_certificado: 'Réponds aux questions, puis imprime le certificat avec les résultats en cliquant sur « Imprimer ». Attention : tu peux répondre aux questions aussi souvent que tu le souhaites, mais uniquement ta première réponse apparaîtra sur le certificat.',
      presta_atencion: 'Prête attention à tous les détails, puis réponds aux questions dans la rubrique « De quoi te rappelles-tu ? »',
      click_ver_video: 'Clique sur <span class="icon-play"></span> pour démarrer la vidéo.',
      abrir_google_maps: 'Ouvrir dans Google Maps',
      mapa: 'Plan',
      buscando: 'Recherche…',
      sin_resultados: 'Aucun résultat trouvé',
      explora_iculture: 'Explorez le monde francophone<br />et ses diverses cultures!',
      mas_reciente: 'Plus récents',
      archivo: 'Archives',
      basico: 'Basique',
      hecha_famosa_por: 'Rendu célèbre par:',
      ver_cancion_normal: 'Clique sur <span class="icon-music"></span> pour écouter la chanson avec le chanteur.',
      ver_cancion_karaoke: 'Si tu veux la version Karaoké, clique sur <span class="icon-microphone"></span> ci-dessous. Amuse-toi bien!',
      letra_cancion: 'Paroles de la chanson',
      cargando: 'Chargement...',
      elige_idioma: 'Sélectionnez une langue',
      frances: 'Français',
      espanol: 'Español',
      aleman: 'Deutsch',
      tu_nombre: 'Votre nom',
      busqueda_iculture: 'rechercher dans iCulture',
      categorias: 'Catégorie',
      ap_themes: 'AP Themes',
      busqueda: 'Recherche',
      cerrar: 'Fermer',
      enviar: 'Envoyer',
      num_articulos: 'Articles',
      enero: 'janvier',
      febrero: 'février',
      marzo: 'Mars',
      abril: 'avril',
      mayo: 'Mai',
      junio: 'juin',
      julio: 'juillet',
      agosto: 'août',
      septiembre: 'septembre',
      octubre: 'Octobre',
      noviembre: 'novembre',
      diciembre: 'décembre',
      articulo: 'Article',
      actividad: 'Activité',
      biografia: 'Biographie',
      falso: 'Faux',
      verdadero: 'Vrai',
      escribe_tu_respuesta: 'Écrivez votre réponse ici.',
      inicio: 'Accueil',
      version_cantada: 'Chanson avec le chanteur',
      version_karaoke: 'Version Karaoké',
      haz_clic_en_el_boton: 'Sélectionne la question personnelle et clique sur le bouton  <span class="icon-play"></span> pour voir la vidéo.',
      preguntas: 'Questions',
      your_name: 'Votre nom',
      name: 'Nom',
      not_found: 'Aucun résultat trouvé',
      type_your_password: 'Type your password',
      password: 'Password',
      loginButton: 'Login',
      error_password: 'Invalid password',
      filtro_ap_themes: 'Thèmes AP',
      filtro_categories: 'Catégories',
      filtro_date: 'Date',
      filtro_level: 'Niveau',
      filtros: 'Filters de recherche',
      actividad_bloqueada: 'For this activity you are not allowed to see the text of the article.\n' +
        'When you are ready with the activity, you can go back to the main menu by clicking.'
    };
  }

  _german(): Translations {
    return {
      imprimir: 'Drucken',
      palabras_dificiles: 'Schwierige Wörter',
      texto_del_video: 'Transkript',
      transcripcion: 'Transkript',
      actividad_respuesta: 'Aktivitäten und Antworten',
      solo_actividad: 'Nur die Aktivitäten',
      actividad_web: 'Internet Aktivität',
      avanzado: 'Oberstufe',
      algo_personal: 'Fragen an dich!',
      certificado: 'Zeugnis',
      que_recuerdas: 'Was hast du dir gemerkt?',
      imprimir_texto_actividades: 'In diesem Abschnitt kannst du den Text der Aktivitäten mit oder ohne deine Antworten als PDF ausdrucken. Wähle aus, was du einschließen möchtest, und klicke auf die Schaltfläche „Drucken“.',
      algunas_expresiones_dificiles: 'Schwierige Wörter',
      puedes_teclear_aqui: 'Beginne hier zu tippen',
      responde_e_imprime_certificado: 'Beantworte die Fragen, dann drucke das Zeugnis mit dem Ergebnis aus. Klicke hierfür auf „Drucken“. Achtung: du kannst so oft antworten wie du willst. Aber auf dem Zertifikat wird nur deine erste Antwort berücksichtigt werden.',
      presta_atencion: 'Achte auf alle Details und antworte dann auf die Fragen in dem Bereich „Was hast du dir gemerkt?“',
      click_ver_video: 'Jetzt klicke auf <span class="icon-play"></span> um das Video zu starten',
      abrir_google_maps: 'Öffnen Sie in Google Maps',
      mapa: 'Karte',
      buscando: 'Suchen...',
      sin_resultados: 'keine Ergebnisse gefunden',
      explora_iculture: 'Erforscht die deutschsprachige Welt<br />und ihre verschiedenen Kulturen!',
      mas_reciente: 'Neueste Ausgaben',
      archivo: 'Archiv',
      basico: 'Mittelstufe',
      hecha_famosa_por: 'Berühmt gemacht durch:',
      ver_cancion_normal: 'Klicken Sie auf <span class="icon-music"></span>, um das Lied mit dem Sänger zu spielen.',
      ver_cancion_karaoke: 'Wenn Sie die Karaoke-Version möchten, klicken Sie unten auf <span class="icon-microphone"></span>. Viel Spaß!',
      letra_cancion: 'Texts des Liedes',
      cargando: 'Wird geladen...',
      elige_idioma: 'Wähle eine Sprache',
      frances: 'Français',
      espanol: 'Español',
      aleman: 'Deutsch',
      tu_nombre: 'Dein Name',
      busqueda_iculture: 'Suche in iCulture',
      categorias: 'Kategorie',
      ap_themes: 'AP Themes',
      busqueda: 'Suche',
      cerrar: 'schließen',
      enviar: 'Senden',
      num_articulos: 'Nachrichtenartikel',
      enero: 'Januar',
      febrero: 'Februar',
      marzo: 'März',
      abril: 'April',
      mayo: 'Mai',
      junio: 'Juni',
      julio: 'Juli',
      agosto: 'August',
      septiembre: 'September',
      octubre: 'Oktober',
      noviembre: 'November',
      diciembre: 'Dezember',
      articulo: 'Artikel',
      actividad: 'Aktivität',
      biografia: 'Biografie',
      falso: 'Falsch',
      verdadero: 'Wahr',
      escribe_tu_respuesta: 'Schreiben Sie hier Ihre Antwort.',
      inicio: 'Homepage',
      version_cantada: 'Gesungene Version',
      version_karaoke: 'Karaoke-Version',
      haz_clic_en_el_boton: 'Wähle die Frage aus und klicke auf <span class="icon-play"></span> um das Video zu sehen.',
      preguntas: 'Fragen',
      your_name: 'Dein Name',
      name: 'Name',
      not_found: 'Keine Ergebnisse gefunden',
      type_your_password: 'Type your password',
      password: 'Password',
      loginButton: 'Login',
      error_password: 'Invalid password',
      filtro_ap_themes: 'AP Themen',
      filtro_categories: 'Kategorien',
      filtro_date: 'Datum',
      filtro_level: 'Stufe',
      filtros: 'Filter',
      actividad_bloqueada: 'For this activity you are not allowed to see the text of the article.\n' +
        'When you are ready with the activity, you can go back to the main menu by clicking.'
    };
  }

  _english(): Translations {
    return {
      imprimir: 'Print',
      palabras_dificiles: 'Palabras difíciles',
      texto_del_video: 'Texto del vídeo',
      transcripcion: 'Transcripción',
      actividad_respuesta: 'Actividad y respuesta',
      solo_actividad: 'Sólo Actividad',
      actividad_web: 'Actividad Web',
      avanzado: 'Avanzado',
      algo_personal: 'Algo personal',
      certificado: 'Certificado',
      que_recuerdas: '¿Qué recuerdas?',
      imprimir_texto_actividades: 'In this page you can print out the text for the activities as well as your answers. You can also send your answers to your teacher directly from this page. The E-mail button will be active for you to send the answers once they are completed.',
      algunas_expresiones_dificiles: 'Algunas palabras y expresiones difíciles',
      puedes_teclear_aqui: 'Start typing here',
      responde_e_imprime_certificado: 'Responde las preguntas y luego imprime el certificado con los resultados haciendo clic en el botón de imprimir. Ojo, puedes intentar responder cuantas veces quieras cada pregunta, pero el certificado solo va a incluir tus respuestas del primer intento.',
      presta_atencion: 'Presta atención a todos los detalles y responde a las preguntas de la sección "¿Qué recuerdas?"',
      click_ver_video: 'Ahora haz clic en el botón <span class="icon-play"></span> para ver el video.',
      abrir_google_maps: 'Abrir en Google Maps',
      mapa: 'Mapa',
      buscando: 'Buscando...',
      sin_resultados: 'No se han encontrado resultados',
      explora_iculture: 'Explore culture<br />beyond the classroom',
      mas_reciente: 'Current School Year',
      archivo: 'Archive',
      basico: 'Básico',
      hecha_famosa_por: 'Hecha famosa por: ',
      ver_cancion_normal: 'Haz clic en <span class="icon-music"></span> para tocar la canción en Versión cantada.',
      ver_cancion_karaoke: 'Si quieres la versión Karaoke, haz clic en <span class="icon-microphone"></span> abajo. ¡Diviértete!',
      letra_cancion: 'Letra de la canción',
      cargando: 'Loading',
      elige_idioma: 'SELECT A LANGUAGE',
      frances: 'French',
      espanol: 'Spanish',
      aleman: 'German',
      tu_nombre: 'Name',
      busqueda_iculture: 'Search in iCulture.com',
      categorias: 'Categories',
      ap_themes: 'AP Themes',
      busqueda: 'Search',
      cerrar: 'Close',
      enviar: 'Send',
      num_articulos: 'Artículos',
      enero: 'Enero',
      febrero: 'Febrero',
      marzo: 'Marzo',
      abril: 'Abril',
      mayo: 'Mayo',
      junio: 'Junio',
      julio: 'Julio',
      agosto: 'Agosto',
      septiembre: 'Septiembre',
      octubre: 'Octubre',
      noviembre: 'Noviembre',
      diciembre: 'Diciembre',
      articulo: 'Article',
      actividad: 'Activity',
      biografia: 'Biography',
      falso: 'Falso',
      verdadero: 'Verdadero',
      escribe_tu_respuesta: 'Escribe tu respuesta aquí.',
      inicio: 'Home',
      version_cantada: 'Versión cantada',
      version_karaoke: 'Versión karaoke',
      haz_clic_en_el_boton: 'Haz clic en el botón <span class="icon-play"></span> para ver el vídeo de cada una de las tres preguntas personales.',
      preguntas: 'Questions',
      your_name: 'Your name',
      name: 'Name',
      not_found: 'No se han encontrado resultados',
      type_your_password: 'Type your password',
      password: 'Password',
      loginButton: 'Login',
      error_password: 'Invalid password',
      filtro_ap_themes: 'AP Themes',
      filtro_categories: 'Categories',
      filtro_date: 'Date',
      filtro_level: 'Level',
      filtros: 'Filters',
      actividad_bloqueada: 'For this activity you are not allowed to see the text of the article.\n' +
        'When you are ready with the activity, you can go back to the main menu by clicking.'
    };
  }
}
