<div class="monthCard cursorLink" (click)="openItem.emit()">
  <div class="containerImage" style="background: url('{{ img }}') no-repeat center center;"></div>
  <div class="containerText">
    <div class="card__iconSection"  style="color:{{ color }};" [ngClass]="icon === '' && name === '' ? 'hiddenItem' : ''">
      <span class="{{ icon }}"></span> {{name}}
    </div>
    <p class="card__category">{{ category.toUpperCase() }}</p>
    <p class="card__levelDate" [ngClass]="level === '' || date === '' ? 'hiddenItem' : ''">{{ level }} | {{ date }}</p>
    <p class="card__levelDate" [ngClass]="level !== '' && date === '' ? '' : 'hiddenItem'">{{ level }}</p>
    <p class="card__title" style="color:{{ color }};">{{ title }}</p>
    <div class="container__cardText" [ngClass]="text === '' ? 'hiddenItem' : ''">
      <p class="card__text">{{ text }}</p>
    </div>
  </div>
</div>
