import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UUID} from 'uuid-generator-ts';
import {Common} from '../_helpers/common';

@Component({
  selector: 'app-question-textarea',
  templateUrl: './question-textarea.component.html',
  styleUrls: ['./question-textarea.component.scss']
})
export class QuestionTextareaComponent implements OnInit {
  uuid = '';
  currentLanguage = 'Spanish';

  @Input() placeHolder = '';
  @Input() text = '';
  @Input() extraClass = '';
  @Input() textareaClass = '';

  @Output() textChange = new EventEmitter();

  onChange(val) {
    this.textChange.emit(val);
  }

  constructor(
    private common: Common
  ) { }

  ngOnInit(): void {
    const uuid = new UUID();
    this.uuid = uuid.getDashFreeUUID();
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }

  inschar(myValue): void {
    const eleDom: HTMLTextAreaElement = document.getElementById('text-' + this.uuid) as HTMLTextAreaElement;
    this.common.inschar(eleDom, myValue);
  }
}
