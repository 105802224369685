import {AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {navigationoption} from '../_models/navigationoption';
import {UUID} from 'uuid-generator-ts';
import {Common} from '../_helpers/common';

@Component({
  selector: 'app-navigation-bar',
  templateUrl: './navigation-bar.component.html',
  styleUrls: ['./navigation-bar.component.scss']
})
export class NavigationBarComponent implements OnInit, AfterViewInit, OnChanges {
  uuid = '';

  @Input() initialLabel: string;
  @Input() extraComponentClass: string = '';
  @Input() elementList: navigationoption[];
  @Input() itemSelected: string;
  @Input() selectedColor: string;
  @Input() selectedBackgroundColor: string;
  @Output() changeOptionSelected = new EventEmitter();

  constructor(
    private common: Common
  ) {}

  ngOnInit(): void {
    const uuid = new UUID();
    this.uuid = uuid.getDashFreeUUID();

    if(this.selectedBackgroundColor && this.selectedBackgroundColor !== '') {
      this.common.changeThemeVar('--navigation-background', this.selectedBackgroundColor);
    }
    if(this.selectedColor && this.selectedColor !== '') {
      this.common.changeThemeVar('--navigation-text-selected', this.selectedColor);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    for (const propName in changes) {
      if (changes.hasOwnProperty(propName)) {
        switch (propName) {
          case 'itemSelected': {
            this.paintColoredLabels();
          }
        }
      }
    }
  }

  ngAfterViewInit(): void {
    this.paintColoredLabels();
  }

  clearItemsLabels(): void {
    const listElements = document.querySelectorAll('ul#list-' + this.uuid + ' > li');
    listElements.forEach(function(item) {
      item.classList.remove('ActiveItem');
      item.classList.remove('firstActiveItem');
      item.classList.remove('middleActiveItem');
      item.classList.remove('lastActiveItem');
    });
  }
  paintColoredLabels(): void {
    this.clearItemsLabels();
    if(this.itemSelected && this.itemSelected !== '') {
      let found = false;
      let number = 0;
      const listElements = document.querySelectorAll('ul#list-' + this.uuid + ' > li');
      listElements.forEach((item: HTMLElement) => {
        if(found === true) return;

        const active = (this.itemSelected === item.dataset.elementid);
        const label = item.classList.contains('InitialLabel');

        if(label === true) {
          item.classList.add('ActiveItem');
          item.classList.add('firstActiveItem');
        } else {
          if(active !== true) {
            item.classList.add('ActiveItem');
            if(number > 0) {
              item.classList.add('middleActiveItem');
            } else {
              item.classList.add('firstActiveItem');
            }
          } else {
            item.classList.add('ActiveItem');
            if(number > 0) {
              item.classList.add('lastActiveItem');
            }
            found = true;
          }
        }

        ++number;
      });
    }
  }

  onChangeOption(id: string, link: string): void {
    if(link && link !== '') {
      let win = window.open(link, '_blank');
      win.focus();
    } else {
      this.changeOptionSelected.emit(id);
    }
  }
}
