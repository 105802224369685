<h1>{{ sectionName }} Index</h1>
<p>&nbsp;</p>
<div class="elementCard bucle" *ngFor="let item of currentItems">
  <app-archive-card
    id="{{ item.id }}"
    title="{{ item.title }}"
    date="{{ item.date }}"
    text="{{ item.text }}"
    (clickElement)="handleItemClick($event)"
  ></app-archive-card>
</div>
