<div class="itemPopup">
  <div class="overlay"></div>
  <div class="modalContainer">
    <div class="modalContainer__header scroll">
      <div>
        <p class="monthLabel">{{ monthLabel }}</p>
      </div>
      <div class="header__rightCorner">
        <p>
          <span (click)="changeListType()"
                [ngClass]="{
                  active: currentListType() === '1',
                  hiddenItem: levelFilter === 'Advanced'
                }"
                class="cursorLink">
            {{ translations.basico.toUpperCase() }}
          </span>
          <span (click)="changeListType()"
                class="icon-sort cursorLink"
                [ngClass]="levelFilter === '' ? '' : 'hiddenItem'"></span>
          <span (click)="changeListType()"
                [ngClass]="{
                  active: currentListType() === '2',
                  hiddenItem: levelFilter === 'Basic'
                }"
                class="cursorLink">
            {{ translations.avanzado.toUpperCase() }}
          </span>
        </p>
        <nav>
          <ul>
            <li (click)="closeModal.emit()" class="cursorLink"><span class="icon-close"></span></li>
          </ul>
        </nav>
      </div>
    </div>
    <div class="modalContainer__mainContent" style="background: url('/angular/bg-main-content.png') repeat-x top left;">
      <app-loading *ngIf="!item;else other_content"></app-loading>
      <ng-template #other_content>
        <div class="elementCard bucle" *ngFor="let inew of item" [ngClass]="inew.tipo === currentListType() ? '' : 'hiddenItem'">
          <app-element-month-card
            [ngClass]="inew.tipo === currentListType() ? '' : 'hiddenItem'"
            color="{{color}}"
            month="{{month}}"
            year="{{year}}"
            title="{{inew.titulo}}"
            img="{{inew.image}}"
            text="{{CutText(inew.subtitulo)}}"
            category="{{inew.category}}"
            level="{{ inew.tipo === '1' ? translations.basico.toUpperCase() : translations.avanzado.toUpperCase() }}"
            date="{{ inew.fod | date: 'mediumDate' }}"
            (openItem)="openItem.emit( inew.id )"
          ></app-element-month-card>
        </div>
      </ng-template>
    </div>
    <div class="footer_sergio" > 
      <div style="width: 70%; height: 40px; float:left; background: #fff;"><img src="assets/images/logo-dark.png" class="logo_sergio" alt="logo"></div>
      <div style="width: 30%; height: 40px; float:right; background: #fff;">© 2023 Carnegie Learning, Inc.</div>
    </div>
  </div>
</div>
