import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Ipassportitemsubtitles } from '../_models/ipassportitemsubtitles';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IpassportitemsubtitlesService {
  constructor(private http: HttpClient) { }

  getItem(id: number): Observable<Ipassportitemsubtitles[]> {
    return this.http.get<Ipassportitemsubtitles[]>(`${environment.api_url}/api/iculture/ipassport/getsubtitles/${id.toString()}`);
  }
}
