<!-- <footer>
  <div class="leftSide">
    <div class="logo">
      <img src="assets/images/footer/emc-logo.png"
           alt="EMC"
           srcset="assets/images/footer/emc-logo.png 1x, assets/images/footer/emc-logo@2x.png 2x">
      <img src="assets/images/footer/publishing.png"
           alt="Publishing"
           srcset="assets/images/footer/publishing.png 1x, assets/images/footer/publishing@2x.png 2x">
    </div>
    <div>
      <p>© 2020 EMC School. All Rights Reserved</p>
      <p class="footer__txtMini">501 Grant Street • Union Trust Building • Suite 1075 • Pittsburgh, PA 15219 • 888-851-7094</p>
    </div>
  </div>
  <div class="rightSide">
    <div>
      <nav>
        <ul>
          <li><a href="https://support.emcschool.com/" target="_blank">Tech Support</a></li>
          <li><a href="https://discover.carnegielearning.com/contact-EMC-sales.html" target="_blank">Sales</a></li>
          <li><a href="https://www.emcschool.com/terms/terms.php" target="_blank">Terms and Conditions</a></li>
        </ul>
      </nav>
    </div>
    <div class="socialNetworks">
      <img src="assets/images/footer/twitter.png"
           alt="Twitter"
           srcset="assets/images/footer/twitter.png 1x, assets/images/footer/twitter@2x.png 2x">
      <img src="assets/images/footer/facebook.png"
           alt="Facebook"
           srcset="assets/images/footer/facebook.png 1x, assets/images/footer/facebook@2x.png 2x">
      <img src="assets/images/footer/vimeo.png"
           alt="Vimeo"
           srcset="assets/images/footer/vimeo.png 1x, assets/images/footer/vimeo@2x.png 2x">
    </div>
  </div>
</footer>
-->
<footer *ngIf="router.url === '/'">
  <div class="container">
    <div class="row">
      <div class="col-xl-5 col-xl-offset-1 col-md-6">
        <p class="h1 body-font">Connect With Us</p>

        <!-- Social List -->
        <ul class="list-inline social">
          <li>
            <a href="https://www.facebook.com/CarnegieLearning" rel="me" target="_blank"><i class="sr-only">Facebook</i><i
              class="icon-facebook"></i></a>
          </li>
          <li>
            <a href="https://twitter.com/carnegielearn" rel="me" target="_blank"><i class="sr-only">Twitter</i><i
              class="icon-twitter"></i></a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/carnegie-learning" rel="me" target="_blank"><i class="sr-only">LinkedIn</i><i
              class="icon-linkein"></i></a>
          </li>
          <li>
            <a href="https://www.pinterest.com/carnegielearning/" rel="me" target="_blank"><i
              class="sr-only">Pinterest</i><i class="icon-pinterest"></i></a>
          </li>
          <li>
            <a href="https://www.instagram.com/carnegielearning/" rel="me" target="_blank"><i
              class="sr-only">Instagram</i><i class="icon-instagram"></i></a>
          </li>
        </ul>

        <ul class="list-inline">
          <li><a href="https://www.carnegielearning.com/privacy-policy/" target="_blank">Privacy Policy</a></li>
          <li><a href="https://www.carnegielearning.com/terms-of-use/" target="_blank">Terms of Use</a></li>
          <li><a href="https://www.carnegielearning.com/copyright-information/" target="_blank">Copyright</a></li>
        </ul>
        <div class="divide40 visible-md"></div>
      </div>
      <div class="col-xl-5 col-md-6">
        <p class="h1 body-font">Carnegie Learning, Inc.</p>
        <div class="row">
          <div class="col-sm-6">
            <ul class="tall-list">
              <li>
                <span itemprop="streetAddress">501 Grant Street</span>
              </li>
              <li>
                <span itemprop="streetAddress">Union Trust Building</span>
              </li>
              <li>
                <span itemprop="streetAddress">Suite 1075</span>
              </li>
              <li>
                <span itemprop="streetAddress">Pittsburgh, PA 15219</span>
              </li>
            </ul>
          </div>
          <div class="col-sm-6">
            <ul class="tall-list">
              <li>P: <span class="tel" itemprop="telephone">888.851.7094</span></li>

              <li><a class="text-blue" href="https://www.carnegielearning.com/contact/" target="_blank">Get in
                touch.</a></li>

            </ul>
          </div>
        </div>
        <div class="divide25"></div>
        <p>
          © 2014-2020 Carnegie Learning, Inc.
        </p>
      </div>
    </div>
  </div>
</footer>
