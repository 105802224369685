<div class="itemPopup">
  <div class="overlay" [ngClass]="(individualAspect === true)?'opaqueOverlay':''"></div>
  <div class="modalContainer">
    <div class="modalContainer__wrapper">
      <div class="modalContainer__main">
        <app-loading *ngIf="!item;else other_content"></app-loading>
        <ng-template #other_content>
          <div id="contentHome" [ngClass]="(currentTab === 'home')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column extraComponentClass="noScroll"
                              headerComponentTitle="iNews"
                              headerComponentIcon="icon-wifi">
              <div style="height: 300px; overflow: hidden"><img src="{{ item.image }}" style="width: 100%;"></div>
              <mat-basic-audio-player
                *ngIf="currentTab === 'home'"
                [audioUrl]="item.sound_mp3"
                [autoPlay]="false"
                displayVolumeControls="true"
                muted="muted"
              ></mat-basic-audio-player>
            </app-modal-column>
            <app-modal-column
              extraComponentClass="{{ individualAspect === true ? 'autoScroll hideRelatedLink' : 'autoScroll' }}"
              [relatedItemiNews]=!deactivateRelatedItem
              relatedItemiNewsTitle="{{ item.tipo === '1' ? translations.basico.toUpperCase() : translations.avanzado.toUpperCase() }}"
              (clickRelatedItem)="onChangeRelated()">
              <p>
                <strong>{{ item.tipo === '1' ? translations.basico.toUpperCase() : translations.avanzado.toUpperCase() }}
                  | {{ item.fod | date: 'mediumDate' }}</strong></p>
              <div class="title">{{ item.titulo }}</div>
              <p><strong>{{ item.subtitulo }}</strong></p>
              <br>
              <div class="text">
                <p [innerHTML]="item.body"></p>
              </div>
            </app-modal-column>
          </div>
          <div id="contentActq" [ngClass]="(currentTab === 'actq')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column extraComponentClass="noScroll positionRelative"
                              headerComponentTitle="iNews"
                              headerComponentIcon="icon-wifi">
              <div>
                <div style="height: 300px; overflow: hidden"><img src="{{ item.image }}" style="width: 100%;"></div>
                <mat-basic-audio-player
                  *ngIf="currentTab === 'actq'"
                  [audioUrl]="item.sound_mp3"
                  [autoPlay]="false"
                  displayVolumeControls="true"
                  muted="muted"
                ></mat-basic-audio-player>
              </div>
              <div class="videoOverlay"
                   *ngIf=" (currentQuest === '1' && (item.tipoa === '3' || item.tipoa === '4'))
                   || (currentQuest === '2' && (item.tipob === '3' || item.tipob === '4'))"
              ></div>
            </app-modal-column>
            <app-modal-column extraComponentClass="autoScroll">
              <div class="title">{{ translations.actividad }}</div>
              <div id="Question01"
                   [ngClass]="(currentQuest === '1')?'':'hiddenItem'">
                <div class="verticalGrow"
                     *ngIf="item.forma === '1' || item.forma === '2'">
                  <app-question-textarea
                    [(text)]="answer.activity_1"
                  ></app-question-textarea>
                </div>
                <app-question-poll-boolean
                  *ngIf="item.forma === '3'"
                  [questions]="GenerateQuestionsPollBoolean(1)"
                  language="{{currentLanguage}}"
                  (onAnswerChange)="ChangeAnswerPoll(1, $event)"
                ></app-question-poll-boolean>
                <app-question-poll
                  *ngIf="item.forma === '4'"
                  [questions]="GenerateQuestionsPoll(1)"
                  language="{{currentLanguage}}"
                  (onAnswerChange)="ChangeAnswerPoll(1, $event)"
                ></app-question-poll>
              </div>
              <div id="Question02"
                   [ngClass]="(currentQuest === '2')?'':'hiddenItem'">
                <div class="verticalGrow"
                     *ngIf="item.formb === '1' || item.formb === '2'">
                  <app-question-textarea
                    [(text)]="answer.activity_2"
                  ></app-question-textarea>
                </div>
                <app-question-poll-boolean
                  *ngIf="item.formb === '3'"
                  [questions]="GenerateQuestionsPollBoolean(2)"
                  language="{{currentLanguage}}"
                  (onAnswerChange)="ChangeAnswerPoll(2, $event)"
                ></app-question-poll-boolean>
                <app-question-poll
                  *ngIf="item.formb === '4'"
                  [questions]="GenerateQuestionsPoll(2)"
                  language="{{currentLanguage}}"
                  (onAnswerChange)="ChangeAnswerPoll(2, $event)"
                ></app-question-poll>
              </div>
              <app-navigation-bar itemSelected="{{ currentQuest }}"
                                  initialLabel="{{ translations.actividad }}"
                                  (changeOptionSelected)="ChangeQuestion($event)"
                                  [elementList]="activitiesNavigationMenu"></app-navigation-bar>
            </app-modal-column>
          </div>
          <div id="contentSend" [ngClass]="(currentTab === 'send')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column extraComponentClass="noScroll"
                              headerComponentTitle="iNews"
                              headerComponentIcon="icon-wifi">
              <div style="height: 300px; overflow: hidden"><img src="{{ item.image }}" style="width: 100%;"></div>
              <mat-basic-audio-player
                *ngIf="currentTab === 'send'"
                [audioUrl]="item.sound_mp3"
                [autoPlay]="false"
                displayVolumeControls="true"
                muted="muted"
              ></mat-basic-audio-player>
            </app-modal-column>
            <app-modal-column>
              <div *ngIf="currentPrint === '1'">
                <p><strong>{{ translations.imprimir }}</strong></p>
                <p class="textGrey14">{{ translations.imprimir_texto_actividades }}</p>
                <hr/>
                <p><strong>{{ translations.articulo }}</strong></p>
                <p (click)="ToogleReportCheck('reportCertificate')" class="cursorLink">
                  <span
                    [ngClass]="(reportCertificate === true) ? 'icon-checked-checkbox' : 'icon-unchecked-checkbox'"></span>
                  <span
                    style="margin-left: 10px;"
                    matTooltip="{{ translations.articulo }}"
                    matTooltipPosition="below"
                    matTooltipClass="tooltipElementBelow">{{ translations.articulo }}</span></p>
                <hr/>
                <p><strong>{{ translations.actividad_web }}</strong></p>
                <div class="contentTwoColumns">
                  <p (click)="ToogleReportCheck('reportActivityActivity')" class="cursorLink">
                    <span
                      [ngClass]="(reportActivityActivity === true) ? 'icon-checked-checkbox' : 'icon-unchecked-checkbox'"></span>
                    <span
                      style="margin-left: 10px;"
                      matTooltip="{{ translations.solo_actividad }}"
                      matTooltipPosition="below"
                      matTooltipClass="tooltipElementBelow">{{ translations.solo_actividad }}</span></p>
                  <p (click)="ToogleReportCheck('reportActivityAll')" class="cursorLink">
                    <span
                      [ngClass]="(reportActivityAll === true) ? 'icon-checked-checkbox' : 'icon-unchecked-checkbox'"></span>
                    <span
                      style="margin-left: 10px;"
                      matTooltip="{{ translations.actividad_respuesta }}"
                      matTooltipPosition="below"
                      matTooltipClass="tooltipElementBelow">{{ translations.actividad_respuesta }}</span></p>
                </div>
                <hr/>
                <button (click)="NextStepPrint()" type="button" class="buttonBlue">{{ translations.imprimir }}</button>
              </div>
              <div *ngIf="currentPrint === '2'">
                <p><strong>{{ translations.imprimir }}</strong></p>
                <p class="textGrey14">{{ translations.imprimir_texto_actividades }}</p>
                <hr/>
                <p><strong>{{ translations.your_name }}</strong></p>
                <input class="inputName" placeholder="{{ translations.name }}" [(ngModel)]="username"/>
                <p>&nbsp;</p>
                <button (click)="PrintReport()" type="button" class="buttonBlue">{{ translations.imprimir }}</button>
              </div>
            </app-modal-column>
          </div>
        </ng-template>
      </div>
      <div class="modalContainer__sideMenu">
        <div>
          <nav>
            <ul>
              <li (click)="closeModal.emit()"
                  class="cursorLink" [ngClass]="(individualAspect === true)?'visibilityHidden':''"
              ><span class="icon-close"
                     matTooltip="{{ translations.cerrar }}"
                     matTooltipPosition="left"
                     matTooltipClass="tooltipElement"></span></li>
              <li [ngClass]="{
                    ActiveItem: currentTab === 'home',
                    DissableItem: (currentTab === 'actq') && (currentQuest === '1' && (item.tipoa === '3' || item.tipoa === '4'))
                   || (currentQuest === '2' && (item.tipob === '3' || item.tipob === '4'))
                  }"
                  id="HomeIcon"
              ><a href="#"
                  (click)="ChangeTab('home', $event)"><span
                class="icon-home"
                [matTooltip]="homeIconTooltip()"
                matTooltipPosition="left"
                matTooltipClass="tooltipElement"></span></a></li>
              <li [ngClass]="(currentTab === 'actq')?'ActiveItem':''"
              ><a href="#"
                  (click)="ChangeTab('actq', $event)"><span
                class="icon-circle-tick"
                matTooltip="{{ translations.actividad }}"
                matTooltipPosition="left"
                matTooltipClass="tooltipElement"></span></a></li>
              <li [ngClass]="(currentTab === 'send')?'ActiveItem':''"
              ><a href="#"
                  (click)="ChangeTab('send', $event)"><span
                class="icon-upload"
                matTooltip="{{ translations.imprimir }}"
                matTooltipPosition="left"
                matTooltipClass="tooltipElement"></span></a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="footer_sergio" > 
      <div style="width: 70%; height: 40px; float:left; background: #fff;"><img src="assets/images/logo-dark.png" class="logo_sergio" alt="logo"></div>
      <div style="width: 30%; height: 40px; float:right; background: #fff;">© 2023 Carnegie Learning, Inc.</div>
    </div>
  </div>
</div>
