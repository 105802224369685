import {Component, OnInit, Output, EventEmitter, Input} from '@angular/core';

import { Ipassportitem } from '../_models/ipassportitem';
import {Translations} from '../_models/translations';
import {TranslationsService} from '../_services/translations.service';
import {navigationoption} from '../_models/navigationoption';
import {Common} from '../_helpers/common';
import {Ipassportanswer} from '../_models/ipassportanswer';

@Component({
  selector: 'app-ipassportitem',
  templateUrl: './ipassportitem.component.html',
  styleUrls: ['./ipassportitem.component.scss']
})
export class IpassportitemComponent implements OnInit {
  translations: Translations;
  currentTab = 'home';
  currentMap = '1';
  currentQuest = '1';
  currentPrint = '1';
  question1 = '';
  question2 = '';
  question3 = '';
  question4 = '';
  videoCurrentTime = '';
  answer: Ipassportanswer;
  reportCertificate = false;
  reportActivityActivity = false;
  reportActivityAll = false;
  reportTranscriptionText = false;
  reportTranscriptionWords = false;

  activitiesNavigationMenu: navigationoption[] = [];
  mapNavigationMenu: navigationoption[] = [];

  individualAspect = false;

  username = '';

  @Input() item: Ipassportitem;
  @Input() itemsubtitles;
  @Output() closeModal = new EventEmitter();

  constructor(
    private translationsService: TranslationsService,
    private common: Common
  ) {
    this.translations = translationsService.getTranslations();
    this.answer = new class implements Ipassportanswer {
      activity: string;
      q1: string;
      q2: string;
      q3: string;
      q4: string;
    }
    this.answer.activity = '';
    this.answer.q1 = '';
    this.answer.q2 = '';
    this.answer.q3 = '';
    this.answer.q4 = '';

    // Check if we have a direct link to this element:
    const currentUrl = window.location.href;
    if (currentUrl.indexOf('lang') !== -1) {
      this.individualAspect = true;
    }
  }

  ngOnInit(): void {
    for(let i = 1 ; i <= 4 ; ++i) {
      this.activitiesNavigationMenu.push({
        id: i.toString(),
        name: i.toString(),
        extraClass: '',
        link: ''
      });
    }

    for(let i = 1 ; i <= 3 ; ++i) {
      this.mapNavigationMenu.push({
        id: i.toString(),
        name: i.toString(),
        extraClass: '',
        link: ''
      });
    }

    this.mapNavigationMenu.push({
      id: 'maps',
      name: this.translations.abrir_google_maps,
      extraClass: 'extraLeftSpace',
      link: this.item.q6
    });

    this.common.changeThemeVar('--item-popup-color', '#C7161E');
    this.common.changeThemeVar('--text-selected', '#fff');
  }

  ChangeTab(newTab, event, mobile?:boolean): void {
    event.preventDefault();
    if (newTab !== this.currentTab) {
      this.currentTab = newTab;
    }

    if(mobile && mobile === true) {
      // Move scroll on mobile menu:
      setTimeout(() => {
        const container = document.getElementById('MobileMenu');
        // const rowToScrollTo = document.getElementById(newTab);

        const highlightedItems = document.querySelectorAll('#' + newTab);

        let offset = 0;
        highlightedItems.forEach((item: HTMLElement) => {
          if(item.getBoundingClientRect().left > 0) {
            offset = item.getBoundingClientRect().left;
          } else if(item.offsetLeft > 0) {
            offset = item.offsetLeft;
          }
        });
        const containers = document.querySelectorAll('#MobileMenu');
        containers.forEach((item2: HTMLElement) => {
          item2.scrollLeft = offset;
        });
      }, 400);
    }
  }

  ChangeMap(newTab: string): void {
    if (newTab !== this.currentMap) {
      this.currentMap = newTab;
    }
  }

  ChangeQuestion(newTab: string): void {
    if (newTab !== this.currentQuest) {
      this.currentQuest = newTab;
    }
  }

  ToogleReportCheck(variable: string): void {
    this[variable] = !this[variable];
  }

  FixUserAnswer(question, answer): void {
    const questionName = 'q' + question;
    const answerText = 'q' + question + answer;
    this.answer[questionName] = (this.answer[questionName] === '') ? answerText : this.answer[questionName];
  }

  ChangeAnswer(question, answer, event): void {
    event.preventDefault();
    this.FixUserAnswer(question, answer);
    if (question === '1') {
      this.question1 = answer;
      if (answer === this.item.q1x) {
        this.videoCurrentTime = this.item.q1f;
      } else {
        this.videoCurrentTime = '';
      }
    } else if (question === '2') {
      this.question2 = answer;
      if (answer === this.item.q2x) {
        this.videoCurrentTime = this.item.q2f;
      } else {
        this.videoCurrentTime = '';
      }
    } else if (question === '3') {
      this.question3 = answer;
      if (answer === this.item.q3x) {
        this.videoCurrentTime = this.item.q3f;
      } else {
        this.videoCurrentTime = '';
      }
    } else if (question === '4') {
      this.question4 = answer;
      if (answer === this.item.q4x) {
        this.videoCurrentTime = this.item.q4f;
      } else {
        this.videoCurrentTime = '';
      }
    }
  }

  NextStepPrint(): void {
    this.currentPrint = '2';
  }

  PrintReport(): void {
    const printArea = document.getElementById('contentToPrint');

    if(!printArea) return;

    printArea.innerHTML = '';

    // Título:
    let content = '<p><img src="assets/images/logo-dark@3x.png" alt="iCulture" style="width: 140px; margin-bottom: 50px"></p>';
    content += '<p><strong>iCulture Carnegie Learning</strong></p>';
    content += '<p>Ipassport - ' + this.item.titulo + '</p>';
    content += '<p>&nbsp;</p>';

    content += '<p>User: ' + this.username + '</p>';
    content += '<p>&nbsp;</p>';

    if (this.reportCertificate === true) {
      content += '<p><strong>' + this.translations.que_recuerdas + '</strong></p>';

      // --- que recuerdas - - - 01
      content += '<p><strong>' + this.translations.preguntas + ' - 1</strong></p>';
      content += '<div>' + this.item.q1 + '</div>';
      content += '<p><strong>Your answer: </strong></p>';
      content += this.common.answerToText(this.item[this.answer.q1]);

      // --- que recuerdas - - - 02
      content += '<p><strong>' + this.translations.preguntas + ' - 2</strong></p>';
      content += '<div>' + this.item.q2 + '</div>';
      content += '<p><strong>Your answer: </strong></p>';
      content += this.common.answerToText(this.item[this.answer.q2]);

      // --- que recuerdas - - - 03
      content += '<p><strong>' + this.translations.preguntas + ' - 3</strong></p>';
      content += '<div>' + this.item.q3 + '</div>';
      content += '<p><strong>Your answer: </strong></p>';
      content += this.common.answerToText(this.item[this.answer.q3]);

      // --- que recuerdas - - - 04
      content += '<p><strong>' + this.translations.preguntas + ' - 4</strong></p>';
      content += '<div>' + this.item.q4 + '</div>';
      content += '<p><strong>Your answer: </strong></p>';
      content += this.common.answerToText(this.item[this.answer.q4]);
    }

    // Pregunta Actividad Web
    if (this.reportActivityActivity === true || this.reportActivityAll === true) {
      content += '<p><strong>' + this.translations.actividad_web + '</strong></p>';
      content += '<div>' + this.item.q7 + '</div>';
      content += '<p>&nbsp;</p>';
    }

    // Respuesta Actividad Web
    if (this.reportActivityAll === true) {
      content += '<p><strong>Your answer</strong></p>';
      content += this.common.answerToText(this.answer.activity, 'text');
      content += '<p>&nbsp;</p>';
    }

    // Transcripción
    if (this.reportTranscriptionText === true) {
      content += '<p><strong>' + this.translations.transcripcion + '</strong></p>';
      content += '<div>' + this.item.subtitle + '</div>';
      content += '<p>&nbsp;</p>';
    }

    // Palabras difíciles
    if (this.reportTranscriptionWords === true) {
      content += '<p><strong>' + this.translations.palabras_dificiles + '</strong></p>';
      content += '<div>' + this.item.words + '</div>';
      content += '<p>&nbsp;</p>';
    }

    printArea.innerHTML = content;
    window.print();
  }
}
