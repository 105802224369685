import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Isongitemsubtitles } from '../_models/isongitemsubtitles';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IsongitemsubtitlesService {
  constructor(private http: HttpClient) { }

  getItem(id: number): Observable<Isongitemsubtitles[]> {
    return this.http.get<Isongitemsubtitles[]>(`${environment.api_url}/api/iculture/isong/getsubtitles/${id.toString()}`);
  }
}
