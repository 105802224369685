import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Common} from '../_helpers/common';

@Component({
  selector: 'app-date-year-month-select',
  templateUrl: './date-year-month-select.component.html',
  styleUrls: ['./date-year-month-select.component.scss']
})
export class DateYearMonthSelectComponent implements OnInit {
  currentText = '';
  inputValue: Date;
  removeSelectedIcon = false;

  @Input() name = '';
  @Input() monthSelectorType = 'normal';
  @Input() extraClass = '';
  @Output() changeOption = new EventEmitter();

  constructor(
    private common: Common
  ) { }

  ngOnInit(): void {
    this.currentText = this.name;
  }

  ChangeDatepicker(): void {
    this.inputValue.setDate(1);
    this.emitNewDate(this.inputValue);
  }

  CloseCurrentOption(): void {
    this.currentText = this.name;
    this.removeSelectedIcon = false;
    this.changeOption.emit('');
  }

  chosenMonthHandler(normalizedMonth: Date, datepicker: any): void {
    this.inputValue = normalizedMonth;
    this.emitNewDate(this.inputValue);
    datepicker.close();
  }

  emitNewDate(value: Date): void {
    const newDate = this.common.formatDateISO(value);
    this.currentText = newDate;
    this.removeSelectedIcon = true;
    this.changeOption.emit(newDate);
  }
}
