<div class="itemPopup">
  <div class="overlay" [ngClass]="(individualAspect === true)?'opaqueOverlay':''"></div>
  <div class="modalContainer">
    <div class="modalContainer__header mobile-up-hidden">
      <div class="header__title">
        <span class="icon-ipassport"></span> iPassport
      </div>
      <div>
        <span class="icon-close cursorLink"
              (click)="closeModal.emit()"></span>
      </div>
    </div>
    <div class="modalContainer__wrapper">
      <div class="modalContainer__main">
        <app-loading *ngIf="!itemsubtitles || !item;else other_content"></app-loading>
        <ng-template #other_content>
          <div id="contentHome" [ngClass]="(currentTab === 'home')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column extraComponentClass="noScroll mobileFullWidth"
                              headerComponentTitle="iPassport"
                              headerComponentIcon="icon-ipassport">
              <app-videoplayer
                *ngIf="currentTab === 'home'"
                posterimage="{{ item.image }}"
                videourlM4v="{{ item.video_m4v }}"
                videourlOgv="{{ item.video_ogv }}"
                videourlMp4="{{ item.video_mp4 }}"
                videourlWebm="{{ item.video_webm }}"
                subtitles="{{ itemsubtitles }}"></app-videoplayer>
            </app-modal-column>
            <div id="MobileMenu" class="modalContainer__mobileMenu mobile-up-hidden">
              <nav>
                <ul>
                  <li id="home" class="ActiveItem"><a href="#"
                                                      (click)="ChangeTab('home', $event, true)">{{ translations.inicio }}</a>
                  </li>
                  <li id="maps"><a href="#" (click)="ChangeTab('maps', $event, true)">{{ translations.mapa }}</a></li>
                  <li id="actq"><a href="#"
                                   (click)="ChangeTab('actq', $event, true)">{{ translations.que_recuerdas }}</a></li>
                  <li id="actw"><a href="#"
                                   (click)="ChangeTab('actw', $event, true)">{{ translations.actividad_web }}</a></li>
                  <li id="word"><a href="#"
                                   (click)="ChangeTab('word', $event, true)">{{ translations.transcripcion }}</a></li>
                  <li id="send"><a href="#" (click)="ChangeTab('send', $event, true)">{{ translations.imprimir }}</a>
                  </li>
                </ul>
              </nav>
            </div>
            <app-modal-column extraComponentClass="autoScroll">
              <div class="title">{{ item.titulo }}</div>
              <br>
              <div class="text">
                <p>{{ item.intro }}</p>
                <p>{{ translations.presta_atencion }} <span class="icon-circle-tick"></span></p>
                <p [innerHTML]="translations.click_ver_video"></p>
              </div>
            </app-modal-column>
          </div>
          <div id="contentMaps" [ngClass]="(currentTab === 'maps')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column extraComponentClass="noScroll noFloats mobileFullWidth">
              <div id="Map01"
                   [ngClass]="(currentMap === '1')?'':'hiddenItem'"
                   class="mapBackground mobile-hidden"
                   style="background-image: url({{ item.mapa01 }})">
                <span class="icon-right-arrow cursorLink"
                      (click)="ChangeMap('2')"></span>
              </div>
              <div [ngClass]="(currentMap === '1')?'':'hiddenItem'"
                   class="mapBackground mapSlideMobile mobile-up-hidden">
                <img src="{{ item.mapa01 }}"/>
                <span class="icon-right-arrow cursorLink"
                      (click)="ChangeMap('2')"></span>
                <p class="openMapMobile"><a href="{{ item.q6 }}"
                                            target="_blank">{{ translations.abrir_google_maps }}</a></p>
                <span class="mobileMapMenu map01 Active cursorLink" (click)="ChangeMap('1')"></span>
                <span class="mobileMapMenu map02 cursorLink" (click)="ChangeMap('2')"></span>
                <span class="mobileMapMenu map03 cursorLink" (click)="ChangeMap('3')"></span>
              </div>
              <div id="Map02"
                   [ngClass]="(currentMap === '2')?'':'hiddenItem'"
                   class="mapBackground mobile-hidden"
                   style="background-image: url({{ item.mapa02 }})">
                <span class="icon-left-arrow cursorLink"
                      (click)="ChangeMap('1')"></span>
                <span class="icon-right-arrow cursorLink"
                      (click)="ChangeMap('3')"></span>
              </div>
              <div [ngClass]="(currentMap === '2')?'':'hiddenItem'"
                   class="mapBackground mapSlideMobile mobile-up-hidden">
                <img src="{{ item.mapa02 }}"/>
                <span class="icon-left-arrow cursorLink"
                      (click)="ChangeMap('1')"></span>
                <span class="icon-right-arrow cursorLink"
                      (click)="ChangeMap('3')"></span>
                <p class="openMapMobile"><a href="{{ item.q6 }}"
                                            target="_blank">{{ translations.abrir_google_maps }}</a></p>
                <span class="mobileMapMenu map01 cursorLink" (click)="ChangeMap('1')"></span>
                <span class="mobileMapMenu map02 Active cursorLink" (click)="ChangeMap('2')"></span>
                <span class="mobileMapMenu map03 cursorLink" (click)="ChangeMap('3')"></span>
              </div>
              <div id="Map03"
                   [ngClass]="(currentMap === '3')?'':'hiddenItem'"
                   class="mapBackground mobile-hidden"
                   style="background-image: url({{ item.mapa03 }})">
                <span class="icon-left-arrow cursorLink"
                      (click)="ChangeMap('2')"></span>
              </div>
              <div [ngClass]="(currentMap === '3')?'':'hiddenItem'"
                   class="mapBackground mapSlideMobile mobile-up-hidden">
                <img src="{{ item.mapa03 }}"/>
                <span class="icon-left-arrow cursorLink"
                      (click)="ChangeMap('2')"></span>
                <p class="openMapMobile"><a href="{{ item.q6 }}"
                                            target="_blank">{{ translations.abrir_google_maps }}</a></p>
                <span class="mobileMapMenu map01 cursorLink" (click)="ChangeMap('1')"></span>
                <span class="mobileMapMenu map02 cursorLink" (click)="ChangeMap('2')"></span>
                <span class="mobileMapMenu map03 Active cursorLink" (click)="ChangeMap('3')"></span>
              </div>
              <app-navigation-bar itemSelected="{{ currentMap }}"
                                  extraComponentClass="mobile-hidden"
                                  initialLabel=""
                                  (changeOptionSelected)="ChangeMap($event)"
                                  [elementList]="mapNavigationMenu"></app-navigation-bar>
            </app-modal-column>
            <div id="MobileMenu" class="modalContainer__mobileMenu mobile-up-hidden">
              <nav>
                <ul>
                  <li id="home"><a href="#" (click)="ChangeTab('home', $event, true)">{{ translations.inicio }}</a></li>
                  <li id="maps" class="ActiveItem"><a href="#"
                                                      (click)="ChangeTab('maps', $event, true)">{{ translations.mapa }}</a>
                  </li>
                  <li id="actq"><a href="#"
                                   (click)="ChangeTab('actq', $event, true)">{{ translations.que_recuerdas }}</a></li>
                  <li id="actw"><a href="#"
                                   (click)="ChangeTab('actw', $event, true)">{{ translations.actividad_web }}</a></li>
                  <li id="word"><a href="#"
                                   (click)="ChangeTab('word', $event, true)">{{ translations.transcripcion }}</a></li>
                  <li id="send"><a href="#" (click)="ChangeTab('send', $event, true)">{{ translations.imprimir }}</a>
                  </li>
                </ul>
              </nav>
            </div>
            <app-modal-column>
              <p class="noMargin"><strong>{{ translations.mapa }}</strong></p>
              <div class="title">{{ item.titulo }}</div>
              <br>
              <div class="text" [innerHTML]="item.q5"></div>
            </app-modal-column>
          </div>
          <div id="contentActq" [ngClass]="(currentTab === 'actq')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column extraComponentClass="noScroll mobileFullWidth"
                              headerComponentTitle="iPassport"
                              headerComponentIcon="icon-ipassport">
              <app-videoplayer
                *ngIf="currentTab === 'actq'"
                posterimage="{{ item.image }}"
                videourlM4v="{{ item.video_m4v }}"
                videourlOgv="{{ item.video_ogv }}"
                videourlMp4="{{ item.video_mp4 }}"
                videourlWebm="{{ item.video_webm }}"
                subtitles="{{ itemsubtitles }}"
                startTime="{{ videoCurrentTime }}"></app-videoplayer>
            </app-modal-column>
            <div id="MobileMenu" class="modalContainer__mobileMenu mobile-up-hidden">
              <nav>
                <ul>
                  <li id="home"><a href="#" (click)="ChangeTab('home', $event, true)">{{ translations.inicio }}</a></li>
                  <li id="maps"><a href="#" (click)="ChangeTab('maps', $event, true)">{{ translations.mapa }}</a></li>
                  <li id="actq" class="ActiveItem"><a href="#"
                                                      (click)="ChangeTab('actq', $event, true)">{{ translations.que_recuerdas }}</a>
                  </li>
                  <li id="actw"><a href="#"
                                   (click)="ChangeTab('actw', $event, true)">{{ translations.actividad_web }}</a></li>
                  <li id="word"><a href="#"
                                   (click)="ChangeTab('word', $event, true)">{{ translations.transcripcion }}</a></li>
                  <li id="send"><a href="#" (click)="ChangeTab('send', $event, true)">{{ translations.imprimir }}</a>
                  </li>
                </ul>
              </nav>
            </div>
            <app-modal-column extraComponentClass="autoScroll mobile-hidden">
              <p><strong>{{ translations.que_recuerdas }}</strong></p>
              <div>
                <div id="Question01"
                     [ngClass]="(currentQuest === '1')?'':'hiddenItem'">
                  <p>{{ translations.responde_e_imprime_certificado }}</p>
                  <hr>
                  <p><strong>{{ item.q1 }}</strong></p>
                  <p>A. <a href="#"
                           (click)="ChangeAnswer('1', 'a', $event)"
                           [ngClass]="{
                           'incorrectQuestion': question1 === 'a' && item.q1x !== 'a',
                           'correctQuestion': question1 === 'a' && item.q1x === 'a',
                           'inheritColor': question1 !== 'a'
                           }">
                    <span [ngClass]="(question1 === 'a')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                    {{ item.q1a }}</a></p>
                  <p>B. <a href="#"
                           (click)="ChangeAnswer('1', 'b', $event)"
                           [ngClass]="{
                           'incorrectQuestion': question1 === 'b' && item.q1x !== 'b',
                           'correctQuestion': question1 === 'b' && item.q1x === 'b',
                           'inheritColor': question1 !== 'b'
                           }">
                    <span [ngClass]="(question1 === 'b')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                    {{ item.q1b }}</a></p>
                  <p>C. <a href="#"
                           (click)="ChangeAnswer('1', 'c', $event)"
                           [ngClass]="{
                           'incorrectQuestion': question1 === 'c' && item.q1x !== 'c',
                           'correctQuestion': question1 === 'c' && item.q1x === 'c',
                           'inheritColor': question1 !== 'c'
                           }">
                    <span [ngClass]="(question1 === 'c')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                    {{ item.q1c }}</a></p>
                  <div *ngIf="question1 !== ''">
                    <div *ngIf="question1 === 'a'">
                      <p>{{ item.fq1a }}</p>
                    </div>
                    <div *ngIf="question1 === 'b'">
                      <p>{{ item.fq1b }}</p>
                    </div>
                    <div *ngIf="question1 === 'c'">
                      <p>{{ item.fq1c }}</p>
                    </div>
                  </div>
                </div>
                <div id="Question02"
                     [ngClass]="(currentQuest === '2')?'':'hiddenItem'">
                  <hr>
                  <p><strong>{{ item.q2 }}</strong></p>
                  <p>A. <a href="#"
                           (click)="ChangeAnswer('2', 'a', $event)"
                           [ngClass]="{
                           'incorrectQuestion': question2 === 'a' && item.q2x !== 'a',
                           'correctQuestion': question2 === 'a' && item.q2x === 'a',
                           'inheritColor': question2 !== 'a'
                           }">
                    <span [ngClass]="(question2 === 'a')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                    {{ item.q2a }}</a></p>
                  <p>B. <a href="#"
                           (click)="ChangeAnswer('2', 'b', $event)"
                           [ngClass]="{
                           'incorrectQuestion': question2 === 'b' && item.q2x !== 'b',
                           'correctQuestion': question2 === 'b' && item.q2x === 'b',
                           'inheritColor': question2 !== 'b'
                           }">
                    <span [ngClass]="(question2 === 'b')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                    {{ item.q2b }}</a></p>
                  <p>C. <a href="#"
                           (click)="ChangeAnswer('2', 'c', $event)"
                           [ngClass]="{
                           'incorrectQuestion': question2 === 'c' && item.q2x !== 'c',
                           'correctQuestion': question2 === 'c' && item.q2x === 'c',
                           'inheritColor': question2 !== 'c'
                           }">
                    <span [ngClass]="(question2 === 'c')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                    {{ item.q2c }}</a></p>
                  <div *ngIf="question2 !== ''">
                    <div *ngIf="question2 === 'a'">
                      <p>{{ item.fq2a }}</p>
                    </div>
                    <div *ngIf="question2 === 'b'">
                      <p>{{ item.fq2b }}</p>
                    </div>
                    <div *ngIf="question2 === 'c'">
                      <p>{{ item.fq2c }}</p>
                    </div>
                  </div>
                </div>
                <div id="Question03"
                     [ngClass]="(currentQuest === '3')?'':'hiddenItem'">
                  <hr>
                  <p><strong>{{ item.q3 }}</strong></p>
                  <p>A. <a href="#"
                           (click)="ChangeAnswer('3', 'a', $event)"
                           [ngClass]="{
                           'incorrectQuestion': question3 === 'a' && item.q3x !== 'a',
                           'correctQuestion': question3 === 'a' && item.q3x === 'a',
                           'inheritColor': question3 !== 'a'
                           }">
                    <span [ngClass]="(question3 === 'a')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                    {{ item.q3a }}</a></p>
                  <p>B. <a href="#"
                           (click)="ChangeAnswer('3', 'b', $event)"
                           [ngClass]="{
                           'incorrectQuestion': question3 === 'b' && item.q3x !== 'b',
                           'correctQuestion': question3 === 'b' && item.q3x === 'b',
                           'inheritColor': question3 !== 'b'
                           }">
                    <span [ngClass]="(question3 === 'b')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                    {{ item.q3b }}</a></p>
                  <p>C. <a href="#"
                           (click)="ChangeAnswer('3', 'c', $event)"
                           [ngClass]="{
                           'incorrectQuestion': question3 === 'c' && item.q3x !== 'c',
                           'correctQuestion': question3 === 'c' && item.q3x === 'c',
                           'inheritColor': question3 !== 'c'
                           }">
                    <span [ngClass]="(question3 === 'c')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                    {{ item.q3c }}</a></p>
                  <div *ngIf="question3 !== ''">
                    <div *ngIf="question3 === 'a'">
                      <p>{{ item.fq3a }}</p>
                    </div>
                    <div *ngIf="question3 === 'b'">
                      <p>{{ item.fq3b }}</p>
                    </div>
                    <div *ngIf="question3 === 'c'">
                      <p>{{ item.fq3c }}</p>
                    </div>
                  </div>
                </div>
                <div id="Question04"
                     [ngClass]="(currentQuest === '4')?'':'hiddenItem'">
                  <hr>
                  <p><strong>{{ item.q4 }}</strong></p>
                  <p>A. <a href="#"
                           (click)="ChangeAnswer('4', 'a', $event)"
                           [ngClass]="{
                           'incorrectQuestion': question4 === 'a' && item.q4x !== 'a',
                           'correctQuestion': question4 === 'a' && item.q4x === 'a',
                           'inheritColor': question4 !== 'a'
                           }">
                    <span [ngClass]="(question4 === 'a')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                    {{ item.q4a }}</a></p>
                  <p>B. <a href="#"
                           (click)="ChangeAnswer('4', 'b', $event)"
                           [ngClass]="{
                           'incorrectQuestion': question4 === 'b' && item.q4x !== 'b',
                           'correctQuestion': question4 === 'b' && item.q4x === 'b',
                           'inheritColor': question4 !== 'b'
                           }">
                    <span [ngClass]="(question4 === 'b')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                    {{ item.q4b }}</a></p>
                  <p>C. <a href="#"
                           (click)="ChangeAnswer('4', 'c', $event)"
                           [ngClass]="{
                           'incorrectQuestion': question4 === 'c' && item.q4x !== 'c',
                           'correctQuestion': question4 === 'c' && item.q4x === 'c',
                           'inheritColor': question4 !== 'c'
                           }">
                    <span [ngClass]="(question4 === 'c')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                    {{ item.q4c }}</a></p>
                  <div *ngIf="question4 !== ''">
                    <div *ngIf="question4 === 'a'">
                      <p>{{ item.fq4a }}</p>
                    </div>
                    <div *ngIf="question4 === 'b'">
                      <p>{{ item.fq4b }}</p>
                    </div>
                    <div *ngIf="question4 === 'c'">
                      <p>{{ item.fq4c }}</p>
                    </div>
                  </div>
                </div>
              </div>
              <app-navigation-bar itemSelected="{{ currentQuest }}"
                                  initialLabel="{{ translations.preguntas }}"
                                  (changeOptionSelected)="ChangeQuestion($event)"
                                  [elementList]="activitiesNavigationMenu"></app-navigation-bar>
            </app-modal-column>
            <div class="rightSideAir mobile-up-hidden">
              <div>
                <p><strong>{{ translations.que_recuerdas }}</strong></p>
                <p>{{ translations.responde_e_imprime_certificado }}</p>
              </div>
            </div>
            <app-collapsible-element title="{{ translations.actividad }}">
              <div id="Question01"
                   [ngClass]="(currentQuest === '1')?'':'hiddenItem'">
                <p><strong>{{ item.q1 }}</strong></p>
                <p>A. <a href="#" (click)="ChangeAnswer('1', 'a', $event)" class="inheritColor">
                  <span [ngClass]="(question1 === 'a')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                  {{ item.q1a }}</a></p>
                <p>B. <a href="#" (click)="ChangeAnswer('1', 'b', $event)" class="inheritColor">
                  <span [ngClass]="(question1 === 'b')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                  {{ item.q1b }}</a></p>
                <p>C. <a href="#" (click)="ChangeAnswer('1', 'c', $event)" class="inheritColor">
                  <span [ngClass]="(question1 === 'c')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                  {{ item.q1c }}</a></p>
                <div *ngIf="question1 !== ''">
                  <div *ngIf="question1 === 'a'">
                    <p>{{ item.fq1a }}</p>
                  </div>
                  <div *ngIf="question1 === 'b'">
                    <p>{{ item.fq1b }}</p>
                  </div>
                  <div *ngIf="question1 === 'c'">
                    <p>{{ item.fq1c }}</p>
                  </div>
                </div>
                <app-navigation-bar itemSelected="{{ currentQuest }}"
                                    initialLabel="{{ translations.preguntas }}"
                                    (changeOptionSelected)="ChangeQuestion($event)"
                                    [elementList]="activitiesNavigationMenu"></app-navigation-bar>
              </div>
              <div id="Question02"
                   [ngClass]="(currentQuest === '2')?'':'hiddenItem'">
                <p><strong>{{ item.q2 }}</strong></p>
                <p>A. <a href="#" (click)="ChangeAnswer('2', 'a', $event)" class="inheritColor">
                  <span [ngClass]="(question2 === 'a')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                  {{ item.q2a }}</a></p>
                <p>B. <a href="#" (click)="ChangeAnswer('2', 'b', $event)" class="inheritColor">
                  <span [ngClass]="(question2 === 'b')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                  {{ item.q2b }}</a></p>
                <p>C. <a href="#" (click)="ChangeAnswer('2', 'c', $event)" class="inheritColor">
                  <span [ngClass]="(question2 === 'c')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                  {{ item.q2c }}</a></p>
                <div *ngIf="question2 !== ''">
                  <div *ngIf="question2 === 'a'">
                    <p>{{ item.fq2a }}</p>
                  </div>
                  <div *ngIf="question2 === 'b'">
                    <p>{{ item.fq2b }}</p>
                  </div>
                  <div *ngIf="question2 === 'c'">
                    <p>{{ item.fq2c }}</p>
                  </div>
                </div>
                <app-navigation-bar itemSelected="{{ currentQuest }}"
                                    initialLabel="{{ translations.preguntas }}"
                                    (changeOptionSelected)="ChangeQuestion($event)"
                                    [elementList]="activitiesNavigationMenu"></app-navigation-bar>
              </div>
              <div id="Question03"
                   [ngClass]="(currentQuest === '3')?'':'hiddenItem'">
                <p><strong>{{ item.q3 }}</strong></p>
                <p>A. <a href="#" (click)="ChangeAnswer('3', 'a', $event)" class="inheritColor">
                  <span [ngClass]="(question3 === 'a')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                  {{ item.q3a }}</a></p>
                <p>B. <a href="#" (click)="ChangeAnswer('3', 'b', $event)" class="inheritColor">
                  <span [ngClass]="(question3 === 'b')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                  {{ item.q3b }}</a></p>
                <p>C. <a href="#" (click)="ChangeAnswer('3', 'c', $event)" class="inheritColor">
                  <span [ngClass]="(question3 === 'c')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                  {{ item.q3c }}</a></p>
                <div *ngIf="question3 !== ''">
                  <div *ngIf="question3 === 'a'">
                    <p>{{ item.fq3a }}</p>
                  </div>
                  <div *ngIf="question3 === 'b'">
                    <p>{{ item.fq3b }}</p>
                  </div>
                  <div *ngIf="question3 === 'c'">
                    <p>{{ item.fq3c }}</p>
                  </div>
                </div>
                <app-navigation-bar itemSelected="{{ currentQuest }}"
                                    initialLabel="{{ translations.preguntas }}"
                                    (changeOptionSelected)="ChangeQuestion($event)"
                                    [elementList]="activitiesNavigationMenu"></app-navigation-bar>
              </div>
              <div id="Question04"
                   [ngClass]="(currentQuest === '4')?'':'hiddenItem'">
                <p><strong>{{ item.q4 }}</strong></p>
                <p>A. <a href="#" (click)="ChangeAnswer('4', 'a', $event)" class="inheritColor">
                  <span [ngClass]="(question4 === 'a')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                  {{ item.q4a }}</a></p>
                <p>B. <a href="#" (click)="ChangeAnswer('4', 'b', $event)" class="inheritColor">
                  <span [ngClass]="(question4 === 'b')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                  {{ item.q4b }}</a></p>
                <p>C. <a href="#" (click)="ChangeAnswer('4', 'c', $event)" class="inheritColor">
                  <span [ngClass]="(question4 === 'c')?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
                  {{ item.q4c }}</a></p>
                <div *ngIf="question4 !== ''">
                  <div *ngIf="question4 === 'a'">
                    <p>{{ item.fq4a }}</p>
                  </div>
                  <div *ngIf="question4 === 'b'">
                    <p>{{ item.fq4b }}</p>
                  </div>
                  <div *ngIf="question4 === 'c'">
                    <p>{{ item.fq4c }}</p>
                  </div>
                </div>
                <app-navigation-bar itemSelected="{{ currentQuest }}"
                                    initialLabel="{{ translations.preguntas }}"
                                    (changeOptionSelected)="ChangeQuestion($event)"
                                    [elementList]="activitiesNavigationMenu"></app-navigation-bar>
              </div>
            </app-collapsible-element>
          </div>
          <div id="contentActw" [ngClass]="(currentTab === 'actw')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column extraComponentClass="mobile-hidden"
                              headerComponentTitle="iPassport"
                              headerComponentIcon="icon-ipassport">
              <div>
                <p><strong>{{ translations.actividad_web }}</strong></p>
              </div>
              <div [innerHTML]="item.q7"></div>
            </app-modal-column>
            <div class="leftSideAir mobile-up-hidden">
              <div>
                <img src="{{ item.image }}" style="width: 100%"/>
              </div>
            </div>
            <div id="MobileMenu" class="modalContainer__mobileMenu mobile-up-hidden">
              <nav>
                <ul>
                  <li id="home"><a href="#" (click)="ChangeTab('home', $event, true)">{{ translations.inicio }}</a></li>
                  <li id="maps"><a href="#" (click)="ChangeTab('maps', $event, true)">{{ translations.mapa }}</a></li>
                  <li id="actq"><a href="#"
                                   (click)="ChangeTab('actq', $event, true)">{{ translations.que_recuerdas }}</a></li>
                  <li id="actw" class="ActiveItem"><a href="#"
                                                      (click)="ChangeTab('actw', $event, true)">{{ translations.actividad_web }}</a>
                  </li>
                  <li id="word"><a href="#"
                                   (click)="ChangeTab('word', $event, true)">{{ translations.transcripcion }}</a></li>
                  <li id="send"><a href="#" (click)="ChangeTab('send', $event, true)">{{ translations.imprimir }}</a>
                  </li>
                </ul>
              </nav>
            </div>
            <app-modal-column extraComponentClass="noScroll onlyTextareaContent mobile-hidden">
              <app-question-textarea placeHolder="{{ translations.puedes_teclear_aqui }}"
                                     [(text)]="answer.activity"
                                     textareaClass="activitiesTextarea"></app-question-textarea>
            </app-modal-column>
            <div class="rightSideAir mobile-up-hidden">
              <div>
                <p><strong>{{ translations.actividad_web }}</strong></p>
                <div [innerHTML]="item.q7"></div>
              </div>
            </div>
            <app-collapsible-element-textarea
              title="{{ translations.actividad_web }}"
              textareaPlaceholder="{{ translations.puedes_teclear_aqui }}"></app-collapsible-element-textarea>
          </div>
          <div id="contentWords" [ngClass]="(currentTab === 'word')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column extraComponentClass="mobile-hidden"
                              headerComponentTitle="iPassport"
                              headerComponentIcon="icon-ipassport">
              <p><strong>{{ translations.transcripcion }}</strong></p>
              <div [innerHTML]="item.subtitle"></div>
            </app-modal-column>
            <div class="leftSideAir mobile-up-hidden">
              <div>
                <img src="{{ item.image }}" style="width: 100%"/>
              </div>
            </div>
            <div id="MobileMenu" class="modalContainer__mobileMenu mobile-up-hidden">
              <nav>
                <ul>
                  <li id="home"><a href="#" (click)="ChangeTab('home', $event, true)">{{ translations.inicio }}</a></li>
                  <li id="maps"><a href="#" (click)="ChangeTab('maps', $event, true)">{{ translations.mapa }}</a></li>
                  <li id="actq"><a href="#"
                                   (click)="ChangeTab('actq', $event, true)">{{ translations.que_recuerdas }}</a></li>
                  <li id="actw"><a href="#"
                                   (click)="ChangeTab('actw', $event, true)">{{ translations.actividad_web }}</a></li>
                  <li id="word" class="ActiveItem"><a href="#"
                                                      (click)="ChangeTab('word', $event, true)">{{ translations.transcripcion }}</a>
                  </li>
                  <li id="send"><a href="#" (click)="ChangeTab('send', $event, true)">{{ translations.imprimir }}</a>
                  </li>
                </ul>
              </nav>
            </div>
            <app-modal-column extraComponentClass="mobile-hidden">
              <div>
                <p><strong>{{ translations.algunas_expresiones_dificiles }}</strong></p>
                <div [innerHTML]="item.words"></div>
              </div>
            </app-modal-column>
            <div class="rightSideAir mobile-up-hidden">
              <div>
                <p><strong>{{ translations.transcripcion }}</strong></p>
                <div [innerHTML]="item.subtitle"></div>
              </div>
            </div>
            <app-collapsible-element title="{{ translations.algunas_expresiones_dificiles }}"
                                     body="{{ item.words }}"></app-collapsible-element>
          </div>
          <div id="contentSend" [ngClass]="(currentTab === 'send')?'':'hiddenItem'" class="doubleColumn">
            <app-modal-column extraComponentClass="noScroll mobileFullWidth"
                              headerComponentTitle="iPassport"
                              headerComponentIcon="icon-ipassport">
              <app-videoplayer
                *ngIf="currentTab === 'send'"
                posterimage="{{ item.image }}"
                videourlM4v="{{ item.video_m4v }}"
                videourlOgv="{{ item.video_ogv }}"
                videourlMp4="{{ item.video_mp4 }}"
                videourlWebm="{{ item.video_webm }}"
                subtitles="{{ itemsubtitles }}"></app-videoplayer>
            </app-modal-column>
            <div id="MobileMenu" class="modalContainer__mobileMenu mobile-up-hidden">
              <nav>
                <ul>
                  <li id="home"><a href="#" (click)="ChangeTab('home', $event, true)">{{ translations.inicio }}</a></li>
                  <li id="maps"><a href="#" (click)="ChangeTab('maps', $event, true)">{{ translations.mapa }}</a></li>
                  <li id="actq"><a href="#"
                                   (click)="ChangeTab('actq', $event, true)">{{ translations.que_recuerdas }}</a></li>
                  <li id="actw"><a href="#"
                                   (click)="ChangeTab('actw', $event, true)">{{ translations.actividad_web }}</a></li>
                  <li id="word"><a href="#"
                                   (click)="ChangeTab('word', $event, true)">{{ translations.transcripcion }}</a></li>
                  <li id="send" class="ActiveItem"><a href="#"
                                                      (click)="ChangeTab('send', $event, true)">{{ translations.imprimir }}</a>
                  </li>
                </ul>
              </nav>
            </div>
            <app-modal-column>
              <div *ngIf="currentPrint === '1'">
                <p><strong>{{ translations.imprimir }}</strong></p>
                <p class="textGrey14">{{ translations.imprimir_texto_actividades }}</p>
                <hr/>
                <p><strong>{{ translations.que_recuerdas }}</strong></p>
                <p (click)="ToogleReportCheck('reportCertificate')" class="cursorLink">
                  <span
                    [ngClass]="(reportCertificate === true) ? 'icon-checked-checkbox' : 'icon-unchecked-checkbox'"></span>
                  <span
                    style="margin-left: 10px;"
                    matTooltip="{{ translations.certificado }}"
                    matTooltipPosition="below"
                    matTooltipClass="tooltipElementBelow">{{ translations.certificado }}</span></p>
                <hr/>
                <p><strong>{{ translations.actividad_web }}</strong></p>
                <div class="contentTwoColumns">
                  <p (click)="ToogleReportCheck('reportActivityActivity')" class="cursorLink">
                    <span
                      [ngClass]="(reportActivityActivity === true) ? 'icon-checked-checkbox' : 'icon-unchecked-checkbox'"></span>
                    <span
                      style="margin-left: 10px;"
                      matTooltip="{{ translations.solo_actividad }}"
                      matTooltipPosition="below"
                      matTooltipClass="tooltipElementBelow">{{ translations.solo_actividad }}</span></p>
                  <p (click)="ToogleReportCheck('reportActivityAll')" class="cursorLink">
                    <span
                      [ngClass]="(reportActivityAll === true) ? 'icon-checked-checkbox' : 'icon-unchecked-checkbox'"></span>
                    <span
                      style="margin-left: 10px;"
                      matTooltip="{{ translations.actividad_respuesta }}"
                      matTooltipPosition="below"
                      matTooltipClass="tooltipElementBelow">{{ translations.actividad_respuesta }}</span></p>
                </div>
                <hr/>
                <p><strong>{{ translations.transcripcion }}</strong></p>
                <div class="contentTwoColumns">
                  <p (click)="ToogleReportCheck('reportTranscriptionText')" class="cursorLink">
                    <span
                      [ngClass]="(reportTranscriptionText === true) ? 'icon-checked-checkbox' : 'icon-unchecked-checkbox'"></span>
                    <span
                      style="margin-left: 10px;"
                      matTooltip="{{ translations.texto_del_video }}"
                      matTooltipPosition="below"
                      matTooltipClass="tooltipElementBelow">{{ translations.texto_del_video }}</span></p>
                  <p (click)="ToogleReportCheck('reportTranscriptionWords')" class="cursorLink">
                    <span
                      [ngClass]="(reportTranscriptionWords === true) ? 'icon-checked-checkbox' : 'icon-unchecked-checkbox'"></span>
                    <span
                      style="margin-left: 10px;"
                      matTooltip="{{ translations.palabras_dificiles }}"
                      matTooltipPosition="below"
                      matTooltipClass="tooltipElementBelow">{{ translations.palabras_dificiles }}</span></p>
                </div>
                <hr/>
                <button (click)="NextStepPrint()" type="button" class="buttonBlue">{{ translations.imprimir }}</button>
              </div>
              <div *ngIf="currentPrint === '2'">
                <p><strong>{{ translations.imprimir }}</strong></p>
                <p class="textGrey14">{{ translations.imprimir_texto_actividades }}</p>
                <hr/>
                <p><strong>{{ translations.your_name }}</strong></p>
                <input class="inputName" placeholder="{{ translations.name }}" [(ngModel)]="username"/>
                <p>&nbsp;</p>
                <button (click)="PrintReport()" type="button" class="buttonBlue">{{ translations.imprimir }}</button>
              </div>
            </app-modal-column>
          </div>
        </ng-template>
      </div>
      <div class="modalContainer__sideMenu">
        <div>
          <nav>
            <ul>
              <li (click)="closeModal.emit()"
                  class="cursorLink" [ngClass]="(individualAspect === true)?'visibilityHidden':''"
              ><span class="icon-close"
                     matTooltip="{{ translations.cerrar }}"
                     matTooltipPosition="left"
                     matTooltipClass="tooltipElement"></span></li>
              <li [ngClass]="(currentTab === 'home')?'ActiveItem':''"
              ><a href="#" (click)="ChangeTab('home', $event)"><span
                class="icon-home"
                matTooltip="{{ translations.inicio }}"
                matTooltipPosition="left"
                matTooltipClass="tooltipElement"></span></a></li>
              <li [ngClass]="(currentTab === 'maps')?'ActiveItem':''"
              ><a href="#" (click)="ChangeTab('maps', $event)"><span
                class="icon-world"
                matTooltip="{{ translations.mapa }}"
                matTooltipPosition="left"
                matTooltipClass="tooltipElement"></span></a></li>
              <li [ngClass]="(currentTab === 'actq')?'ActiveItem':''"
              ><a href="#" (click)="ChangeTab('actq', $event)"><span
                class="icon-circle-tick"
                matTooltip="{{ translations.que_recuerdas }}"
                matTooltipPosition="left"
                matTooltipClass="tooltipElement"></span></a></li>
              <li [ngClass]="(currentTab === 'actw')?'ActiveItem':''"
              ><a href="#" (click)="ChangeTab('actw', $event)"><span
                class="icon-popup"
                matTooltip="{{ translations.actividad_web }}"
                matTooltipPosition="left"
                matTooltipClass="tooltipElement"></span></a></li>
              <li [ngClass]="(currentTab === 'word')?'ActiveItem':''"
              ><a href="#" (click)="ChangeTab('word', $event)"><span
                class="icon-ticket"
                matTooltip="{{ translations.palabras_dificiles }}"
                matTooltipPosition="left"
                matTooltipClass="tooltipElement"></span></a></li>
              <li [ngClass]="(currentTab === 'send')?'ActiveItem':''"
              ><a href="#" (click)="ChangeTab('send', $event)"><span
                class="icon-upload"
                matTooltip="{{ translations.imprimir }}"
                matTooltipPosition="left"
                matTooltipClass="tooltipElement"></span></a></li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
    <div class="footer_sergio" > 
      <div style="width: 70%; height: 40px; float:left; background: #fff;"><img src="assets/images/logo-dark.png" class="logo_sergio" alt="logo"></div>
      <div style="width: 30%; height: 40px; float:right; background: #fff;">© 2023 Carnegie Learning, Inc.</div>
    </div>
  </div>
</div>
