import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import { Isongitem } from '../_models/isongitem';
import {Translations} from '../_models/translations';
import {AuthenticationService} from '../_services/authentication.service';
import {TranslationsService} from '../_services/translations.service';
import {Song} from '../_models/song';
import { Isonganswer } from '../_models/isonganswer';
import {Common} from '../_helpers/common';
import {Ipassportanswer} from '../_models/ipassportanswer';

@Component({
  selector: 'app-isongsitem',
  templateUrl: './isongsitem.component.html',
  styleUrls: ['./isongsitem.component.scss']
})
export class IsongsitemComponent implements OnInit {
  translations: Translations;
  currentTab = 'home';
  currentPrint = '1';
  answer: Isonganswer;
  reportCertificate = false;
  reportActivityActivity = false;
  reportActivityAll = false;
  reportTranscriptionText = false;
  reportTranscriptionWords = false;
  currentSungSong: Song = {
    audio: '',
    lyrics: ''
  };
  currentKaraokeSong: Song = {
    audio: '',
    lyrics: ''
  };

  individualAspect = false;

  username = '';

  @Input() item: Isongitem;
  @Input() itemsubtitles;
  @Output() closeModal = new EventEmitter();

  constructor(
    private translationsService: TranslationsService,
    private common: Common
  ) {
    this.translations = translationsService.getTranslations();
    this.answer = new class implements Isonganswer {
      activity: string;
    }
    this.answer.activity = '';

    // Check if we have a direct link to this element:
    const currentUrl = window.location.href;
    if (currentUrl.indexOf('lang') !== -1) {
      this.individualAspect = true;
    }
  }

  ngOnInit(): void {
    this.currentSungSong.audio = this.item.song_mp3;
    this.currentKaraokeSong.audio = this.item.song_mp3_karaoke;

    this.currentSungSong.lyrics = this.item.subtitles;
    this.currentKaraokeSong.lyrics = this.item.subtitles;

    this.common.changeThemeVar('--item-popup-color', '#168DDD');
    this.common.changeThemeVar('--text-selected', '#fff');
  }

  ChangeTab(newTab, event): void {
    event.preventDefault();
    if (newTab !== this.currentTab) {
      this.currentTab = newTab;
    }
  }

  ToogleReportCheck(variable: string): void {
    this[variable] = !this[variable];
  }

  NextStepPrint(): void {
    this.currentPrint = '2';
  }

  PrintReport(): void {
    const printArea = document.getElementById('contentToPrint');

    if(!printArea) return;

    printArea.innerHTML = '';

    // Título:
    let content = '<p><img src="assets/images/logo-dark@3x.png" alt="iCulture" style="width: 140px; margin-bottom: 50px"></p>';
    content += '<p><strong>iCulture Carnegie Learning</strong></p>';
    content += '<p>Isongs - ' + this.item.titulo + '</p>';
    content += '<p>&nbsp;</p>';

    content += '<p>User: ' + this.username + '</p>';
    content += '<p>&nbsp;</p>';

    // Preguntas
    if (this.reportActivityActivity === true || this.reportActivityAll === true) {
      content += '<p><strong>' + this.translations.actividad + '</strong></p>';
      content += '<div>' + this.item.actividad + '</div>';
      content += '<p>&nbsp;</p>';
    }

    // Respuestas
    if (this.reportActivityAll === true) {
      content += '<p><strong>Your answer</strong></p>';
      content += this.common.answerToText(this.answer.activity, 'text');
      content += '<p>&nbsp;</p>';
    }

    // Letra
    if (this.reportTranscriptionText === true) {
      content += '<p><strong>' + this.translations.transcripcion + '</strong></p>';
      content += '<div>' + this.item.subtitle + '</div>';
      content += '<p>&nbsp;</p>';
    }

    // Palabras
    if (this.reportTranscriptionWords === true) {
      content += '<p><strong>' + this.translations.palabras_dificiles + '</strong></p>';
      content += '<div>' + this.item.words + '</div>';
      content += '<p>&nbsp;</p>';
    }

    // Biografía
    if (this.reportCertificate === true) {
      content += '<p><strong>' + this.translations.biografia + '</strong></p>';
      content += '<div>' + this.item.bio + '</div>';
      content += '<p>&nbsp;</p>';
    }

    printArea.innerHTML = content;
    window.print();
  }
}
