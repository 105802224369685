<div class="pollbooleanContainer {{ extraClass }}">
  <p [ngClass]="title === '' ? 'hiddenItem' : ''"><strong>{{ title }}</strong></p>
  <p [ngClass]="previousText === '' ? 'hiddenItem' : ''">{{ previousText }}</p>
  <div class="poll__item {{ questionExtraClass }}" *ngFor="let question of questions; let i = index" [attr.data-index]="i">
    <p><strong>{{ question }}</strong></p>
    <p>A. <a href="#"
             (click)="ChangeAnswer(i, false, translations.falso, $event)"
             class="inheritColor">
      <span [ngClass]="(currentAnswers[i] === false)?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
      {{ translations.falso }}</a></p>
    <p>B. <a href="#"
             (click)="ChangeAnswer(i, true, translations.verdadero, $event)"
             class="inheritColor">
      <span [ngClass]="(currentAnswers[i] === true)?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
      {{ translations.verdadero }}</a></p>
  </div>
</div>
