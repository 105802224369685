import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Ivideoitemsubtitles } from '../_models/ivideoitemsubtitles';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IvideoitemsubtitlesService {
  constructor(private http: HttpClient) { }

  getItem(id: number): Observable<Ivideoitemsubtitles[]> {
    return this.http.get<Ivideoitemsubtitles[]>(`${environment.api_url}/api/iculture/ivideo/getsubtitles/${id.toString()}`);
  }
}
