import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Common } from '../_helpers/common';
import { Location } from '@angular/common';
import { Itemcard } from '../_models/itemcard';
import { IpassportService } from '../_services/ipassport.service';
import { IvideoService } from '../_services/ivideo.service';
import { IsongService } from '../_services/isong.service';

@Component({
  selector: 'app-archive',
  templateUrl: './archive.component.html',
  styleUrls: ['./archive.component.scss']
})
export class ArchiveComponent implements OnInit {
  sectionName = '';
  currentItems: Itemcard[] = [];

  constructor(
    private ipassportService: IpassportService,
    private ivideoService: IvideoService,
    private isongService: IsongService,
    private actRoute: ActivatedRoute,
    private location: Location,
    private common: Common
  ) {
    this.common.bodyScroll();
    if (this.actRoute.snapshot.params.section) {
      this.sectionName = this.actRoute.snapshot.params.section;
      if (this.sectionName === 'iPassport' || this.sectionName === 'iVideos' || this.sectionName === 'iSongs') {
      } else {
        window.location.href = '/ipassport';
      }
    }
  }

  ngOnInit(): void {
    if (this.sectionName === 'iPassport') {
      this.callToAPI(this.ipassportService);
    } else if (this.sectionName === 'iVideos') {
      this.callToAPI(this.ivideoService);
    } else if (this.sectionName === 'iSongs') {
      this.callToAPI(this.isongService);
    }
  }

  callToAPI(service: any): void {
    this.currentItems = [];
    service.getAllItems(
      localStorage.getItem('currentLanguage'), null, null, null
    ).subscribe((data: any) => {
        if (data.status) {
          data.result.forEach((item) => {
            this.currentItems.push(
              this.processElementFromApi(item)
            );
          });
        }
      },
      error => {
        console.log(error);
      });
  }

  processElementFromApi(item: any): Itemcard {
    return {
      id: item.id,
      category: '',
      title: item.titulo,
      image: '',
      text: item.intro,
      type: '',
      month: '',
      year: '',
      date: item.mesano,
      level: '',
      block: ''
    };
  }

  handleItemClick(itemId: any): void {
    if (this.sectionName === 'iPassport') {
      window.location.href = 'ipassport/' + itemId;
    } else if (this.sectionName === 'iVideos') {
      window.location.href = 'ivideos/' + itemId;
    } else if (this.sectionName === 'iSongs') {
      window.location.href = 'isongs/' + itemId;
    }
  }
}
