import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {Song} from '../_models/song';

@Component({
  selector: 'app-karaokeplayer',
  templateUrl: './karaokeplayer.component.html',
  styleUrls: ['./karaokeplayer.component.scss']
})
export class KaraokeplayerComponent implements OnInit {
  public points = 0;
  public lines: string[] = [];

  @Input() currentSong: Song;
  @Output() lyricsTimeUpdate = new EventEmitter<number>();

  constructor() {}

  ngOnInit(): void {
  }

  resetPlayer(): void {
    this.points = 0;
    this.lines = [];
  }

  handleAudioTimeUpdate = (time: number) => {
    this.lyricsTimeUpdate.emit(time);
  }
}
