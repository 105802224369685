import { Component, OnInit } from '@angular/core';

import { TranslationsService } from '../_services/translations.service';
import {Translations} from '../_models/translations';
import {InewitemService} from '../_services/inewitem.service';
import {ActivatedRoute, Router} from '@angular/router';

import {Common} from '../_helpers/common';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  translations: Translations;
  number = 1;

  constructor(
    private translationsService: TranslationsService,
    private actRoute: ActivatedRoute,
    private router: Router,
    private common: Common
  ) {
    if (this.actRoute.snapshot.params.language) {
      const langNew = this.actRoute.snapshot.params.language;
      if (langNew === 'Spanish' || langNew === 'French' || langNew === 'German') {
        console.log('enter');
        localStorage.setItem('currentLanguage', langNew);
        const user = {
          id: '1',
          name: 'admin',
          token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        };
        localStorage.setItem('currentUser', JSON.stringify(user));
        const d = new Date();
        localStorage.setItem('currentTime', d.getTime().toString() );
        window.location.href = '/ipassport';
        // this.router.navigate(['/ipassport/']);
        return;
      }
    }
    if (this.actRoute.snapshot.params.date) {
      const now = new Date();
      // Expiration: 4 hours
      const date = {
        value: this.actRoute.snapshot.params.date,
        expiration: now.getTime() + 14400000
      };
      localStorage.setItem('currentSinceDate', JSON.stringify(date));
    } else {
      const value = common.checkExpirationSinceDate();
      if (value === '') {
        localStorage.removeItem('currentSinceDate');
      }
    }
    this.translations = translationsService.getTranslations('english');
    this.number = (Math.floor(Math.random() * 3) + 1); // Random number from 1 and 3
  }

  ngOnInit(): void {
  }

  randomBackground(): string {
    return 'background-image: url("assets/images/home/background' + this.number.toString() + '.jpg")'
  }

  changeLanguage(language: string): void {
    console.log(language);
    if (language === 'French') {
      this.number = 1;
    } else if (language === 'German') {
      this.number = 3;
    } else {
      this.number = 2;
    }
  }
}
