import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Ipassportitem } from '../_models/ipassportitem';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IpassportitemService {
  constructor(private http: HttpClient) { }

  getItem(id: number): Observable<Ipassportitem[]> {
    return this.http.get<Ipassportitem[]>(`${environment.api_url}/api/iculture/ipassport/get/${id.toString()}`);
  }
}
