import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Translations} from '../_models/translations';
import {TranslationsService} from '../_services/translations.service';

@Component({
  selector: 'app-question-poll-boolean',
  templateUrl: './question-poll-boolean.component.html',
  styleUrls: ['./question-poll-boolean.component.scss']
})
export class QuestionPollBooleanComponent implements OnInit {
  translations: Translations;

  currentAnswers = [];

  @Input() questions = [];
  @Input() title = '';
  @Input() correctAnswer = '';
  @Input() previousText = '';
  @Input() language = 'spanish';
  @Input() extraClass = '';
  @Input() questionExtraClass = '';
  @Output() onAnswerChange = new EventEmitter();

  constructor(
    private translationsService: TranslationsService
  ) {
    this.translations = translationsService.getTranslations();
  }

  ngOnInit(): void {
    this.questions.forEach((item, index) => {
      this.currentAnswers[index] = '';
    });
  }

  ChangeAnswer(question: number, value: boolean, answer: string, event: any): void {
    event.preventDefault();
    this.currentAnswers[question] = value;

    this.onAnswerChange.emit({ question: question + 1, value, answer });
  }
}
