import { Pipe, PipeTransform } from '@angular/core';
/*
 * Raise the value exponentially
 * Takes an exponent argument that defaults to 1.
 * Usage:
 *   value | processSubtitles
 * Example:
 *   {{ 2 | processSubtitles }}
 *   formats to: 1024
*/
@Pipe({name: 'processSubtitles'})
export class ProcessSubtitlesPipe implements PipeTransform {
  transform(value: string): any {
    const vttBlob = new Blob([value], {
      type: 'text/plain'
    });
    return URL.createObjectURL(vttBlob);
  }
}
