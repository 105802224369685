<div class="collapsible-element mobile-up-hidden" [ngClass]="collapsibleOpen === false ? 'closed' : 'opened'">
  <div class="collapsible__title">
    <p class="cursorLink" (click)="toggleCollapsible()">
      <strong>{{ title }}</strong></p>
    <span class="icon-up-arrow cursorLink" (click)="toggleCollapsible()"></span>
  </div>
  <div class="collapsible__body">
    <div [innerHTML]="body"></div>
    <textarea id="webanswer" class="activitiesTextarea" placeholder="{{ textareaPlaceholder }}"></textarea>
    <div class="characters characters-spanish" [ngClass]="currentLanguage === 'Spanish' ? '' : 'hiddenItem'">
      <button (click)="inschar('webanswer','ñ')">ñ</button>
      <button (click)="inschar('webanswer','Ñ')">Ñ</button>
      <button (click)="inschar('webanswer','á')">á</button>
      <button (click)="inschar('webanswer','Á')">Á</button>
      <button (click)="inschar('webanswer','é')">é</button>
      <button (click)="inschar('webanswer','É')">É</button>
      <button (click)="inschar('webanswer','í')">í</button>
      <button (click)="inschar('webanswer','Í')">Í</button>
      <button (click)="inschar('webanswer','ó')">ó</button>
      <button (click)="inschar('webanswer','Ó')">Ó</button>
      <button (click)="inschar('webanswer','ú')">ú</button>
      <button (click)="inschar('webanswer','Ú')">Ú</button>
      <button (click)="inschar('webanswer','¿')">¿</button>
      <button (click)="inschar('webanswer','¡')">¡</button>
    </div>
    <div class="characters characters-german" [ngClass]="currentLanguage === 'German' ? '' : 'hiddenItem'">
      <button (click)="inschar('webanswer','Ä')">Ä</button>
      <button (click)="inschar('webanswer','ä')">ä</button>
      <button (click)="inschar('webanswer','Ö')">Ö</button>
      <button (click)="inschar('webanswer','ö')">ö</button>
      <button (click)="inschar('webanswer','É')">É</button>
      <button (click)="inschar('webanswer','é')">é</button>
      <button (click)="inschar('webanswer','Ü')">Ü</button>
      <button (click)="inschar('webanswer','ü')">ü</button>
      <button (click)="inschar('webanswer','ß')">ß</button>
    </div>
    <div class="characters characters-french" [ngClass]="currentLanguage === 'French' ? '' : 'hiddenItem'">
      <button (click)="inschar('webanswer','À')">À</button>
      <button (click)="inschar('webanswer','à')">à</button>
      <button (click)="inschar('webanswer','Â')">Â</button>
      <button (click)="inschar('webanswer','â')">â</button>
      <button (click)="inschar('webanswer','Á')">Á</button>
      <button (click)="inschar('webanswer','á')">á</button>
      <button (click)="inschar('webanswer','Æ')">Æ</button>
      <button (click)="inschar('webanswer','æ')">æ</button>
      <button (click)="inschar('webanswer','È')">È</button>
      <button (click)="inschar('webanswer','è')">è</button>
      <button (click)="inschar('webanswer','Ê')">Ê</button>
      <button (click)="inschar('webanswer','ê')">ê</button>
      <button (click)="inschar('webanswer','É')">É</button>
      <button (click)="inschar('webanswer','é')">é</button>
      <button (click)="inschar('webanswer','Ë')">Ë</button>
      <button (click)="inschar('webanswer','ë')">ë</button>
      <button (click)="inschar('webanswer','Î')">Î</button>
      <button (click)="inschar('webanswer','î')">î</button>
      <button (click)="inschar('webanswer','Ï')">Ï</button>
      <button (click)="inschar('webanswer','ï')">ï</button>
      <button (click)="inschar('webanswer','Ô')">Ô</button>
      <button (click)="inschar('webanswer','ô')">ô</button>
      <button (click)="inschar('webanswer','Œ')">Œ</button>
      <button (click)="inschar('webanswer','œ')">œ</button>
      <button (click)="inschar('webanswer','Ù')">Ù</button>
      <button (click)="inschar('webanswer','ù')">ù</button>
      <button (click)="inschar('webanswer','Û')">Û</button>
      <button (click)="inschar('webanswer','û')">û</button>
      <button (click)="inschar('webanswer','Ü')">Ü</button>
      <button (click)="inschar('webanswer','ü')">ü</button>
      <button (click)="inschar('webanswer','ÿ')">ÿ</button>
      <button (click)="inschar('webanswer','Ç')">Ç</button>
      <button (click)="inschar('webanswer','ç')">ç</button>
      <button (click)="inschar('webanswer','«')">«</button>
      <button (click)="inschar('webanswer','»')">»</button>
    </div>
  </div>
</div>

