<div class="pollContainer {{ extraClass }}">
  <p [ngClass]="title === '' ? 'hiddenItem' : ''"><strong>{{ title }}</strong></p>
  <p [ngClass]="previousText === '' ? 'hiddenItem' : ''">{{ previousText }}</p>
  <div class="poll__item {{ questionExtraClass }}" *ngFor="let question of questions; let i = index" [attr.data-index]="i">
    <p><strong>{{ question.question }}</strong></p>
    <p *ngFor="let answer of question.answers; let i2 = index" [attr.data-index]="i2">
      {{ PaintLetter(i2) }}.
      <a href="#" (click)="ChangeAnswer(i, i2.toString(10), answer, $event)" class="inheritColor">
      <span [ngClass]="(currentAnswers[i] === i2.toString(10))?'icon-checked-radio-button':'icon-circle'" class="icon"></span>
        {{ answer }}</a></p>
  </div>
</div>
