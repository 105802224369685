import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-archive-card',
  templateUrl: './archive-card.component.html',
  styleUrls: ['./archive-card.component.scss']
})
export class ArchiveCardComponent implements OnInit {
  @Input() id: string;
  @Input() title: string;
  @Input() text: string;
  @Input() date: string;
  @Output() clickElement = new EventEmitter();

  constructor() { }

  ngOnInit(): void {
  }

  handleClick(): void {
    this.clickElement.emit(this.id);
  }
}
