import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { Isongitem } from '../_models/isongitem';
import { environment } from '../../environments/environment';
import {Observable} from 'rxjs';

@Injectable({ providedIn: 'root' })
export class IsongitemService {
  constructor(private http: HttpClient) { }

  getItem(id: number): Observable<Isongitem[]> {
    return this.http.get<Isongitem[]>(`${environment.api_url}/api/iculture/isong/get/${id.toString()}`);
  }
}
