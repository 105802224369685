import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-modal-column',
  templateUrl: './modal-column.component.html',
  styleUrls: ['./modal-column.component.scss']
})
export class ModalColumnComponent implements OnInit {
  @Input() extraComponentClass;
  @Input() headerComponentTitle;
  @Input() headerComponentIcon;
  @Input() relatedItemiNews: boolean = false;
  @Input() relatedItemiNewsTitle: string = '';
  @Input() iNewsActivity: boolean = false;
  @Input() iNewsActivityTitle: string = '';
  @Output() clickRelatedItem = new EventEmitter();

  constructor() {}
  ngOnInit(): void {}

  clickRelated(): void {
    this.clickRelatedItem.emit();
  }
}
