import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription, fromEvent } from 'rxjs';
import { map } from 'rxjs/operators';
import { UUID } from 'uuid-generator-ts';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent implements OnInit, AfterViewInit, OnDestroy {
  audio: HTMLAudioElement;
  uuid;
  isPlaying = false;
  hasVolume = true;
  duration = '00:00';
  timeSubscription: Subscription;
  loadSubscription: Subscription;
  currentTime = 0;
  currentLongTimer = 0;

  @Input() src = '';
  @Input() autoPlay = false;
  @Output() CurrentTimeUpdate = new EventEmitter<number>();
  @Output() PlayPause = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {
    const uuid = new UUID();
    this.uuid = uuid.getDashFreeUUID();
  }

  ngAfterViewInit(): void {
    this.audio = document.getElementById('audio-' + this.uuid) as HTMLAudioElement;

    // Loads new audio source
    this.loadAudioSource(this.src);

    // Subscribes timeupdate
    this.timeSubscription = fromEvent(this.audio, 'timeupdate')
      .pipe(map(event => event)).subscribe(
        (event) => { this.handleAudioTimeUpdate(event); });

    // Subscribe to loaded event
    this.loadSubscription = fromEvent(this.audio, 'loadeddata')
      .pipe(map(event => event)).subscribe(
        (event) => { this.handleAudioLoaded(event); });

    if (this.autoPlay === true) {
      this.isPlaying = true;
      this.audio.play();
    }

    // Subscribe other events
    // this.audio.addEventListener('playing', this.handleAudioPlayed);
    // this.audio.addEventListener('pause', this.handleAudioPaused);
  }

  ngOnDestroy(): void {
    // Unsubscribe
    this.timeSubscription.unsubscribe();
    this.loadSubscription.unsubscribe();

    // Destroy audio tag
    this.loadAudioSource('');
    this.audio.load();
  }

  loadAudioSource(src: string): void {
    this.audio.pause();
    this.handleAudioPaused();
    this.audio.src = src;
  }

  _timeToString(time: number): string {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return minutes.toString() + ':' + seconds.toString().padStart(2, '0');
  }

  handleAudioLoaded = (e: any) => {
    this.duration = this._timeToString(this.audio.duration);
  }

  handleAudioTimeUpdate = (e: any) => {
    this.currentTime = this.audio.currentTime;
    this.currentLongTimer = Math.floor((this.audio.currentTime * 100) / this.audio.duration);
    this.CurrentTimeUpdate.emit(this.audio.currentTime);
  }

  handleAudioPlayed = () => {
    this.PlayPause.emit(true);
    this.isPlaying = true;
    this.audio.play();
  }

  handleAudioPaused = () => {
    this.PlayPause.emit(false);
    this.isPlaying = false;
    this.audio.pause();
  }

  handleAudioMuted = () => {
    this.hasVolume = false;
    this.audio.muted = true;
  }

  handleAudioMutedOff = () => {
    this.hasVolume = true;
    this.audio.muted = false;
  }

  handleChangeTime(event: any): void {
    // e = Mouse click event.
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left; // x position within the element.
    const total = document.getElementById('progress-' + this.uuid).clientWidth;

    // console.log( x + ' <--> ' + total);

    this.currentLongTimer = Math.floor((x * 100) / total);
    // console.log( this.currentLongTimer + '%');
    // Math.floor((this.audio.currentTime * 100) / this.audio.duration);
    this.currentTime = Math.floor((this.currentLongTimer * this.audio.duration) / 100);
    this.audio.currentTime = this.currentTime;
  }
}
