import {Component, Input, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {ActivatedRoute} from '@angular/router';
import {IpassportService} from '../_services/ipassport.service';
import {IpassportitemService} from '../_services/ipassportitem.service';
import {IpassportitemsubtitlesService} from '../_services/ipassportitemsubtitles.service';

import {Itemcard} from '../_models/itemcard';
import {Ipassportitem} from '../_models/ipassportitem';
import {Ipassportitemsubtitles} from '../_models/ipassportitemsubtitles';
import {Filtersobject} from '../_models/filtersobject';
import {Translations} from '../_models/translations';
import {AuthenticationService} from '../_services/authentication.service';
import {TranslationsService} from '../_services/translations.service';
import {Common} from '../_helpers/common';

@Component({
  selector: 'app-ipassport',
  templateUrl: './ipassport.component.html',
  styleUrls: ['./ipassport.component.scss']
})
export class IpassportComponent implements OnInit {
  translations: Translations;
  searchedItems: Itemcard[] = [];
  searching = false;
  currentItems: Itemcard[] = [];
  archivedItemsAboveRow: Itemcard[] = [];
  archivedItemsBelowRow: Itemcard[] = [];
  sectionName = '-Passport';
  iconPath = 'icon-ipassport';
  color = '#C7161E';
  itemId: number;
  itemPreview: Ipassportitem = null;
  itemPreviewSubtitles: Ipassportitemsubtitles = null;
  currentFilters: Filtersobject = {
    date: null, level: null, apthemes: null, categories: null
  };

  constructor(
    private ipassportService: IpassportService,
    private ipassportitemService: IpassportitemService,
    private ipassportitemsubtitlesService: IpassportitemsubtitlesService,
    private actRoute: ActivatedRoute,
    private location: Location,
    private translationsService: TranslationsService,
    private common: Common
  ) {
    this.common.bodyScroll();
    if (this.actRoute.snapshot.params.language) {
      const langNew = this.actRoute.snapshot.params.language;
      if (langNew === 'Spanish' || langNew === 'French' || langNew === 'German') {
        localStorage.setItem('currentLanguage', langNew);
      }
    }

    if (this.actRoute.snapshot.params.id) {
      // Get ID from URL
      this.itemId = this.actRoute.snapshot.params.id;
      // Call to API to retrieve ID item information
      this.readItemPreview();
    } else {
      this.itemId = null;
    }

    this.location = location;
    this.translations = translationsService.getTranslations();
  }

  ngOnInit(): void {
    this.callToAPI();
  }

  callToAPI(): void {
    this.currentItems = [];
    this.archivedItemsAboveRow = [];
    this.archivedItemsBelowRow = [];
    this.searching = true;
    this.ipassportService.getAllItems(
      localStorage.getItem('currentLanguage'),
      this.currentFilters.apthemes,
      this.currentFilters.categories,
      this.currentFilters.date
    ).subscribe((data: any) => {
      if (data.status) {
        data.result.forEach((item, index) => {
          if (index < 9) {
            this.currentItems.push(
              this.processItemFromApi(item)
            );
          } else {
            if (index % 2 === 1) {
              this.archivedItemsAboveRow.push(
                this.processItemFromApi(item)
              );
            } else {
              this.archivedItemsBelowRow.push(
                this.processItemFromApi(item)
              );
            }
          }
        });
      }
      this.searching = false;
    },
    error => {
      console.log(error);
      this.searching = false;
    });
  }

  processItemFromApi(item: any): Itemcard {
    return {
      id: item.id,
      category: item.cat_name ? item.cat_name : item.cat,
      title: item.titulo,
      image: item.image,
      text: item.intro,
      type: 'passport',
      month: '',
      year: '',
      date: '',
      level: '',
      block: ''
    };
  }

  readItemPreview(): void {
    this.ipassportitemService.getItem(this.itemId).subscribe((data: any) => {
      if (data.status) {
        data.result.forEach((item: Ipassportitem) => {
          this.itemPreview = item;
        });
        this.loadItemPopup();
      }
    });
    this.ipassportitemsubtitlesService.getItem(this.itemId).subscribe((data: any) => {
      if (data.status) {
        data.result.forEach((item) => {
          this.itemPreviewSubtitles = {
            id: item.id,
            url: item.url
          };
        });
        this.loadItemPopup();
      }
    });
  }

  loadItemPopup(): void {
    this.common.bodyNoScroll();
  }

  handleItemClick(itemId: number): void {
    this.itemId = itemId;
    // this.location.go('ipassport/' + itemId);
    // Call to API to retrieve ID item information
    this.readItemPreview();
  }

  handleCloseModal(): void {
    this.itemId = null;
    this.itemPreview = null;
    this.itemPreviewSubtitles = null;
    this.location.go('ipassport/');
    this.common.bodyScroll();
  }

  CutText(text: string): string {
    return this.common.CutText(text, 135);
  }

  ChangeFilters(element: Filtersobject): void {
    this.currentFilters.apthemes = null;
    this.currentFilters.categories = null;
    this.currentFilters.date = null;

    if (element.apthemes !== '') {
      this.currentFilters.apthemes = element.apthemes;
    }
    if (element.categories !== '') {
      this.currentFilters.categories = element.categories;
    }
    if (element.date !== '') {
      this.currentFilters.date = element.date;
    }

    this.callToAPI();
  }
}
