<header>
  <div [ngClass]="openSearch === true ? '' : 'hiddenItem'" class="containerSearch">
    <div class="overlaySearch">
      <div class="search__container">
        <div class="search__logo">
          <img src="assets/images/logo-dark.png"
               srcset="assets/images/logo-dark.png 1x, assets/images/logo-dark@2x.png 2x, assets/images/logo-dark@3x.png 3x">
        </div>
        <div class="search__search">
          <span class="icon-search"></span>
          <input
            id="inputGlobalSearch"
            type="text"
            placeholder="{{ translations.busqueda_iculture }}"
            (input)="ChangeSearch($event.target.value)"
            value="" />
          <span class="icon-close cursorLink" (click)="ToogleSearch()"></span>
        </div>
        <div class="search__emptySpace"></div>
      </div>
      <div class="search__results" [ngClass]="takeSearch === true ? '' : 'hiddenItem'">
        <div class="searchResults__main">
          <div *ngIf="searching === true;else searched">
            <p>{{ translations.buscando }}</p>
          </div>
          <ng-template #searched>
            <div *ngIf="searching === false && numberItemsSearch === 0;else results">
              <p>{{ translations.not_found }}</p>
            </div>
            <ng-template #results>
              <div class="results__list" style="max-height: 400px; overflow-y: scroll">
                <div *ngIf="searchedItemsiPassport && searchedItemsiPassport.length > 0">
                  <p style="margin-top: 15px; font-size: 15px; color: #C7161E;"><span class="icon-ipassport"></span> iPassport</p>
                  <div *ngFor="let item of searchedItemsiPassport" class="results__item">
                    <app-element-month-card
                      color="#C7161E"
                      icon="icon-ipassport"
                      name="iPassport"
                      title="{{ item.title }}"
                      img="{{ item.image }}"
                      text=""
                      category="{{ item.category }}"
                      level="{{ item.level }}"
                      date="{{ item.date | date: 'mediumDate'  }}"
                      (openItem)="openItemiPassport( item.id )"
                    ></app-element-month-card>
                  </div>
                </div>
                <div *ngIf="searchedItemsiNews && searchedItemsiNews.length > 0">
                  <p style="margin-top: 15px; font-size: 15px; color: #EC8F23;"><span class="icon-wifi"></span> iNews</p>
                  <div *ngFor="let item of searchedItemsiNews" class="results__item">
                    <app-element-month-card
                      color="#EC8F23"
                      icon="icon-wifi"
                      name="iNews"
                      title="{{ item.title }}"
                      img="{{ item.image }}"
                      text=""
                      category="{{ item.category }}"
                      level="{{ item.level }}"
                      date="{{ item.date | date: 'mediumDate'  }}"
                      (openItem)="openItemiNews( item.id )"
                    ></app-element-month-card>
                  </div>
                </div>
                <div *ngIf="searchedItemsiVideos && searchedItemsiVideos.length > 0">
                  <p style="margin-top: 15px; font-size: 15px; color: #351361;"><span class="icon-play-circle"></span> iVideos</p>
                  <div *ngFor="let item of searchedItemsiVideos" class="results__item">
                    <app-element-month-card
                      color="#351361"
                      icon="icon-play-circle"
                      name="iVideos"
                      title="{{ item.title }}"
                      img="{{ item.image }}"
                      text=""
                      category="{{ item.category }}"
                      level="{{ item.level }}"
                      date="{{ item.date | date: 'mediumDate'  }}"
                      (openItem)="openItemiVideos( item.id )"
                    ></app-element-month-card>
                  </div>
                </div>
                <div *ngIf="searchedItemsiSongs && searchedItemsiSongs.length > 0">
                  <p style="margin-top: 15px; font-size: 15px; color: #168DDD;"><span class="icon-music"></span> iSongs</p>
                  <div *ngFor="let item of searchedItemsiSongs" class="results__item">
                    <app-element-month-card
                      color="#168DDD"
                      icon="icon-music"
                      name="iSongs"
                      title="{{ item.title }}"
                      img="{{ item.image }}"
                      text=""
                      category="{{ item.category }}"
                      level="{{ item.level }}"
                      date="{{ item.date | date: 'mediumDate'  }}"
                      (openItem)="openItemiSongs( item.id )"
                    ></app-element-month-card>
                  </div>
                </div>
              </div>
            </ng-template>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
  <div class="containerHeader {{ language }}">
    <div class="internalLogo" style="height: 85px;">
      <img [ngClass]="openSearch === false ? '' : 'hiddenItem'"
           src="assets/images/logo.png"
           alt="iCulture"
           srcset="assets/images/logo.png 1x, assets/images/logo@2x.png 2x">
    </div>
    <div class="header__text">
      <p>{{ languageLang }}</p>
      <h1 [innerHTML]="translations.explora_iculture"></h1>
    </div>
  </div>
  <div class="containerNavigation">
    <nav class="header__mainMenu mobile-hidden">
      <ul>
        <li [ngClass]="(option==='ipassport')?'opcionActive':''"><a routerLink="/ipassport">iPassport</a></li>
        <li [ngClass]="(option==='inews')?'opcionActive':''"><a routerLink="/inews">iNews</a></li>
        <li [ngClass]="(option==='ivideos')?'opcionActive':''"><a routerLink="/ivideos">iVideos</a></li>
        <li [ngClass]="(option==='isongs')?'opcionActive':''"><a routerLink="/isongs">iSongs</a></li>
      </ul>
    </nav>
    <div class="iconsMenu mobile-hidden">
      <ul>
        <li [ngClass]="openSearch === true ? 'Active' : ''"
            (click)="ToogleSearch()" class="cursorLink">
          <span
            class="icon-search"
            matTooltip="{{ translations.busqueda }}"
            matTooltipPosition="below"
            matTooltipClass="tooltipElementBelow"
          ></span>
        </li>
        <li [ngClass]="openOptions === true ? 'Active' : ''"
            (click)="ToogleOptions()" class="cursorLink">
          <span
            class="icon-options"
            matTooltip="{{ translations.filtros }}"
            matTooltipPosition="left"
            matTooltipClass="tooltipElement"
          ></span>
        </li>
      </ul>
    </div>
    <div class="header__mobileMenu mobile-up-hidden">
      <span class="icon-menu cursorLink" (click)="openMobileMenu()"></span>
      <div class="currentItemLabel">
        <p [ngClass]="(option==='ipassport')?'':'hiddenItem'">iPassport</p>
        <p [ngClass]="(option==='inews')?'':'hiddenItem'">iNews</p>
        <p [ngClass]="(option==='ivideos')?'':'hiddenItem'">iVideos</p>
        <p [ngClass]="(option==='isongs')?'':'hiddenItem'">iSongs</p>
      </div>
      <ul class="headerMobile__Options">
        <li [ngClass]="openSearch === true ? 'Active' : ''"
            (click)="ToogleSearch()" class="cursorLink"><span class="icon-search"></span></li>
        <li [ngClass]="openOptions === true ? 'Active' : ''"
            (click)="ToogleOptions()" class="cursorLink"><span class="icon-options"></span></li>
      </ul>
    </div>
  </div>
  <div class="containerFilters" [ngClass]="openOptions === true ? '' : 'hiddenItem'">
    <app-filter-select
      name="{{ translations.filtro_ap_themes }}"
      *ngIf="option !== 'ipassport'"
      [opened]="openApthemesSelector"
      [options]="apTagsList"
      (changeOption)="changeAPThemes($event)"
      (openSelector)="OpenApthemesSelector()"
      (closeSelector)="CloseApthemesSelector()"
    ></app-filter-select>
    <app-filter-select
      name="{{ translations.filtro_categories }}"
      *ngIf="option !== 'ipassport'"
      [opened]="openCategorySelector"
      [twoColumns]="true"
      [options]="categoriesList"
      (changeOption)="changeCategories($event)"
      (openSelector)="OpenCategoriesSelector()"
      (closeSelector)="CloseCategoriesSelector()"
    ></app-filter-select>
    <div *ngIf="monthSelectorType === 'normal';else elseBlock">
      <app-date-select
        monthSelectorType="{{ monthSelectorType }}"
        name="{{ translations.filtro_date }}"
        (changeOption)="changeDate($event)"></app-date-select>
    </div>
    <ng-template #elseBlock>
      <app-date-year-month-select
        name="{{ translations.filtro_date }}"
        (changeOption)="changeDate($event)"></app-date-year-month-select>
    </ng-template>
    <app-filter-select
      *ngIf="inewsFilters === true"
      name="{{ translations.filtro_level }}"
      [opened]="openLevelSelector"
      [options]="LevelOptions()"
      (changeOption)="changeLevel($event)"
      (openSelector)="OpenLevelSelector()"
      (closeSelector)="CloseLevelSelector()"
    ></app-filter-select>
  </div>
</header>
<div *ngIf="showMobileMenu" class="mobileMenu__container">
  <div class="overlay">
    <div class="mobileMenu__header">
      <span class="icon-close cursorLink" (click)="closeMobileMenu()"></span>
    </div>
    <div class="mobileMenu__main">
      <ul>
        <li class="cursorLink" [ngClass]="(option==='ipassport')?'activeOption':''">
          <a routerLink="/ipassport">iPassport</a>
        </li>
        <li class="cursorLink" [ngClass]="(option==='inews')?'activeOption':''">
          <a routerLink="/inews">iNews</a>
        </li>
        <li class="cursorLink" [ngClass]="(option==='ivideos')?'activeOption':''">
          <a routerLink="/ivideos">iVideos</a>
        </li>
        <li class="cursorLink" [ngClass]="(option==='isongs')?'activeOption':''">
          <a routerLink="/isongs">iSongs</a>
        </li>
      </ul>
    </div>
  </div>
</div>
