import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthenticationService } from '../_services/authentication.service';
import {TranslationsService} from '../_services/translations.service';
import {Translations} from '../_models/translations';
import {HttpClient} from '@angular/common/http';
import {Common} from '../_helpers/common';
import {Selectitem} from '../_models/selectitem';

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss']
})
export class LoginFormComponent implements OnInit {
  translations: Translations;
  currentLanguage = 'english';
  step = '1';
  inputPassword = '';
  openLanguageSelector = false;
  showError = false;
  currentInputType = 'password';

  @Output() onChangeLanguage = new EventEmitter();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService,
    private translationsService: TranslationsService,
    private common: Common
  ) {
    // redirect to home if already logged in
    if (this.authenticationService.currentUserValue) {
      this.router.navigate(['/']);
    }

    this.changeLanguage(this.currentLanguage);
  }

  ngOnInit(): void {}

  changeLanguage(language: string): void {
    this.translations = this.translationsService.getTranslations(language);
  }

  onSelectLanguage(language: string): void {
    this.currentLanguage = language;
    localStorage.setItem('currentLanguage', language);
    this.changeLanguage(language);
    this.step = '2';

    this.onChangeLanguage.emit(language);
  }

  onLogin(): void {
    this.showError = false;
    if (this.inputPassword === '') { return; }
    this.authenticationService.login(
      this.common.SHA1(this.inputPassword)
    ).subscribe((data: any) => {
      if (data.access && data.access === true) {
        // Login the user
        console.log(data);
        const user = {
          id: '1',
          name: 'admin',
          token: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ.SflKxwRJSMeKKF2QT4fwpMeJf36POk6yJV_adQssw5c'
        };
        localStorage.setItem('currentUser', JSON.stringify(user));
        const d = new Date();
        localStorage.setItem('currentTime', d.getTime().toString() );
        this.router.navigate(['ipassport']);
      }
    },
    error => {
      this.showError = true;
      console.log(error);
    }
    );
  }

  LanguageSelectorOptions(): Selectitem[] {
    const ret: Selectitem[] = [];
    let tmp: Selectitem = {
      label: 'Spanish',
      value: 'Spanish'
    };
    ret.push(tmp);
    tmp = {
      label: 'French',
      value: 'French'
    };
    ret.push(tmp);
    tmp = {
      label: 'German',
      value: 'German'
    };
    ret.push(tmp);

    return ret;
  }

  OpenLanguageSelector(): void {
    this.openLanguageSelector = true;
  }

  CloseLanguageSelector(): void {
    this.openLanguageSelector = false;
  }

  changeLanguageSelector(current: any): void {
    this.currentLanguage = current;
    localStorage.setItem('currentLanguage', current);
    this.changeLanguage(current);

    this.onChangeLanguage.emit(current);
  }

  onViewPassword(): void {
    if (this.currentInputType === 'password') {
      this.currentInputType = 'text';
    } else {
      this.currentInputType = 'password';
    }
  }
}
