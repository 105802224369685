<div class="map__navigation {{ extraComponentClass }}">
  <ul id="list-{{ uuid }}">
    <li *ngIf="initialLabel && initialLabel !== ''" class="InitialLabel">{{ initialLabel }}</li>
    <li *ngFor="let option of elementList"
        class="navigation__item cursorLink {{ option.extraClass }}"
        [attr.data-elementid]="option.id"
        (click)="onChangeOption(option.id, option.link)">
      {{ option.name }}
    </li>
  </ul>
</div>
