import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

import { Ivideoitem } from '../_models/ivideoitem';
import {Translations} from '../_models/translations';
import {AuthenticationService} from '../_services/authentication.service';
import {TranslationsService} from '../_services/translations.service';
import {Common} from '../_helpers/common';
import {navigationoption} from '../_models/navigationoption';
import {Ivideoanswer} from '../_models/ivideoanswer';

@Component({
  selector: 'app-ivideositem',
  templateUrl: './ivideositem.component.html',
  styleUrls: ['./ivideositem.component.scss']
})
export class IvideositemComponent implements OnInit {
  translations: Translations;

  currentTab = 'home';
  currentMap = '1';
  currentQuest = '1';
  currentAdvanced = '1';
  currentPersonal = '1';
  currentPrint = '1';
  stopPlayVideo = false;
  question1 = '';
  question2 = '';
  question3 = '';
  question4 = '';
  videoCurrentTime = '';
  answer: Ivideoanswer;
  reportCertificate = false;
  reportActivityActivity = false;
  reportActivityAll = false;
  reportPersonalActivity = false;
  reportPersonalAll = false;
  reportAvanzadoActivity = false;
  reportAvanzadoAll = false;
  reportTranscriptionText = false;
  reportTranscriptionWords = false;

  activitiesNavigationMenu: navigationoption[] = [];
  algopersonalNavigationMenu: navigationoption[] = [];
  advancedNavigationMenu: navigationoption[] = [];

  individualAspect = false;

  username = '';

  @Input() item: Ivideoitem;
  @Input() itemsubtitles;
  @Output() closeModal = new EventEmitter();

  constructor(
    private translationsService: TranslationsService,
    private common: Common
  ) {
    this.translations = translationsService.getTranslations();
    this.answer = new class implements Ivideoanswer {
      activity: string;
      algo_personal1: string;
      algo_personal2: string;
      algo_personal3: string;
      avanzado1: string;
      avanzado2: string;
      q1: string;
      q2: string;
      q3: string;
      q4: string;
    }
    this.answer.activity = '';
    this.answer.q1 = '';
    this.answer.q2 = '';
    this.answer.q3 = '';
    this.answer.q4 = '';
    this.answer.algo_personal1 = '';
    this.answer.algo_personal2 = '';
    this.answer.algo_personal3 = '';
    this.answer.avanzado1 = '';
    this.answer.avanzado2 = '';

    // Check if we have a direct link to this element:
    const currentUrl = window.location.href;
    if (currentUrl.indexOf('lang') !== -1) {
      this.individualAspect = true;
    }
  }

  ngOnInit(): void {
    for(let i = 1 ; i <= 4 ; ++i) {
      this.activitiesNavigationMenu.push({
        id: i.toString(),
        name: i.toString(),
        extraClass: '',
        link: ''
      });
    }

    for(let i = 1 ; i <= 3 ; ++i) {
      this.algopersonalNavigationMenu.push({
        id: i.toString(),
        name: i.toString(),
        extraClass: '',
        link: ''
      });
    }

    for(let i = 1 ; i <= 2 ; ++i) {
      this.advancedNavigationMenu.push({
        id: i.toString(),
        name: i.toString(),
        extraClass: '',
        link: ''
      });
    }

    this.common.changeThemeVar('--item-popup-color', '#351361');
    this.common.changeThemeVar('--text-selected', '#fff');
  }

  ChangeTab(newTab, event, mobile?:boolean): void {
    event.preventDefault();
    if (newTab !== this.currentTab) {
      this.currentTab = newTab;
    }

    if(mobile && mobile === true) {
      // Move scroll on mobile menu:
      setTimeout(() => {
        const container = document.getElementById('MobileMenu');
        const rowToScrollTo = document.getElementById(newTab);
        container.scrollLeft = rowToScrollTo.offsetLeft;
      }, 200);
      setTimeout(() => {
        const container = document.getElementById('MobileMenu');
        const rowToScrollTo = document.getElementById(newTab);
        container.scrollLeft = rowToScrollTo.offsetLeft;
      }, 400);
      setTimeout(() => {
        const container = document.getElementById('MobileMenu');
        const rowToScrollTo = document.getElementById(newTab);
        container.scrollLeft = rowToScrollTo.offsetLeft;
      }, 600);
    }

  }

  ChangeQuestion(newTab: string): void {
    if (newTab !== this.currentQuest) {
      this.currentQuest = newTab;
      this.stopPlayVideo = true;

      setTimeout(() => this.stopPlayVideo = false, 500);
    }
  }

  ChangeAdvance(newTab: string): void {
    if (newTab !== this.currentAdvanced) {
      this.currentAdvanced = newTab;
    }
  }

  ChangePersonal(newTab: string): void {
    if (newTab !== this.currentPersonal) {
      this.currentPersonal = newTab;
    }
  }

  ToogleReportCheck(variable: string): void {
    this[variable] = !this[variable];
  }

  FixUserAnswer(question, answer): void {
    const questionName = 'q' + question;
    const answerText = 'q' + question + answer;
    this.answer[questionName] = (this.answer[questionName] === '') ? answerText : this.answer[questionName];
  }

  ChangeAnswer(question, answer, event): void {
    event.preventDefault();
    this.FixUserAnswer(question, answer);
    if (question === '1') {
      this.question1 = answer;
      if (answer === this.item.q1x) {
        this.videoCurrentTime = this.item.q1f;
      } else {
        this.videoCurrentTime = '';
      }
    } else if (question === '2') {
      this.question2 = answer;
      if (answer === this.item.q2x) {
        this.videoCurrentTime = this.item.q2f;
      } else {
        this.videoCurrentTime = '';
      }
    } else if (question === '3') {
      this.question3 = answer;
      if (answer === this.item.q3x) {
        this.videoCurrentTime = this.item.q3f;
      } else {
        this.videoCurrentTime = '';
      }
    } else if (question === '4') {
      this.question4 = answer;
      if (answer === this.item.q4x) {
        this.videoCurrentTime = this.item.q4f;
      } else {
        this.videoCurrentTime = '';
      }
    }
  }

  NextStepPrint(): void {
    this.currentPrint = '2';
  }

  PrintReport(): void {
    const printArea = document.getElementById('contentToPrint');

    if(!printArea) return;

    printArea.innerHTML = '';

    // Título:
    let content = '<p><img src="assets/images/logo-dark@3x.png" alt="iCulture" style="width: 140px; margin-bottom: 50px"></p>';
    content += '<p><strong>iCulture Carnegie Learning</strong></p>';
    content += '<p>Ivideos - ' + this.item.titulo + '</p>';
    content += '<p>&nbsp;</p>';

    content += '<p>User: ' + this.username + '</p>';
    content += '<p>&nbsp;</p>';

    if (this.reportCertificate === true) {
      content += '<p><strong>' + this.translations.que_recuerdas + '</strong></p>';

      // --- que recuerdas - - - 01
      content += '<p><strong>' + this.translations.preguntas + ' - 1</strong></p>';
      content += '<div>' + this.item.q1 + '</div>';
      content += '<p><strong>Your answer: </strong></p>';
      content += this.common.answerToText(this.item[this.answer.q1]);

      // --- que recuerdas - - - 02
      content += '<p><strong>' + this.translations.preguntas + ' - 2</strong></p>';
      content += '<div>' + this.item.q2 + '</div>';
      content += '<p><strong>Your answer: </strong></p>';
      content += this.common.answerToText(this.item[this.answer.q2]);

      // --- que recuerdas - - - 03
      content += '<p><strong>' + this.translations.preguntas + ' - 3</strong></p>';
      content += '<div>' + this.item.q3 + '</div>';
      content += '<p><strong>Your answer: </strong></p>';
      content += this.common.answerToText(this.item[this.answer.q3]);


      // --- que recuerdas - - - 04
      content += '<p><strong>' + this.translations.preguntas + ' - 4</strong></p>';
      content += '<div>' + this.item.q4 + '</div>';
      content += '<p><strong>Your answer: </strong></p>';
      content += this.common.answerToText(this.item[this.answer.q4]);

    }

    // Algo personal
    if (this.reportPersonalActivity === true || this.reportPersonalAll === true) {
      content += '<p><strong>' + this.translations.algo_personal + '</strong></p>';
      content += '<div>' + this.translations.haz_clic_en_el_boton + '</div>';
      content += '<p>&nbsp;</p>';
    }

    // Respuesta Algo personal
    if (this.reportPersonalAll === true) {
      content += '<p><strong>Your answer 1</strong></p>';
      content += this.common.answerToText(this.answer.algo_personal1, 'text');
      content += '<p>&nbsp;</p>';
      content += '<p><strong>Your answer 2</strong></p>';
      content += this.common.answerToText(this.answer.algo_personal2, 'text');
      content += '<p>&nbsp;</p>';
      content += '<p><strong>Your answer 3</strong></p>';
      content += this.common.answerToText(this.answer.algo_personal3, 'text');
      content += '<p>&nbsp;</p>';
    }

    // Avanzado
    if (this.reportAvanzadoActivity === true || this.reportAvanzadoAll === true) {
      content += '<p><strong>' + this.translations.avanzado + '</strong></p>';
      content += '<div>' + this.item.q6 + '</div>';
      content += '<p>&nbsp;</p>';
    }

    // Respuesta Avanzado
    if (this.reportAvanzadoAll === true) {
      content += '<p><strong>Your answer 1</strong></p>';
      content += this.common.answerToText(this.answer.avanzado1, 'text');
      content += '<p>&nbsp;</p>';
      content += '<p><strong>Your answer 2</strong></p>';
      content += this.common.answerToText(this.answer.avanzado2, 'text');
      content += '<p>&nbsp;</p>';
    }

    // Pregunta Actividad Web
    if (this.reportActivityActivity === true || this.reportActivityAll === true) {
      content += '<p><strong>' + this.translations.actividad_web + '</strong></p>';
      content += '<div>' + this.item.q7 + '</div>';
      content += '<p>&nbsp;</p>';
    }

    // Respuesta Actividad Web
    if (this.reportActivityAll === true) {
      content += '<p><strong>Your answer</strong></p>';
      content += this.common.answerToText(this.answer.activity, 'text');
      content += '<p>&nbsp;</p>';
    }

    // Transcripción
    if (this.reportTranscriptionText === true) {
      content += '<p><strong>' + this.translations.transcripcion + '</strong></p>';
      content += '<div>' + this.item.subtitle + '</div>';
      content += '<p>&nbsp;</p>';
    }

    // Palabras difíciles
    if (this.reportTranscriptionWords === true) {
      content += '<p><strong>' + this.translations.palabras_dificiles + '</strong></p>';
      content += '<div>' + this.item.words + '</div>';
      content += '<p>&nbsp;</p>';
    }

    printArea.innerHTML = content;
    window.print();
  }
}
