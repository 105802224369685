import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-element-card',
  templateUrl: './element-card.component.html',
  styleUrls: ['./element-card.component.scss']
})
export class ElementCardComponent implements OnInit {
  @Input() color: string;
  @Input() title: string;
  @Input() img: string;
  @Input() text: string;
  @Input() section: string;
  @Input() icon: string;

  constructor() { }

  ngOnInit(): void {
  }

}
