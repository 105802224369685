import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-element-month-card',
  templateUrl: './element-month-card.component.html',
  styleUrls: ['./element-month-card.component.scss']
})
export class ElementMonthCardComponent implements OnInit {
  @Input() color: string;
  @Input() title: string;
  @Input() img: string;
  @Input() text: string;
  @Input() category: string;
  @Input() level: string;
  @Input() date: string;
  @Input() id: string;
  @Input() icon: string = '';
  @Input() name: string = '';

  @Output() openItem = new EventEmitter();

  constructor() { }

  ngOnInit(): void {}

}
