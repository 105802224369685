import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UUID} from 'uuid-generator-ts';

@Component({
  selector: 'app-audioplayer',
  templateUrl: './audioplayer.component.html',
  styleUrls: ['./audioplayer.component.scss']
})
export class AudioplayerComponent implements OnInit, OnChanges {
  uuid = '';

  @Input() audiourlMp3 = '';
  @Input() posterimage = '';
  @Input() subtitles = '';
  @Input() startTime = '';

  constructor() { }

  ngOnInit(): void {
    const uuid = new UUID();
    this.uuid = uuid.getDashFreeUUID();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // tslint:disable-next-line:forin
    for (const propName in changes) {
      const chng = changes[propName];

      if (propName === 'startTime') {
        const video = document.getElementById(this.uuid) as HTMLVideoElement;
        if (video) {
          if(chng.currentValue === '') {
            video.currentTime = 0;
          } else {
            video.currentTime = chng.currentValue;
          }
        }
      }

      const cur  = JSON.stringify(chng.currentValue);
      const prev = JSON.stringify(chng.previousValue);
      console.log(`${propName}: currentValue = ${cur}, previousValue = ${prev}`);
    }
  }

}
