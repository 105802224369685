<div class="selectContainer {{ extraClass }}">
  <div class="select__currentItem">
    <p (click)="picker.open()" class="cursorLink">
      <input class="datepickerInput" [matDatepicker]="picker" [(ngModel)]="inputValue" (dateChange)="ChangeDatepicker()">
      {{ currentText }}
      <span
        [ngClass]="removeSelectedIcon === true ? '' : 'hiddenItem'"
        (click)="CloseCurrentOption()"
        class="icon-close closeIconFormat"></span>
      <span class="icon-down-arrow"></span>
    </p>
  </div>
  <div class="calendar">
    <mat-datepicker #picker></mat-datepicker>
  </div>
</div>
