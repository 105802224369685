<div class="containerLoginForm">
  <div class="paddingDiv" *ngIf="step === '1'">
    <p class="selectLang">{{ translations.elige_idioma }}</p>
  </div>
  <div class="borderTopDiv" *ngIf="step === '1'">
    <div class="paddingDiv">
      <nav>
        <ul>
          <li (click)="onSelectLanguage('Spanish')" style="cursor: pointer;">{{ translations.espanol }}</li>
          <li (click)="onSelectLanguage('French')" style="cursor: pointer;">{{ translations.frances }}</li>
          <li (click)="onSelectLanguage('German')" style="cursor: pointer;">{{ translations.aleman }}</li>
        </ul>
      </nav>
    </div>
  </div>
  <div *ngIf="step === '2'">
    <div class="paddingDiv" style="padding-bottom: 40px">
      <p>{{ translations.elige_idioma.toUpperCase() }}</p>
      <app-filter-select
        extraClass="whiteSelector"
        name="{{ translations.elige_idioma.toUpperCase() }}"
        [opened]="openLanguageSelector"
        [selected]="currentLanguage"
        [forceOneElement]="true"
        [options]="LanguageSelectorOptions()"
        (changeOption)="changeLanguageSelector($event)"
        (openSelector)="OpenLanguageSelector()"
        (closeSelector)="CloseLanguageSelector()"
      ></app-filter-select>
      <p>&nbsp;</p>
      <p>{{ translations.type_your_password.toUpperCase() }}</p>
      <div class="positionRelative">
        <span class="icon-eye inputPassword__icon cursorLink" (click)="onViewPassword()"></span>
        <input id="loginPwd" type="{{ currentInputType }}" class="inputPassword" placeholder="{{ translations.password }}" [(ngModel)]="inputPassword" />
        <button (click)="onLogin()" type="button" class="buttonBlue">{{ translations.loginButton }}</button>
        <div *ngIf="showError === true" class="errorNoLogin">{{ translations.error_password }}</div>
      </div>
    </div>
  </div>
</div>
