import {Component, OnInit} from '@angular/core';
import {TranslationsService} from '../_services/translations.service';
import {Translations} from '../_models/translations';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit {
  translations: Translations;

  constructor(
    private translationsService: TranslationsService
  ) {
    this.translations = translationsService.getTranslations();
  }

  ngOnInit(): void {
  }
}
