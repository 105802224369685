<audio id="audio-{{ uuid }}"
       preload="auto"
></audio>
<div class="audio__controlsContainer">
  <div class="controls__duration">{{ duration }}</div>
  <div class="controls__aligned">
    <div class="controls__item">
      <span (click)="handleAudioPlayed()" class="icon-play cursorLink" [ngClass]="isPlaying === true ? 'hiddenItem' : ''"></span>
      <span (click)="handleAudioPaused()" class="icon-pause cursorLink" [ngClass]="isPlaying === false ? 'hiddenItem' : ''"></span>
    </div>
    <div id="progress-{{ uuid }}" (click)="handleChangeTime($event)" class="controls__progress cursorLink">
      <div class="totalAudioTime"></div>
      <div class="currentAudioTime" style="width: {{ currentLongTimer }}%;"></div>
    </div>
    <div class="controls__item">
      <span (click)="handleAudioMuted()" class="icon-volume cursorLink" [ngClass]="hasVolume === false ? 'hiddenItem' : ''"></span>
      <span (click)="handleAudioMutedOff()" class="icon-volume-off cursorLink" [ngClass]="hasVolume === true ? 'hiddenItem' : ''"></span>
    </div>
  </div>
</div>
