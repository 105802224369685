import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

import {environment} from '../../environments/environment';
import {Observable} from 'rxjs';
import {Inewlistitem} from '../_models/inewlistitem';
import {Itemcard} from '../_models/itemcard';
import {Inewcategoryitem} from '../_models/inewcategoryitem';
import {Common} from '../_helpers/common';

@Injectable({providedIn: 'root'})
export class InewService {
  constructor(
    private http: HttpClient,
    private common: Common
  ) {
  }

  getCurrentItems(language: string, apthemeFilter: string, categoriesFilter: string, dateFilter: string, levelFilter: string): Observable<Inewlistitem[]> {
    const url = environment.api_url + '/api/iculture/inew/list';
    const params = this.common.paramsServicesURL(url, language, apthemeFilter, categoriesFilter, dateFilter, levelFilter);
    return this.http.get<Inewlistitem[]>(params);
  }

  getArchivedItems(language: string, apthemeFilter: string, categoriesFilter: string, dateFilter: string, levelFilter: string): Observable<Inewlistitem[]> {
    const url = environment.api_url + '/api/iculture/inew/archivedlist';
    const params = this.common.paramsServicesURL(url, language, apthemeFilter, categoriesFilter, dateFilter, levelFilter);
    return this.http.get<Inewlistitem[]>(params);
  }

  getAllItems(language: string, apthemeFilter: string, categoriesFilter: string, dateFilter: string, levelFilter: string): Observable<Inewlistitem[]> {
    const url = environment.api_url + '/api/iculture/inew/alllist';
    const params = this.common.paramsServicesURL(url, language, apthemeFilter, categoriesFilter, dateFilter, levelFilter);
    return this.http.get<Inewlistitem[]>(params);
  }

  getMonthList(year: number, month: number, language: string, apthemeFilter: string, categoriesFilter: string, dateFilter: string, levelFilter: string): Observable<Inewlistitem[]> {
    const url = environment.api_url + '/api/iculture/inew/month/' + year.toString() + '/' + month.toString();
    const params = this.common.paramsServicesURL(url, language, apthemeFilter, categoriesFilter, dateFilter, levelFilter);
    return this.http.get<Inewlistitem[]>(params);
  }

  getSearchItems(text: string, language: string, apthemeFilter: string, categoriesFilter: string, dateFilter: string, levelFilter: string): Observable<Itemcard[]> {
    const url = environment.api_url + '/api/iculture/inew/search/' + text;
    const params = this.common.paramsServicesURL(url, language, apthemeFilter, categoriesFilter, dateFilter, levelFilter);
    return this.http.get<Itemcard[]>(params);
  }

  getCategoriesItems(language: string): Observable<Inewcategoryitem[]> {
    const url = environment.api_url + '/api/iculture/inewcat/list';
    const params = this.common.paramsServicesURL(url, language, null, null, null, null);
    return this.http.get<Inewcategoryitem[]>(params);
  }

  getAPTagsItems(language: string): Observable<Inewcategoryitem[]> {
    const url = environment.api_url + '/api/iculture/general/aptags';
    const params = this.common.paramsServicesURL(url, language, null, null, null, null);
    return this.http.get<Inewcategoryitem[]>(params);
  }
}
