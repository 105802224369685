import {Component, Input, OnInit} from '@angular/core';
import {Common} from '../_helpers/common';

@Component({
  selector: 'app-collapsible-element-textarea',
  templateUrl: './collapsible-element-textarea.component.html',
  styleUrls: ['./collapsible-element-textarea.component.scss']
})
export class CollapsibleElementTextareaComponent implements OnInit {
  collapsibleOpen = false;
  currentLanguage = 'Spanish';

  @Input() title;
  @Input() body;
  @Input() textareaPlaceholder;

  constructor(
    private common: Common
  ) {}

  ngOnInit(): void {
    this.currentLanguage = localStorage.getItem('currentLanguage');
  }

  toggleCollapsible(): void {
    this.collapsibleOpen = !this.collapsibleOpen;
  }

  inschar(myField, myValue): void {
    const eleDom: HTMLTextAreaElement = document.getElementById(myField) as HTMLTextAreaElement;
    this.common.inschar(eleDom, myValue);
  }
}
