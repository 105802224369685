import {Component, Input, OnInit, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-question-poll',
  templateUrl: './question-poll.component.html',
  styleUrls: ['./question-poll.component.scss']
})
export class QuestionPollComponent implements OnInit {
  currentAnswers = [];

  @Input() questions = [];
  @Input() title = '';
  @Input() previousText = '';
  @Input() language = 'spanish';
  @Input() extraClass = '';
  @Input() questionExtraClass = '';
  @Output() onAnswerChange = new EventEmitter();

  constructor() {
  }

  ngOnInit(): void {
    this.questions.forEach((item, index) => {
      this.currentAnswers[index] = '';
    });
  }

  ChangeAnswer(question: number, value: string, answer: string, event: any): void {
    event.preventDefault();
    this.currentAnswers[question] = value;

    this.onAnswerChange.emit({ question: question + 1, value: value, answer: answer });
  }

  PaintLetter(itemNumber: number): string {
    return String.fromCharCode(itemNumber + 65);
  }
}
