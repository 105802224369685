import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UUID} from 'uuid-generator-ts';
import {Common} from '../_helpers/common';

@Component({
  selector: 'app-videoplayer',
  templateUrl: './videoplayer.component.html',
  styleUrls: ['./videoplayer.component.scss']
})
export class VideoplayerComponent implements OnInit, OnChanges {
  uuid = '';
  timeoutEnterVideo = null;

  @Input() videourlMp4 = '';
  @Input() videourlOgv = '';
  @Input() videourlM4v = '';
  @Input() videourlWebm = '';
  @Input() posterimage = '';
  @Input() subtitles = '';
  @Input() startTime = '';
  @Input() stopPlay = 'false';

  constructor(
    private common: Common
  ) { }

  ngOnInit(): void {
    const uuid = new UUID();
    this.uuid = uuid.getDashFreeUUID();
  }

  ngOnChanges(changes: SimpleChanges): void {
    // tslint:disable-next-line:forin
    for (const propName in changes) {
      const chng = changes[propName];

      // let control = 0;
      if (propName === 'startTime') {
        console.log('startTime: ' + chng.currentValue + ' ' + this.uuid);
        const video = document.getElementById(this.uuid) as HTMLVideoElement;
        if (video) {
          video.pause();
          if (chng.currentValue === '') {
            video.currentTime = 0;
          } else {
            video.currentTime = parseFloat(chng.currentValue);
            console.log(parseFloat(chng.currentValue));
            video.play();
          }
          // control = video.currentTime;
        }
      }

      if (propName === 'stopPlay') {
        console.log('stopPlay: ' + chng.currentValue + ' ' + this.uuid);
        console.log(chng.currentValue);
        if (chng.currentValue === 'true') {
          const video = document.getElementById(this.uuid) as HTMLVideoElement;
          if (video) {
            video.pause();
          }
        }
      }

      // const cur  = JSON.stringify(chng.currentValue);
      // const prev = JSON.stringify(chng.previousValue);
      // console.log(`${propName}: currentValue = ${cur}, previousValue = ${prev}, time = ${control}`);
    }
  }

  onVideoEnter(): void {
    clearTimeout(this.timeoutEnterVideo);
    const wrapper = document.getElementById('container_' + this.uuid);
    wrapper.classList.add('hoverEffect');

    this.timeoutEnterVideo = setTimeout(() => {
      this.onVideoLeave();
    }, 2000);
  }

  onVideoLeave(): void {
    clearTimeout(this.timeoutEnterVideo);
    const wrapper = document.getElementById('container_' + this.uuid);
    wrapper.classList.remove('hoverEffect');
  }
}
